import Vue from 'vue';
import Router, {Route, RouteRecord} from 'vue-router';
import {store} from '@/store';
import {Position, PositionResult} from 'vue-router/types/router';
import {getModule} from 'vuex-module-decorators';
import {AccountModule, OrganizationModule} from '@/store/modules';

Vue.use(Router);

const accountModule = getModule(AccountModule, store);
const organizationModule = getModule(OrganizationModule, store);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to: Route, from: Route, savedPosition: void | Position) {
        if (savedPosition) {
            // savedPosition is only available for popstate navigations.
            return savedPosition;
        } else {
            // scroll to anchor by returning the selector
            if (to.hash) {
                // bypass #1number check
                const element: HTMLElement = document.querySelector(to.hash) as HTMLElement;

                if (element) {
                    window.scrollTo({
                        top: element.offsetTop,
                        behavior: 'smooth',
                    });
                } else if (/^#\d/.test(to.hash)) {
                    return {
                        selector: to.hash,
                    };
                }

                // if the returned position is falsy or an empty object,
                // will retain current scroll position.
                return;
            }

            return new Promise<PositionResult>((resolve) => {
                if (!to.meta?.preserveScrollPosition) {
                    // coords will be used if no selector is provided,
                    // or if the selector didn't match any element.
                    return resolve({
                        x: 0,
                        y: 0,
                    });
                }

                return resolve();
            });
        }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
            meta: {
                hasDrawerLeft: organizationModule.loggedOrganization &&
                    organizationModule.loggedOrganization.status === 'enabled',
            },
        },
        {
            path: '/horaires-ouverture',
            name: 'business-hours',
            component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
            meta: {
                requiresAuth: true,
                hasDrawerLeft: true,
            },
        },
        {
            path: '/connexion',
            name: 'login',
            meta: {
                requiresGuest: true,
            },
            component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
            path: '/inscription',
            name: 'register',
            meta: {
                requiresGuestOrPendingValidation: true,
            },
            component: () => import(/* webpackChunkName: "register" */ './views/Register.vue'),
        },
        {
            path: '/reinitialiser',
            name: 'reset',
            meta: {
                requiresGuest: true,
            },
            component: () => import(/* webpackChunkName: "reset" */ './views/Reset.vue'),
        },
        {
            path: '/mon-compte',
            name: 'account',
            meta: {
                requiresAuth: true,
                hasDrawerLeft: true,
            },
            component: () => import(/* webpackChunkName: "account" */ './views/Account.vue'),
            children: [
                {
                    path: 'profil',
                    name: 'account-profile',
                    component: () => import(/* webpackChunkName: "account" */ './views/AccountProfile.vue'),
                },
                {
                    path: 'mes-etablissements',
                    name: 'account-organizations',
                    component: () => import(/* webpackChunkName: "account" */ './views/AccountOrganizations.vue'),
                },
                {
                    path: 'authentification',
                    name: 'account-authentication',
                    component: () => import(/* webpackChunkName: "account" */ './views/AccountAuthentication.vue'),
                },
            ],
        },
        {
            path: '/mon-etablissement',
            name: 'organization',
            meta: {
                requiresAuth: true,
                requiresAdmin: true,
                hasDrawerLeft: true,
            },
            component: () => import(/* webpackChunkName: "organization" */ './views/Organization.vue'),
            children: [
                {
                    path: 'motifs',
                    name: 'organization-reasons',
                    component: () => import(/* webpackChunkName: "organization" */ './views/OrganizationReasons.vue'),
                },
                {
                    path: 'utilisateurs',
                    name: 'organization-users',
                    component: () => import(/* webpackChunkName: "organization" */ './views/OrganizationUsers.vue'),
                },
                {
                    path: 'agendas',
                    name: 'organization-agendas',
                    component: () => import(/* webpackChunkName: "organization" */ './views/OrganizationAgendas.vue'),
                },
                {
                    path: 'ressources',
                    name: 'organization-resources',
                    component: () => import(/* webpackChunkName: "organization" */ './views/OrganizationResources.vue'),
                },
                {
                    path: 'synchronisation',
                    name: 'organization-synchronization',
                    component: () => import(/* webpackChunkName: "organization" */ './views/OrganizationSynchronization.vue'),
                },
                {
                    path: 'profil',
                    name: 'organization-profile',
                    component: () => import(/* webpackChunkName: "organization" */ './views/OrganizationProfile.vue'),
                },
                {
                    path: 'facturation',
                    name: 'organization-billing',
                    component: () => import(/* webpackChunkName: "organization" */ './views/OrganizationBilling.vue'),
                    meta: {
                        requiresOwner: true,
                    },
                },
                {
                    path: '/mes-clients',
                    name: 'clients',
                    component: () => import(/* webpackChunkName: "clients" */ './views/Clients.vue'),
                },
                {
                    path: 'instructions',
                    name: 'organization-instructions',
                    component: () => import(/* webpackChunkName: "organization" */ './views/OrganizationInstructions.vue'),
                },
                {
                    path: 'rules',
                    name: 'organization-rules',
                    component: () => import(/* webpackChunkName: "organization" */ './views/OrganizationRules.vue'),
                },
            ],
        },
        {
            path: '/a-propos',
            name: 'about',
            components: {
                default: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
                subheader: () => import(/* webpackChunkName: "about" */ './components/layout/TheHeaderStatic.vue'),
            },
            props: {
                subheader: {
                    title: 'À propos de nous',
                },
            },
        },
        {
            path: '/aide',
            name: 'faq',
            components: {
                default: () => import(/* webpackChunkName: "faq" */ './views/Faq.vue'),
            },
            props: {
                subheader: {
                    title: 'Aide',
                },
            },
        },
        {
            path: '/cgv',
            name: 'terms',
            components: {
                default: () => import(/* webpackChunkName: "terms" */ './views/Terms.vue'),
            },
            props: {
                subheader: {
                    title: 'Conditions générales de vente',
                },
            },
        },
        {
            path: '/rgpd',
            name: 'rgpd',
            components: {
                default: () => import(/* webpackChunkName: "privacy" */ './views/Rgpd.vue'),
            },
            props: {
                subheader: {
                    title: 'Conditions générales de vente',
                },
            },
        },
        {
            path: '/legal',
            name: 'legal',
            components: {
                default: () => import(/* webpackChunkName: "privacy" */ './views/Legal.vue'),
            },
            props: {
                subheader: {
                    title: 'Mentions légales',
                },
            },
        },
        {
            path: '/nous-contacter',
            name: 'contact',
            components: {
                default: () => import(/* webpackChunkName: "contact" */ './views/Contact.vue'),
                subheader: () => import(/* webpackChunkName: "contact" */ './components/layout/TheHeaderStatic.vue'),
            },
            props: {
                subheader: {
                    title: 'Nous contacter',
                },
            },
        },
    ],
});

router.beforeEach((to: Route, from: Route, next: any) => {
    // impersonate a user if we have the required tokens
    if (typeof to.query.impersonator_token === 'string' && typeof to.query.impersonated_token === 'string') {
        accountModule.impersonate({
            impersonator_token: to.query.impersonator_token,
            impersonated_token: to.query.impersonated_token,
        });

        return next({ name: to.name, replace: true });
    }

    // Change title and description
    const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => {
        if (el) {
            (el.parentNode as HTMLElement).removeChild(el);
        }
    });

    if (nearestWithMeta) {
        // Turn the meta tag definitions into actual elements in the head.
        nearestWithMeta.meta.metaTags
            .map((tagDef: any) => {
                const tag = document.createElement('meta');

                Object.keys(tagDef).forEach((key) => {
                    tag.setAttribute(key, tagDef[key]);
                });

                // We use this to track which meta tags we create, so we don't interfere with other ones.
                tag.setAttribute('data-vue-router-controlled', '');

                return tag;
            })
            // Add the meta tags to the document head.
            .forEach((tag: HTMLElement) => document.head.appendChild(tag))
        ;
    }

    // User still in the registration process should stay on registration page
    if (
        to.name !== 'register' &&
        accountModule.loggedAccount &&
        accountModule.loggedAccount?.status !== 'validated'
    ) {
        return next({name: 'register'});
    }

    if (to.matched.some((record: RouteRecord) => record.meta.requiresAuth)) {
        if (!accountModule.isLoggedIn) {
            return next({name: 'login'});
        }
        if (organizationModule.loggedOrganization?.status !== 'enabled') {
            return next({name: 'register'});
        }
    }

    const loggedOrganization = organizationModule.loggedOrganization;

    if (to.matched.some((record: RouteRecord) => record.meta.requiresAdmin)) {
        if (!accountModule.isLoggedIn) {
            return next({name: 'login'});
        }

        if (!loggedOrganization) {
            return next({name: 'home'});
        }

        if (!accountModule.loggedAccountRoles.find((role) => {
            return role.organization &&
                role.organization.id === loggedOrganization.id &&
                (
                    role.role.name === 'organization_admin' ||
                    role.role.name === 'organization_owner'
                )
            ;
        })) {
            return next({name: 'home'});
        }
    }

    if (to.matched.some((record: RouteRecord) => record.meta.requiresOwner)) {
        if (!accountModule.isLoggedIn) {
            return next({name: 'login'});
        }

        if (!loggedOrganization) {
            return next({name: 'home'});
        }

        if (!accountModule.loggedAccountRoles.find((role) => {
            return role.organization &&
                role.organization.id === loggedOrganization.id &&
                role.role.name === 'organization_owner';
        })) {
            return next({name: 'home'});
        }
    }

    if (to.matched.some((record: RouteRecord) => record.meta.requiresDemo)) {
        if (!accountModule.isLoggedIn) {
            return next({name: 'login'});
        }

        if (!loggedOrganization) {
            return next({name: 'home'});
        }

        const demoIds = (process.env.VUE_APP_DEMO_ID as string).split(',');

        if (demoIds.indexOf(loggedOrganization.id) === -1) {
            return next({name: 'home'});
        }
    }

    if (to.matched.some((record: RouteRecord) => record.meta.requiresGuest)) {
        if (accountModule.isLoggedIn) {
            return next({name: 'home'});
        }
    }

    if (to.matched.some((record: RouteRecord) => record.meta.requiresGuestOrPendingValidation)) {
        if (!accountModule.loggedAccount) {
            return next();
        }

        if (accountModule.loggedAccount.status === 'pending_details') {
            return next();
        }

        if (accountModule.loggedAccount.status === 'pending_validation') {
            return next();
        }

        return next({name: 'home'});
    }

    return next();
});

export default router;
