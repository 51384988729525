import Vue from 'vue';

Vue.filter('dayName', (dayNumber: number) => {
    const dayNames: string[] = [
        'Lun',
        'Mar',
        'Mer',
        'Jeu',
        'Ven',
        'Sam',
        'Dim',
    ];
    return dayNames[dayNumber];
});

Vue.filter('dayFullName', (dayNumber: number) => {
    const dayFullNames: string[] = [
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
        'Dimanche',
    ];
    return dayFullNames[dayNumber];
});
