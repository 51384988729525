import {
    IChangeOrganizationParaveterinarianRoleParams,
    ICreateOrganizationParaveterinarianParams,
    IParaveterinarian,
    IUpdateParaveterinarianPresentationParams,
    IInviteOrganizationParaveterinarianParams,
} from '@/types';

import AbstractService from './abstract.service';

export class ParaveterinarianService extends AbstractService {
    public getParaveterinarian(paraveterinarianId: string) {
        return new Promise<IParaveterinarian>((resolve, reject) => {
            this.api({
                url: `/paraveterinarians/${paraveterinarianId}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public createParaveterinarian(params: ICreateOrganizationParaveterinarianParams) {
        const organizationId = params.organization_id;

        delete (params as ICreateOrganizationParaveterinarianParams).organization_id;

        return new Promise<IParaveterinarian>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/paraveterinarians`,
                data: params,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public inviteParaveterinarian(params: IInviteOrganizationParaveterinarianParams) {
        const organizationId = params.organization_id;
        const paraveterinarianId = params.paraveterinarian_id;

        delete (params as IInviteOrganizationParaveterinarianParams).organization_id;
        delete (params as IInviteOrganizationParaveterinarianParams).paraveterinarian_id;

        return new Promise<IParaveterinarian>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/paraveterinarians/${paraveterinarianId}/invite`,
                data: params,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public changeParaveterinarianRole(params: IChangeOrganizationParaveterinarianRoleParams) {
        const organizationId = params.organization_id;
        const paraveterinarianId = params.paraveterinarian_id;

        delete (params as IChangeOrganizationParaveterinarianRoleParams).organization_id;
        delete (params as IChangeOrganizationParaveterinarianRoleParams).paraveterinarian_id;

        return new Promise<IParaveterinarian>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/paraveterinarians/${paraveterinarianId}/role`,
                data: params,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public updateLoggedParaveterinarian(params: IUpdateParaveterinarianPresentationParams) {
        return new Promise<IParaveterinarian>((resolve, reject) => {
            this.api({
                url: `/paraveterinarians/${params.paraveterinarian_id}`,
                method: 'PUT',
                data: params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
