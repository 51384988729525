
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {
        IDisplaySettings,
        IAccount,
    } from '@/types';

    import {
        SnackbarModule, AccountModule,
    } from '@/store/modules';

    const accountNamespace = namespace('account');

    @Component<DisplaySettingsFormCard>({})
    export default class DisplaySettingsFormCard extends Vue {
        public isSubmittingDisplaySettings: boolean = false;
        public displaySettingsFormValid: boolean = false;

        public startTimeMenu: boolean = false;
        public endTimeMenu: boolean = false;

        public value = {};

        public zoomItems = [
            {
                value: 1,
                text: 'Faible',
            },
            {
                value: 2,
                text: 'Moyen',
            },
            {
                value: 3,
                text: 'Fort',
            },
        ];

        public gridItems = [
            {
                value: '00:10:00',
                text: '10 min',
            },
            {
                value: '00:15:00',
                text: '15 min',
            },
            {
                value: '00:20:00',
                text: '20 min',
            },
            {
                value: '00:30:00',
                text: '30 min',
            },
            {
                value: '00:45:00',
                text: '45 min',
            },
            {
                value: '01:00:00',
                text: '60 min',
            },
        ];

        public days = [
            {
                value: 1,
                text: 'Lundi',
            },
            {
                value: 2,
                text: 'Mardi',
            },
            {
                value: 3,
                text: 'Mercredi',
            },
            {
                value: 4,
                text: 'Jeudi',
            },
            {
                value: 5,
                text: 'Vendredi',
            },
            {
                value: 6,
                text: 'Samedi',
            },
            {
                value: 0,
                text: 'Dimanche',
            },
        ];

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount;

        @accountNamespace.Getter('displaySettings')
        public displaySettings!: IDisplaySettings;

        public mounted() {
            this.value = Object.assign({}, this.displaySettings);
        }

        public backButtonClicked() {
            return this.$emit('close');
        }

        public submitButtonClicked() {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.isSubmittingDisplaySettings = true;

            getModule(AccountModule, this.$store)
                .saveDisplaySettings({
                    accountId: this.loggedAccount.id,
                    settings: this.value as IDisplaySettings,
                })
                .then((settings: IDisplaySettings) => {
                    snackbarModule.displaySuccess('Vos paramètres d\'affichage ont bien été enregistrés !');
                    this.$emit('close');
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isSubmittingDisplaySettings = false)
            ;
        }
    }
