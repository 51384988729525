import {AxiosResponse, AxiosError, AxiosInstance, AxiosRequestConfig} from 'axios';
import axios from 'axios';

import {getModule} from 'vuex-module-decorators';

import {store} from '@/store';
import {AccountModule} from '@/store/modules';

import {
    AccountService,
    AgendaService,
    AnimalService,
    BusinessHourService,
    ClientService,
    ContactService,
    EventService,
    OrganizationService,
    ParaveterinarianService,
    PlaceService,
    ReasonService,
    VeterinarianService,
    InstructionService,
    RuleService,
} from '@/api';

import _Vue from 'vue';

export default function ApiPlugin(Vue: typeof _Vue, options?: any): void {
    const apiAxios: AxiosInstance = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
    });

    apiAxios.interceptors.request.use(
        (config) => {
            const isLoginRequest = config.url === '/authentication' && config.method?.toLowerCase() === 'post';
            const token = store.getters['account/authToken'];
            const loggedUser = store.getters['account/loggedUser'];
            const impersonateToken = store.getters['account/impersonateToken'];

            config.headers.Accept = 'application/vnd.vetolib.v1+json';

            if (impersonateToken) {
                config.headers.Impersonate = `Bearer ${impersonateToken}`;
            }

            if (!token) {
                return config;
            }

            // Sometimes, the token is still in the local storage, but not the user
            // Force disconnection when it happens
            if (!loggedUser) {
                getModule(AccountModule, store).logout();
                return config;
            }

            if (!isLoginRequest) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
        },
        (error: AxiosError) => Promise.reject(error),
    );

    apiAxios.interceptors.response.use(
        (response: AxiosResponse) => response,
        (error: AxiosError) => {
            const originalRequest: any = error.config;
            const originalHeaders = originalRequest?.headers;
            const status = error.response?.status;
            const data: any = error.response?.data;

            if (status === 401 && data.reason === 'token_expired' && !originalHeaders['X-Auth-Failed-No-Retry']) {

                return new Promise((resolve, reject) => {
                    getModule(AccountModule, store)
                        .refreshAccessToken()
                        .then(({access_token: accessToken}) => {
                            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                            originalRequest.headers['X-Auth-Failed-No-Retry'] = true;

                            return resolve(apiAxios(originalRequest));
                        })
                        .catch((refreshError) => {
                            store.dispatch('account/logout');
                            reject(refreshError);
                        })
                    ;
                });
            }

            if (status === 498 && data.reason === 'token_expired' && store.getters['account/isImpersonated']) {
                store.dispatch('snackbar/displayError', 'Session super admin expirée, veuillez vous reconnecter');
                store.dispatch('account/logout');
            }

            return Promise.reject(error);
        },
    );

    Vue.prototype.$api = {
        account: new AccountService(apiAxios),
        agenda: new AgendaService(apiAxios),
        animal: new AnimalService(apiAxios),
        businessHour: new BusinessHourService(apiAxios),
        client: new ClientService(apiAxios),
        contact: new ContactService(apiAxios),
        event: new EventService(apiAxios),
        organization: new OrganizationService(apiAxios),
        paraveterinarian: new ParaveterinarianService(apiAxios),
        place: new PlaceService(apiAxios),
        reason: new ReasonService(apiAxios),
        veterinarian: new VeterinarianService(apiAxios),
        instruction: new InstructionService(apiAxios),
        rule: new RuleService(apiAxios),
    };
}
