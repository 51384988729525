
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {
        DateTime,
    } from 'luxon';

    import {
        AgendaViewValue,
        IAccountRole,
        IAgenda,
        IAgendasByGroup,
        IOrganization,
        IReason,
    } from '@/types';

    import {
        AccountModule,
        AgendaModule,
        LayoutModule,
        OrganizationModule,
    } from '@/store/modules';

    const accountNamespace = namespace('account');
    const agendaNamespace = namespace('agenda');
    const layoutNamespace = namespace('layout');
    const organizationNamespace = namespace('organization');
    const reasonNamespace = namespace('reason');

    import DisplaySettingsFormCard from '@/components/DisplaySettingsFormCard.vue';

    @Component<TheDrawerAgenda>({
        components: {
            DisplaySettingsFormCard,
        },
    })
    export default class TheDrawerAgenda extends Vue {
        public now: DateTime = DateTime.utc();
        public displaySettingsDialog: boolean = false;


        @accountNamespace.Getter('isLoggedIn')
        public isLoggedIn!: boolean;

        @accountNamespace.Getter('loggedAccountRoles')
        public loggedAccountRoles!: IAccountRole[];

        @agendaNamespace.Getter('agendasList')
        public agendasList!: IAgenda[];

        @agendaNamespace.Getter('agendasByGroup')
        public agendasByGroup!: IAgendasByGroup;

        @agendaNamespace.Getter('selectedAgendasList')
        public selectedAgendasList!: IAgenda[];

        @agendaNamespace.Getter('selectedDateValue')
        public selectedDateValue!: string;

        @agendaNamespace.Getter('selectedViewValue')
        public selectedViewValue!: AgendaViewValue;

        @agendaNamespace.Action('toggleAgenda')
        public toggleAgenda!: (agenda: IAgenda) => void;

        @agendaNamespace.Action('toggleAgendas')
        public toggleAgendas!: (agendas: IAgenda[]) => void;

        @agendaNamespace.Action('selectAgenda')
        public selectAgenda!: (agenda: IAgenda) => void;

        @agendaNamespace.Action('selectAgendas')
        public selectAgendas!: (agendas: string[]) => void;

        @agendaNamespace.Action('selectAllAgendas')
        public selectAllAgendas!: () => void;

        @agendaNamespace.Action('selectDate')
        public selectDate!: (date: string) => void;

        @agendaNamespace.Action('selectView')
        public selectView!: (view: AgendaViewValue) => void;

        @layoutNamespace.Getter('isDrawerLeftOpened')
        public isDrawerLeftOpened!: boolean;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @reasonNamespace.Getter('organizationReasonsEnabledList')
        public organizationReasonsEnabledList!: IReason[];

        @reasonNamespace.Getter('selectedReasonsList')
        public selectedReasonsList!: IReason[];

        @reasonNamespace.Getter('areAllReasonsSelected')
        public areAllReasonsSelected!: boolean;

        @reasonNamespace.Action('toggleReason')
        public toggleReason!: (reason: IReason) => void;

        @reasonNamespace.Action('selectReason')
        public selectReason!: (reason: IReason) => void;

        @reasonNamespace.Action('selectAllReasons')
        public selectAllReasons!: () => void;

        public toggleDrawerLeft() {
            getModule(LayoutModule, this.$store).toggleDrawerLeft();
        }

        get views() {
            return [
                {
                    value: 'resourceTimeGridDay',
                    icon: 'day-outline',
                    name: 'Jour',
                },
                {
                    value: 'resourceTimeGridWeek',
                    icon: 'week-outline',
                    name: 'Semaine',
                },
                {
                    value: 'dayGridMonth',
                    icon: 'module-outline',
                    name: 'Mois',
                },
            ];
        }

        get organizations() {
            return this.loggedAccountRoles
                .filter((accountRole: IAccountRole) => {
                    return !!accountRole.organization &&
                        (!this.loggedOrganization || accountRole.organization.id !== this.loggedOrganization.id)
                    ;
                })
                .map((accountRole: IAccountRole) => {
                    return accountRole.organization;
                })
            ;
        }

        get selectedView() {
            return this.selectedViewValue;
        }

        set selectedView(value: AgendaViewValue) {
            this.selectView(value);
            this.opened = false;
        }

        get opened() {
            return this.isDrawerLeftOpened;
        }

        set opened(value: boolean) {
            getModule(LayoutModule, this.$store).setDrawerLeftOpened(value);
        }

        get areAllAgendasSelected() {
            return this.agendasList.length === this.selectedAgendasList.length;
        }

        get dividerColor() {
            return '#294174';
        }

        get selectedDate() {
            return this.selectedDateValue;
        }

        set selectedDate(val: string) {
            this.selectDate(val);
        }

        public isAgendaSelected(agenda: IAgenda) {
            return this.selectedAgendasList.indexOf(agenda) > -1;
        }

        public isGroupSelected(agendas: string[]) {
            return agendas.every((agendaId: string) => {
                return  this.selectedAgendasList.find((agenda) => {
                    return agenda.id === agendaId;
                });
            });
        }

        public isReasonSelected(reason: IReason) {
            return this.selectedReasonsList.indexOf(reason) > -1;
        }

        public shortcutsName(name: string) {
            const lengthName = name.split('');
            
            if (lengthName.length < 23) {
                return name;
            } else {
                let newName: string = '';
                lengthName.map((e, i) => {
                        if (i < 24) {
                            newName = newName+e;
                        }
                    },
                );

                return newName + '...';
            }
        }

        public updateTime() {
            this.now = DateTime.now();
            setTimeout(() => this.updateTime(), 1000);
        }

        public selectOrganization(organization: IOrganization) {
            const organizationModule = getModule(OrganizationModule, this.$store);

            organizationModule.selectOrganization(organization);
        }

        public selectedAgenda(agenda: IAgenda) {
            const agendaModule = getModule(AgendaModule, this.$store);
            agendaModule.selectAgenda(agenda);
        }

        public logout() {
            getModule(AccountModule, this.$store)
                .logout()
                .then(() => {
                    if (this.$route.name !== 'booking') {
                        this.$router.push({name: 'home'});
                    }
                })
            ;
        }

        public mounted() {
            this.updateTime();
        }
    }
