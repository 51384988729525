
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {Prop} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';

    import {
        AnimalModule,
        SnackbarModule,
    } from '@/store/modules';

    import {
        IOrganization,
        IDeleteAnimalParams,
        IClient,
        IAnimal,
        IUpdateAnimalParams,
    } from '@/types';

    const clientNamespace = namespace('client');
    const organizationNamespace = namespace('organization');
    const animalNamespace = namespace('animal');

    import AnimalFormCard from '@/components/AnimalFormCard.vue';

    @Component<ClientCardAnimals>({
        components: {
            AnimalFormCard,
        },
    })
    export default class ClientCardAnimals extends Vue {
        public createAnimalDialog: boolean = false;
        public animalToCreate: IAnimal|null = null;

        @Prop({
            type: String,
            required: false,
            default: '',
        })
        public clientToEdit!: string;

        public dataTableIsLoading: boolean = true;

        public headers: object[] = [
          {text: 'Nom', value: 'name'},
          {text: 'Age', value: 'birth_date'},
          {text: 'Sexe', value: 'sex'},
          {text: 'Stérilisé', value: 'is_sterilized'},
          {text: 'Espèce', value: 'species.name'},
          {text: 'Race', value: 'breed.name'},
          {text: 'Actions', value: 'actions', sortable: false, align: 'end'},
        ];

        @clientNamespace.Getter('currentClient')
        public currentClient!: IClient;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @animalNamespace.Getter('animalsList')
        public animalsList!: IAnimal[];

        public openEditAnimalForm(animal: IAnimal) {
            if (animal.status !== 'deceased') {
                this.createAnimalDialog = true;
                (this.animalToCreate as IAnimal) = animal;
                (this.animalToCreate as IAnimal).client_id = this.clientToEdit;
            }
        }

        public openCreateAnimalForm() {
            this.createAnimalDialog = true;
            this.animalToCreate = {
                client_id: this.clientToEdit,
                is_sterilized: false,
                is_crossbreed: false,
            } as IAnimal;
        }

        public deleteAnimal(animalId: string) {
            const animalModule = getModule(AnimalModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);
            const params = {
                organization_id: this.loggedOrganization.id,
                client_id: this.clientToEdit,
                animal_id: animalId,
            };
            animalModule
                .deleteAnimal(params as IDeleteAnimalParams)
                .then(() => {
                    snackbarModule.displaySuccess(
                        `L'animal a bien été supprimé !`,
                    );
                }).catch((error)=> {
                    snackbarModule.displayError(error);
                })
            ;
        }

        public kill(animalId: string) {
            const animalModule = getModule(AnimalModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);
            const params = {
                organization_id: this.loggedOrganization.id,
                client_id: this.clientToEdit,
                animal_id: animalId,
                status: 'deceased',
            };
            animalModule
                .updateAnimal(params as IUpdateAnimalParams)
                .then(() => {
                    snackbarModule.displaySuccess(
                        `L'animal a bien été signalé comme décédé !`,
                    );
                })
            ;
        }

        public resurrect(animalId: string) {
            const animalModule = getModule(AnimalModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);
            const params = {
                organization_id: this.loggedOrganization.id,
                client_id: this.clientToEdit,
                animal_id: animalId,
                status: 'enabled',
            };
            animalModule
                .updateAnimal(params as IUpdateAnimalParams)
                .then(() => {
                    snackbarModule.displaySuccess(
                        `Le statut décédé de cet animal a bien été retiré !`,
                    );
                })
            ;
        }

        public created() {
            if (this.clientToEdit) {
                getModule(AnimalModule, this.$store)
                    .fetchAnimals({
                        organization_id: this.loggedOrganization.id,
                        client_id: this.clientToEdit,
                        is_organization_animal: true,
                    })
                    .then(() => {
                        this.dataTableIsLoading = false;
                    });
            }
        }
    }
