import {RRule, rrulestr, RRuleSet} from 'rrule';
import {DateTime, DateTimeOptions, Settings, ToISOTimeOptions} from 'luxon';

import {IBusinessHour, IRecurrence} from '@/types';

export const rruleHelper = {
    extractFullCalendarBusinessHours,
    formatFullcalendarDateForRRule,
    getByDayAfterStartDateChanged,
    getRecurrenceRule,
    getTextRepresentation,
    parseRule,
    parseRuleForFullcalendar,
    setUntil,
};

function getTextRepresentation(recurrence: string) {
    if (!recurrence) {
        return null;
    }

    const cleanedRecurrence = recurrence
        .split('\n')
        .filter((line: string) => {
            return line.substr(0, 'EXDATE'.length).toUpperCase() !== 'EXDATE';
        })
        .join('\n')
    ;

    return RRule
        .fromString(cleanedRecurrence)
        .toText(
            (id) => (frenchLocales.strings as any)[id as any] as any || id,
            frenchLocales.names as any,
            frenchLocales.dateFormatter,
        )
    ;
}

function getRecurrenceRule(start: string, recurrence: IRecurrence) {
    if (!recurrence.frequency) {
        return null;
    }

    if (!start) {
        return null;
    }

    const formatOpts: ToISOTimeOptions = {
        suppressMilliseconds: true,
        format: 'basic',
        includeOffset: false,
    };

    const dateTimeStart = DateTime.fromISO(start);
    const startFormatted = dateTimeStart.toLocal().toISO(formatOpts);
    let rule = `DTSTART;TZID=${Settings.defaultZone.name}:${startFormatted}\nRRULE:FREQ=${recurrence.frequency}`;

    if (recurrence.frequency === 'WEEKLY') {
        if (recurrence.byDay.length === 0) {
            return null;
        }

        rule += `;INTERVAL=${recurrence.interval};BYDAY=${recurrence.byDay.join(',')}`;
    }

    if (recurrence.until) {
        const dateTimeUntil = DateTime.fromISO(recurrence.until).toLocal();

        if (dateTimeStart > dateTimeUntil) {
            return null;
        }

        rule += `;UNTIL=${dateTimeUntil.toISO(formatOpts)}`;
    } else if (recurrence.count) {
        rule += `;COUNT=${recurrence.count}`;
    }

    if (recurrence.exDate.length > 0) {
        rule += `\nEXDATE:${recurrence.exDate.join(',')}`;
    }

    return rule;
}

function getByDayAfterStartDateChanged(val: string, oldVal: string, recurrence: IRecurrence) {
    const oldByDay = DateTime
        .fromISO(oldVal)
        .setLocale('en-gb')
        .toLocaleString({ weekday: 'long' })
        .substr(0, 2)
        .toUpperCase()
    ;

    const newByDay = DateTime
        .fromISO(val)
        .setLocale('en-gb')
        .toLocaleString({ weekday: 'long' })
        .substr(0, 2)
        .toUpperCase()
    ;

    if (recurrence.byDay.length === 1 && recurrence.byDay[0] === oldByDay) {
        recurrence.byDay = [newByDay];
    }

    return recurrence.byDay;
}

function parseRule(recurrence: string): IRecurrence {
    const parsedData: IRecurrence = {
        frequency: 'WEEKLY',
        interval: 1,
        byDay: [],
        exDate: [],
    };

    recurrence
        .split('\n')
        .forEach((line: string) => {
            let fullType: string;
            let type: string;
            let value: string;

            [fullType, value] = line.split(':');
            [type] = fullType.split(';');

            switch (type.toUpperCase()) {
                case 'EXDATE':
                    parsedData.exDate = value.split(',');
                    break;

                case 'RRULE':
                    value
                        .split(';')
                        .forEach((prop: string) => {
                            let propName: string;
                            let propValue: string;

                            [propName, propValue] = prop.split('=');

                            switch (propName.toUpperCase()) {
                                case 'FREQ':
                                    (parsedData.frequency as string) = propValue.toUpperCase();
                                    break;

                                case 'INTERVAL':
                                    parsedData.interval = parseInt(propValue, 10);
                                    break;

                                case 'BYDAY':
                                    (parsedData.byDay as string[]) = propValue.split(',');
                                    break;

                                case 'UNTIL':
                                    (parsedData.until as string) = propValue;
                                    break;

                                case 'COUNT':
                                    parsedData.count = parseInt(propValue, 10);
                                    break;
                            }
                        })
                    ;
                    break;
            }
        })
    ;

    return parsedData;
}

function parseRuleForFullcalendar(recurrence: string, timezone: string) {
    const parsedData: any = {
        byweekday: [],
    };

    let exDates: string[] = [];

    recurrence
        .split('\n')
        .forEach((line: string) => {
            let fullType: string;
            let type: string;
            let value: string;

            [fullType, value] = line.split(':');
            [type] = fullType.split(';');

            const fromOpts: DateTimeOptions = {
                zone: timezone,
            };

            const formatOpts: ToISOTimeOptions = {
                includeOffset: false,
                suppressMilliseconds: true,
            };

            switch (type.toUpperCase()) {
                case 'DTSTART':
                    parsedData.dtstart = DateTime
                        .fromISO(value, fromOpts)
                        .toISO(formatOpts)
                    ;

                    break;

                case 'EXDATE':
                    exDates = value
                        .split(',')
                        .map((exdate: string) => {
                            return DateTime
                                .fromISO(exdate, fromOpts)
                                .toISO(formatOpts)
                            ;
                        }).filter((exdate: string | null): exdate is string => exdate !== null);
                    ;

                    break;

                case 'RRULE':
                    value
                        .split(';')
                        .forEach((prop: string) => {
                            let propName: string;
                            let propValue: string;

                            [propName, propValue] = prop.split('=');

                            switch (propName.toUpperCase()) {
                                case 'FREQ':
                                    parsedData.freq = propValue.toUpperCase();
                                    break;

                                case 'INTERVAL':
                                    parsedData.interval = parseInt(propValue, 10);
                                    break;

                                case 'BYDAY':
                                    parsedData.byweekday = propValue.split(',');
                                    break;

                                case 'UNTIL':
                                    // Fix Assistovet improperly sending an UTC value in UNTIL
                                    parsedData.until = propValue.replace('Z', '');
                                    break;

                                case 'COUNT':
                                    parsedData.count = parseInt(propValue, 10);
                                    break;
                            }
                        })
                    ;
                    break;
            }
        })
    ;

    return [parsedData, exDates];
}

function setUntil(recurrence: string, until: string) {
    return recurrence
        .split('\n')
        .map((line: string) => {
            if (line.substr(0, 'RRULE'.length).toUpperCase() !== 'RRULE') {
                return line;
            }

            const rrule: string = (line.split(':'))[1];
            let untilFixed: boolean = false;

            const updatedRRule = rrule
                .split(';')
                .map((field: string) => {
                    const key: string = (field.split('='))[0];

                    if (['COUNT', 'UNTIL'].indexOf(key.toUpperCase()) === -1) {
                        return field;
                    }

                    if (untilFixed) {
                        return null;
                    }

                    untilFixed = true;

                    return `UNTIL=${until}`;
                })
                .filter((field: string|null) => field !== null)
                .join(';')
            ;

            if (!untilFixed) {
                return `RRULE:${rrule};UNTIL=${until}`;
            }

            return `RRULE:${updatedRRule}`;
        })
        .join('\n')
    ;
}

function extractFullCalendarBusinessHours(businessHour: IBusinessHour, start: Date, end: Date) {
    const localBusinessHourStart = DateTime.fromISO(businessHour.start);
    const localBusinessHourEnd = DateTime.fromISO(businessHour.end);

    const formatOpts: ToISOTimeOptions = {
        includeOffset: false,
        suppressMilliseconds: true,
    };

    if (!businessHour.recurrence) {
        return {
            startTime: localBusinessHourStart.toISOTime(formatOpts),
            endTime: localBusinessHourEnd.toISOTime(formatOpts),
            // weekday is a number between 1 (monday) and 7 (sunday)
            // we have to return a number between 0 (sunday) and 6 (saturday)
            daysOfWeek: [localBusinessHourStart.weekday % 7],
        };
    }

    const rule: RRuleSet = rrulestr(businessHour.recurrence, {forceset: true}) as RRuleSet;
    const timezone = businessHour.timezone;

    const daysOfWeek =
        rule
            .between(start, end)
            .map((occ: Date) => {

                const adjustedStartString =
                    occ
                    .toISOString()
                    .slice(0, -1)
                ;

                const weekday = DateTime
                    .fromISO(adjustedStartString, {zone: timezone})
                    .weekday
                ;

                // weekday is a number between 1 (monday) and 7 (sunday)
                // we have to return a number between 0 (sunday) and 6 (saturday)
                return weekday % 7;
            })
    ;

    return {
        startTime: localBusinessHourStart.toISOTime(formatOpts),
        endTime: localBusinessHourEnd.toISOTime(formatOpts),
        daysOfWeek,
    };
}

/**
 * RRule requires UTC dates to prevent issues with timezone offsets
 * Otherwise, browser default timezone might conflict with organization timezone
 *
 * @param date Date
 */
function formatFullcalendarDateForRRule(date: Date, timezone: string): Date {
    const datetime = DateTime
        .fromJSDate(date, {zone: timezone})
        .setZone('utc', {keepLocalTime: true})
    ;

    // js counts months from 0 to 11...
    return new Date(
        Date.UTC(
            datetime.year,
            datetime.month - 1,
            datetime.day,
            datetime.hour,
            datetime.minute,
        ),
    );
}

const frenchLocales = {
    strings: {
        'every': 'tou(te)s les',
        'until': 'jusqu\'au',
        'day': 'jours',
        'days': 'jours',
        'week': 'semaines',
        'weeks': 'semaines',
        'on': 'le',
        'in': 'en',
        'on the': 'le',
        'for': 'pour',
        'and': 'et',
        'or': 'ou',
        'at': 'à',
        'last': 'dernier',
        '(~ approximate)': ' ',
        'times': 'fois',
        'time': 'fois',
        'minutes': 'minutes',
        'hours': 'heures',
        'weekdays': 'jours de la semaine',
        'weekday': 'jours de la semaine',
        'months': 'mois',
        'month': 'mois',
        'years': 'années',
        'year': 'année',
    },
    names: {
        dayNames: [
            'dimanche',
            'lundi',
            'mardi',
            'mercredi',
            'jeudi',
            'vendredi',
            'samedi',
        ],
        monthNames: [
            'janvier',
            'fevrier',
            'mars',
            'avril',
            'mai',
            'juin',
            'juillet',
            'août',
            'septembre',
            'octobre',
            'novembre',
            'décembre',
        ],
        tokens: [],
    },
    dateFormatter: (year: number, month: string, day: number) => `${day} ${month} ${year}`,
};
