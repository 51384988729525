import {DateTime} from 'luxon';

export const localStorageService = {
    checkValidity,
    store,
    storeObject,
    load,
    loadObject,
    remove,
};

function store(key: string, value: any) {
    localStorage.setItem(key, value);
}

function storeObject(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
}

function load(key: string) {
    return localStorage.getItem(key);
}

function loadObject(key: string, defaultValue: any = null) {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
}

function remove(key: string) {
    localStorage.removeItem(key);
}

function checkValidity(objectDate: string|null) {
    if (!objectDate) {
        return false;
    }

    if (!process.env.VUE_APP_CACHE_DATE) {
        return false;
    }

    return DateTime.fromISO(objectDate) >= DateTime.fromISO(process.env.VUE_APP_CACHE_DATE);
}
