
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {Watch, Prop} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {formHelper} from '@/helpers';

    import {ClientModule, EventModule, SnackbarModule} from '@/store/modules';

    import {
        IClient,
        IClientAddress,
        ICreateClientParams,
        IFetchClientParams,
        IOrganization,
        IFirstEvent,
        IUpdateClientParams,
        IBookingForm,
    } from '@/types';

    const clientNamespace = namespace('client');
    const organizationNamespace = namespace('organization');

    import PhoneInput from '@/components/forms/PhoneInput.vue';
    

    @Component<ClientCardProfile>({
        components: {
            PhoneInput,
        },
    })
    export default class ClientCardProfile extends Vue {
        @Prop({
            type: String,
            required: false,
        })
        public clientToEdit!: string;

        @Prop({
            required: false,
            default: false,
        })
        public booking!: IBookingForm;

        public clientIsCreated: boolean = false;

        public clientFormValid = false;
        public isLoading: boolean = false;
        public innerModel!: IClient;
        public address!: IClientAddress;

        public emailRules = formHelper.getClientEmailRules();
        public lastNameRules = formHelper.getClientLastNameRules();

        @clientNamespace.Getter('currentClient')
        public currentClient!: IClient;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        get addressParams(): IClientAddress|null {
            if (
                (
                    this.currentClient &&
                    !this.isAddressEmpty(this.currentClient.address)
                ) ||
                !this.isAddressEmpty(this.address)
            ) {
                return this.address;
            }

            return null;
        }

        get params(): ICreateClientParams|IUpdateClientParams {
            const params: ICreateClientParams|IUpdateClientParams = {
                first_name: this.innerModel.first_name,
                last_name: this.innerModel.last_name as string,
                email: this.innerModel.email,
                organization_id: this.loggedOrganization.id,
                file_number: this.innerModel.file_number,
                external_link: this.innerModel.external_link,
                address: this.addressParams,
            };

            if (this.innerModel.cell_phone || this.currentClient) {
                params.cell_phone = this.innerModel.cell_phone ?? null;
            }

            if (this.currentClient) {
                (params as IUpdateClientParams).client_id = this.currentClient.id;
            }

            return params;
        }

        public submitButtonClicked() {
            if (this.clientToEdit) {
                return this.submitUpdateClient();
            }
            return this.submitCreateClient();
        }

        public submitCreateClient() {
            this.isLoading = true;
            const clientModule = getModule(ClientModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            clientModule
                .createClient(this.params as ICreateClientParams)
                .then((client: IClient) => {
                    snackbarModule.displaySuccess(
                        `${client.full_name} a bien été créé(e) !`,
                    );
                    this.$emit('change-tab', 1);
                    this.$emit('create-client', client.id);
                    this.clientIsCreated = true;
                    this.isLoading = false;
                }).catch((error)=> {
                    snackbarModule.displayError(error);
                })
            ;
        }

        public submitUpdateClient() {
            this.isLoading = true;
            const clientModule = getModule(ClientModule, this.$store);
            const eventModule = getModule(EventModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            clientModule
                .updateClient(this.params as IUpdateClientParams)
                .then((client: IClient) => {
                    this.$emit('create-client', client.id);
                    snackbarModule.displaySuccess(
                        `${this.innerModel.first_name} ${this.innerModel.last_name} a bien été modifié(e) !`,
                    );
                    this.$emit('close', client.id);
                    this.isLoading = false;
                    
                    if (this.booking) {
                        const params: IFirstEvent = {
                            booking_id: this.booking.id,
                            organization_id: this.loggedOrganization.id,
                        }
    
                        eventModule.fetchEvent(params as IFirstEvent).then();
                    }
                });
        }

        public mounted() {
            (this.$refs.form as any).resetValidation();

            if (this.clientToEdit) {
                const params: IFetchClientParams = {
                    organization_id: this.loggedOrganization.id,
                    client_id: this.clientToEdit,
                };

                getModule(ClientModule, this.$store).fetchClient(params);
            }
        }

        private isAddressEmpty(address: IClientAddress|null) {
            return !address ||
             !(
                 address.label ||
                 address.city ||
                 address.postal_code ||
                 address.country
             )
            ;
        }

        private data() {
            if (this.clientToEdit) {
                return {
                    innerModel: this.currentClient ?
                        JSON.parse(JSON.stringify(this.currentClient)) :
                        {}
                    ,
                    address: this.currentClient?.address ?
                        JSON.parse(JSON.stringify(this.currentClient.address)) :
                        {}
                    ,
                };
            } else {
                return {
                    innerModel: {},
                    address: {},
                };
            }
        }

        @Watch('currentClient', {immediate: true})
        private onCurrentClientChange(newVal: IClient) {
            // dirty fix to prevent tel-input from keeping the previous value
            if (this.clientToEdit) {
                (this.innerModel as IClient | null) = null;
            }

            this.$nextTick(() => {
                this.innerModel = newVal ? JSON.parse(JSON.stringify(newVal)) : {};
                this.address = newVal?.address ? JSON.parse(JSON.stringify(newVal.address)) : {};
            });
        }
    }
