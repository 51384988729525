
export const stripeHelper = {
    confirmStripeCardPayement,
};

function confirmStripeCardPayement(
    clientSecret: string,
    stripeModule: stripe.Stripe,
    stripeElement: stripe.elements.Element,
    type: string,
    owner: stripe.OwnerInfo | null,
) {
    if (type === 'card') {
        let sourceData = {
            payment_method: {
                card: stripeElement,
            }
        };
        return new Promise<stripe.SetupIntentResponse>((resolve, reject) => {
            stripeModule
                .confirmCardSetup(clientSecret, sourceData)
                .then((result: stripe.SetupIntentResponse) => {
                    if (result.error) {
                        reject(result.error);
                    } else {
                        if (result.setupIntent?.status) {
                            resolve(result);
                        } 
                        reject(result.error);
                    }
                })
            ;
        });
    } else if (type === 'sepa_debit' && owner) {
        let sourceData = {
            payment_method: {
                sepa_debit: stripeElement,
                billing_details: {
                    name: owner.name,
                    email: owner.email,
                }
            }
        };
        return new Promise<stripe.SetupIntentResponse>((resolve, reject) => {
            stripeModule
                .confirmSepaDebitSetup(clientSecret, sourceData as any)
                .then((result: stripe.SetupIntentResponse) => {
                    if (result.error) {
                        reject(result.error);
                    } else {
                        if (result.setupIntent?.status) {
                            resolve(result);
                        } 
                        reject(result.error);
                    }
                })
            ;
        });
    }

}

