import {Module, VuexModule, Action, Mutation} from 'vuex-module-decorators';
import Vuetify from '@/plugins/vuetify';

import {
    CLEAR_STATE,
} from '@/types/store/mutations/store.mutations';

import {
    SET_DRAWER_OPENED,
    SET_DRAWER_LEFT_OPENED,
    SET_DRAWER_NOTIFICATION_OPENED,
    TOGGLE_DRAWER,
    TOGGLE_DRAWER_LEFT,
    TOGGLE_DRAWER_NOTIFICATION,
} from '@/types/store/mutations/layout.mutations';

@Module({
    namespaced: true,
    name: 'layout',
})
export class LayoutModule extends VuexModule {
    public drawerOpened = false;
    public drawerLeftOpened = Vuetify.framework.breakpoint.lgAndUp;
    public drawerNotificationOpened = false;

    get isDrawerOpened(): boolean {
        return this.drawerOpened;
    }

    get isDrawerLeftOpened(): boolean {
        if (Vuetify.framework.breakpoint.mdAndDown) {
            return this.drawerLeftOpened;
        }

        return true;
    }

    get isDrawerNotificationOpened(): boolean {
        return this.drawerNotificationOpened;
    }

    @Action({rawError: true})
    public toggleDrawer() {
        this.context.commit(TOGGLE_DRAWER);
    }

    @Action({rawError: true})
    public setDrawerOpened(value: boolean) {
        this.context.commit(SET_DRAWER_OPENED, value);
    }

    @Action({rawError: true})
    public toggleDrawerLeft() {
        this.context.commit(TOGGLE_DRAWER_LEFT);
    }

    @Action({rawError: true})
    public setDrawerLeftOpened(value: boolean) {
        this.context.commit(SET_DRAWER_LEFT_OPENED, value);
    }

    @Action({ rawError: true })
    public toggleNotificationDrawer() {
        this.context.commit(TOGGLE_DRAWER_NOTIFICATION);
    }

    @Action({ rawError: true })
    public setDrawerNotificationOpened(value: boolean) {
        this.context.commit(SET_DRAWER_NOTIFICATION_OPENED, value);
    }

    @Mutation
    private [TOGGLE_DRAWER]() {
        this.drawerOpened = !this.drawerOpened;
    }

    @Mutation
    private [SET_DRAWER_OPENED](value: boolean) {
        this.drawerOpened = value;
    }

    @Mutation
    private [TOGGLE_DRAWER_LEFT]() {
        this.drawerLeftOpened = !this.drawerLeftOpened ;
    }

    @Mutation
    private [TOGGLE_DRAWER_NOTIFICATION]() {
        this.drawerNotificationOpened = !this.drawerNotificationOpened ;
    }

    @Mutation
    private [SET_DRAWER_LEFT_OPENED](value: boolean) {
        this.drawerLeftOpened = value;
    }

    @Mutation
    private [SET_DRAWER_NOTIFICATION_OPENED](value: boolean) {
        this.drawerNotificationOpened = value;
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.drawerOpened = false;
        this.drawerLeftOpened = false;
    }
}
