
import Vue from 'vue';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';

import {localStorageService} from '@/storage/localstorage.service';

import {
    IAvatar,
    IChangeOrganizationVeterinarianRoleParams,
    ICreateOrganizationVeterinarianParams,
    IDeleteVeterinarianPictureParams,
    IInviteOrganizationVeterinarianParams,
    IPostVeterinarianAvatarParams,
    IPostVeterinarianPicturesParams,
    IPostVeterinarianPicturesResult,
    ISpecialty,
    IUpdateOrganizationVeterinarianParams,
    IUpdateVeterinarianParams,
    IVeterinarian,
    IVeterinarianPicture,
} from '@/types';

import {
    CLEAR_STATE,
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';

import {
    DELETE_VETERINARIAN_PICTURE,
    SET_SPECIALTIES,
    SET_VETERINARIAN,
    SET_VETERINARIAN_AVATAR,
    SET_VETERINARIAN_PICTURES,
    SPECIALTIES_REQUEST,
    UPDATE_VETERINARIAN,
} from '@/types/store/mutations/veterinarian.mutations';

@Module({
    namespaced: true,
    name: 'veterinarian',
})
export class VeterinarianModule extends VuexModule {
    public veterinarian: IVeterinarian|null = localStorageService.loadObject('veterinarian');
    public status: string|null = null;
    public specialties: ISpecialty[] = [];

    private specialtiesRequest: Promise<ISpecialty[]>|null = null;

    get specialtiesList(): ISpecialty[] {
        return this.specialties;
    }

    get loggedVeterinarian(): IVeterinarian|null {
        return this.veterinarian;
    }

    @Action({rawError: true})
    public async fetchSpecialties(): Promise<ISpecialty[]> {
        if (this.specialties.length > 0) {
            return new Promise<ISpecialty[]>((resolve) => resolve(this.specialties));
        }

        if (!this.specialtiesRequest) {
            this.context.commit(SPECIALTIES_REQUEST, new Promise<ISpecialty[]>((resolve, reject) => {
                (Vue.prototype as Vue).$api.veterinarian
                    .specialtiesList()
                        .then((response: ISpecialty[]) => {
                            this.context.commit(REQUEST_SUCCESS);
                            this.context.commit(SET_SPECIALTIES, response);
                            resolve(response);
                        })
                        .catch((error) => {
                            this.context.commit(REQUEST_ERROR);
                            reject(error);
                        })
                    ;
            }));
        }

        return this.specialtiesRequest as Promise<ISpecialty[]>;
    }

    @Action({rawError: true})
    public async createVeterinarian(data: ICreateOrganizationVeterinarianParams): Promise<IVeterinarian> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.veterinarian
                .createVeterinarian(data)
                .then((response: IVeterinarian) => {
                    this.context.commit(REQUEST_SUCCESS);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async inviteVeterinarian(data: IInviteOrganizationVeterinarianParams): Promise<IVeterinarian> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.veterinarian
                .inviteVeterinarian(data)
                .then((response: IVeterinarian) => {
                    this.context.commit(REQUEST_SUCCESS);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async changeVeterinarianRole(data: IChangeOrganizationVeterinarianRoleParams): Promise<IVeterinarian> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.veterinarian
                .changeVeterinarianRole(data)
                .then((response: IVeterinarian) => {
                    this.context.commit(REQUEST_SUCCESS);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async updateVeterinarian(data: IUpdateOrganizationVeterinarianParams): Promise<IVeterinarian> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.veterinarian
                .updateVeterinarian(data)
                .then((response: IVeterinarian) => {
                    this.context.commit(REQUEST_SUCCESS);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({ rawError: true })
    public async fetchVeterinarian(id: string): Promise<IVeterinarian> {
        return new Promise<IVeterinarian>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.veterinarian
                .getVeterinarian(id)
                .then((response: IVeterinarian) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_VETERINARIAN, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({ rawError: true })
    public async update(veterinarian: IUpdateVeterinarianParams): Promise<IVeterinarian> {
        return new Promise<IVeterinarian>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.veterinarian
                .updateLoggedVeterinarian(veterinarian)
                .then((response: IVeterinarian) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(UPDATE_VETERINARIAN);

                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({ rawError: true })
    public async postPictures(params: IPostVeterinarianPicturesParams): Promise<IPostVeterinarianPicturesResult> {
        return new Promise<IPostVeterinarianPicturesResult>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.veterinarian
                .postPictures(params)
                .then((response: IPostVeterinarianPicturesResult) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_VETERINARIAN_PICTURES, response.uploaded);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({ rawError: true })
    public async deletePicture(params: IDeleteVeterinarianPictureParams): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.veterinarian
                .deletePicture(params)
                .then((response: boolean) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(DELETE_VETERINARIAN_PICTURE, params.picture_id);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({ rawError: true })
    public async postAvatar(params: IPostVeterinarianAvatarParams): Promise<IAvatar> {
        return new Promise<IAvatar>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.veterinarian
                .postAvatar(params)
                .then((response: IAvatar) => {
                    this.context.commit(REQUEST_SUCCESS);

                    this.context.commit(SET_VETERINARIAN_AVATAR, response);

                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }


    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
        this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [SPECIALTIES_REQUEST](promise: Promise<ISpecialty[]>) {
        this.status = 'loading';
        this.specialtiesRequest = promise;
    }

    @Mutation
    private [SET_SPECIALTIES](data: ISpecialty[]) {
        this.specialties = data;
        localStorageService.storeObject('specialties', data);
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.status = null;
        this.veterinarian = null;

        localStorageService.remove('veterinarian');
    }

    @Mutation
    private [SET_VETERINARIAN_PICTURES](pictures: IVeterinarianPicture[]) {
        pictures.forEach((picture) => {
            if (this.veterinarian) {
                this.veterinarian.pictures.push(picture);
            }
        });
        localStorageService.storeObject('veterinarian', this.veterinarian);
    }

    @Mutation
    private [DELETE_VETERINARIAN_PICTURE](data: string) {
        if (this.veterinarian) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.veterinarian.pictures.length; ++i) {
                if (this.veterinarian.pictures[i].id === data) {
                    this.veterinarian.pictures.splice(i, 1);
                    break;
                }
            }
        }
        localStorageService.storeObject('veterinarian', this.veterinarian);
    }

    @Mutation
    private [SET_VETERINARIAN](data: IVeterinarian) {
        this.veterinarian = data;
        localStorageService.storeObject('veterinarian', data);
    }

    @Mutation
    private [UPDATE_VETERINARIAN](params: IUpdateVeterinarianParams) {
        this.veterinarian = Object.assign(this.veterinarian as IVeterinarian, params);
    }

    @Mutation
    private [SET_VETERINARIAN_AVATAR](data: IAvatar) {
        if (this.veterinarian) {
            this.veterinarian.avatar = data;
            localStorageService.storeObject('veterinarian', this.veterinarian);
        }
    }
}
