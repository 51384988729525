
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {DateTime} from 'luxon';

    import {
        IAccount,
        IBooking,
        IBookingFile,
        ICreateBookingFilesError,
        ICreateBookingFilesParams,
        ICreateBookingFilesResult,
        IDeleteBookingFilesParams,
        IOrganization,
    } from '@/types';

    import {EventModule, SnackbarModule} from '@/store/modules';

    const accountNamespace = namespace('account');
    const eventNamespace = namespace('event');
    const organizationNamespace = namespace('organization');

    @Component<BookingFilesCard>({})
    export default class BookingFilesCard extends Vue {
        public confirmDeleteFileDialog: boolean = false;
        public isLoadingFiles: boolean = false;

        public isUploadingFiles: boolean = false;
        public isDeletingFile: boolean = false;

        public filesToUpload: File[] = [];
        public fileToDelete: IBookingFile|null = null;

        public DATE_SHORT = DateTime.DATE_SHORT;

        public filesHeaders = [
            {
                text: 'Nom',
                value: 'name',
            },
            {
                text: 'Taille',
                value: 'size',
            },
            {
                text: 'Propriétaire',
                value: 'owner',
            },
            {
                text: 'Date',
                value: 'inserted_at',
            },
            {
                text: '',
                value: 'actions',
            },
        ];

        @Prop({
            type: Object,
            required: true,
        })
        public booking!: IBooking;

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount;

        @eventNamespace.Getter('filesList')
        public files!: IBookingFile[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        private uploadFiles() {
            this.isUploadingFiles = true;

            const formData = new FormData();

            this.filesToUpload.forEach((file: File, index: number) => {
                formData.append(`files[${index}]`, file);
            });

            const params: ICreateBookingFilesParams = {
                organization_id: this.loggedOrganization.id,
                booking_id: this.booking.booking.id,
                files: formData,
            };

            const eventModule = getModule(EventModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            eventModule
                .addBookingFiles(params)
                .then((response: ICreateBookingFilesResult) => {
                    if (response.errors.length > 0) {
                        const failedFiles =
                            response.errors.map((value: ICreateBookingFilesError) => {
                                return value.file;
                            })
                                .join(', ')
                        ;

                        snackbarModule.displayError(`Une erreur est survenue pendant l'envoi des fichiers suivant : ${failedFiles}`);
                    } else {
                        snackbarModule.displaySuccess('Vos fichiers ont bien été ajoutés !');
                    }

                    this.filesToUpload = [];
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isUploadingFiles = false)
            ;
        }

        private getFileLink(file: IBookingFile) {
            this.$api.event
                .getBookingFile({
                    organization_id: this.loggedOrganization.id,
                    booking_id: this.booking.booking.id,
                    file_id: file.id,
                })
                .then((url: string) => window.open(url, '_blank'))
            ;
        }

        private deleteFile(file: IBookingFile) {
            this.fileToDelete = file;
            this.confirmDeleteFileDialog = true;
        }

        private sendDeleteFileRequest() {
            if (!this.fileToDelete) {
                return;
            }

            this.isDeletingFile = true;

            const eventModule = getModule(EventModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            const params: IDeleteBookingFilesParams = {
                organization_id: this.loggedOrganization.id,
                booking_id: this.booking.booking.id,
                file_id: this.fileToDelete.id,
            };

            eventModule
                .deleteBookingFile(params)
                .then(() => snackbarModule.displaySuccess('Votre fichier a bien été supprimé !'))
                .catch(() => snackbarModule.displayError())
                .finally(() => {
                    this.fileToDelete = null;
                    this.isDeletingFile = false;
                })
            ;
        }

        private mounted() {
            this.isLoadingFiles = true;

            const eventModule = getModule(EventModule, this.$store);

            eventModule
                .listBookingFiles({
                    organization_id: this.loggedOrganization.id,
                    booking_id: this.booking.booking.id,
                })
                .finally(() => this.isLoadingFiles = false)
            ;
        }
    }
