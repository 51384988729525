
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {namespace} from 'vuex-class';
    import {Model} from 'vue-property-decorator';

    import {ClientModule, SnackbarModule} from '@/store/modules';

    import {
        IClient,
        IClientAddress,
        ICreateClientParams,
        IOrganization,
        IUpdateClientParams,
    } from '@/types';

    import PhoneInput from '@/components/forms/PhoneInput.vue';

    import {formHelper} from '@/helpers';

    const organizationNamespace = namespace('organization');

    @Component<ClientFormCard>({
        components: {
            PhoneInput,
        },
    })
    export default class ClientFormCard extends Vue {
        public clientFormValid = false;
        public birthDateMenu = false;

        public innerModel!: IClient;
        public address!: IClientAddress;

        public emailRules = formHelper.getClientEmailRules();
        public lastNameRules = formHelper.getClientLastNameRules();

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @Model('input', {type: Object}) public readonly value!: IClient;

        get cardTitle() {
            if (this.innerModel.id) {
                return 'Modifier un client';
            }

            return 'Ajouter un client';
        }

        get submitButtonTitle() {
            if (this.innerModel.id) {
                return 'Modifier';
            }

            return 'Ajouter';
        }

        get addressParams(): IClientAddress|null {
            if (
                this.innerModel.id ||
                this.address?.label ||
                this.address?.postal_code ||
                this.address?.city ||
                this.address?.country
            ) {
                return this.address;
            }

            return null;
        }

        get params(): ICreateClientParams|IUpdateClientParams {
            const params: ICreateClientParams|IUpdateClientParams = {
                first_name: this.innerModel.first_name,
                last_name: this.innerModel.last_name as string,
                cell_phone: this.innerModel.cell_phone,
                email: this.innerModel.email,
                organization_id: this.loggedOrganization.id,
                file_number: this.innerModel.file_number,
                external_link: this.innerModel.external_link,
                address: this.addressParams,
            };

            if (this.innerModel.id) {
                (params as IUpdateClientParams).client_id = this.innerModel.id;
            }

            return params;
        }

        public submitButtonClicked() {
            if (this.innerModel.id) {
                return this.submitUpdateClient();
            }
            return this.submitCreateClient();
        }

        public submitCreateClient() {
            const clientModule = getModule(ClientModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            clientModule
                .createClient(this.params as ICreateClientParams)
                .then((client: IClient) => {
                    const fullName = `${this.innerModel.first_name ?? ''} ${this.innerModel.last_name}`.trim();

                    snackbarModule.displaySuccess(
                        `${fullName} a bien été créé(e) !`,
                    );

                    this.$emit('input', client);
                })
            ;
        }

        public submitUpdateClient() {
            const clientModule = getModule(ClientModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            clientModule
                .updateClient(this.params as IUpdateClientParams)
                .then((client: IClient) => {
                    const fullName = `${this.innerModel.first_name ?? ''} ${this.innerModel.last_name}`.trim();

                    snackbarModule.displaySuccess(
                        `${fullName} a bien été modifié(e) !`,
                    );

                    this.$emit('input', client);
                })
            ;
        }

        private data() {
            return {
                innerModel: Object.assign({}, this.value),
                address: this.value?.address ? this.value.address : {},
            };
        }
    }
