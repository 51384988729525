import {
    IAnimal,
    IBreed,
    ICreateAnimalParams,
    IDeleteAnimalParams,
    ISpecies,
    IUpdateAnimalParams,
    IFetchAnimalsParams,
} from '@/types';

import AbstractService from './abstract.service';

export class AnimalService extends AbstractService {
    public typesList() {
        return new Promise<ISpecies[]>((resolve, reject) => {
            this.api({
                url: `/animals/types`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.types);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public speciesList() {
        return new Promise<ISpecies[]>((resolve, reject) => {
            this.api({
                url: `/animals/species`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.species);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public breedsList() {
        return new Promise<IBreed[]>((resolve, reject) => {
            this.api({
                url: `/animals/breeds`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.breeds);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public list(params: IFetchAnimalsParams) {
        const organizationId = params.organization_id;
        const clientId = params.client_id;

        delete (params as IFetchAnimalsParams).organization_id;
        delete (params as IFetchAnimalsParams).client_id;

        return new Promise<IAnimal[]>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/clients/${clientId}/animals`,
                method: 'GET',
                params,
            })
                .then((response) => {
                    resolve(response.data.animals);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public create(params: ICreateAnimalParams) {
        const organizationId = params.organization_id;
        const clientId = params.client_id;

        delete (params as ICreateAnimalParams).organization_id;
        delete (params as ICreateAnimalParams).client_id;

        return new Promise<IAnimal>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/clients/${clientId}/animals`,
                method: 'POST',
                data: params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public update(params: IUpdateAnimalParams) {
        const animalId = params.animal_id;
        const organizationId = params.organization_id;
        const clientId = params.client_id;

        delete (params as IUpdateAnimalParams).animal_id;
        delete (params as IUpdateAnimalParams).organization_id;
        delete (params as IUpdateAnimalParams).client_id;

        return new Promise<IAnimal>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/clients/${clientId}/animals/${animalId}`,
                method: 'PUT',
                data: params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public remove(params: IDeleteAnimalParams) {
        return new Promise<boolean>((resolve, reject) => {
            const organizationId = params.organization_id;
            const clientId = params.client_id;
            const animalId = params.animal_id;

            this.api({
                url: `/organizations/${organizationId}/clients/${clientId}/animals/${animalId}`,
                method: 'DELETE',
            })
                .then((response) => {
                    resolve(true);
                })
                .catch((error) => {
                    if (error.response.data.error === 'animal_already_deleted') {
                        resolve(true);
                    }

                    reject(error);
                })
            ;
        });
    }
}
