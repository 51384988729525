import Vue from 'vue';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';

import {localStorageService} from '@/storage/localstorage.service';

import {
    IChangeOrganizationParaveterinarianRoleParams,
    ICreateOrganizationParaveterinarianParams,
    IInviteOrganizationParaveterinarianParams,
    IParaveterinarian,
    IUpdateParaveterinarianPresentationParams,
} from '@/types';

import {
    CLEAR_STATE,
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';

import {
    SET_PARAVETERINARIAN,
    UPDATE_PARAVETERINARIAN,
} from '@/types/store/mutations/paraveterinarian.mutations';

@Module({
    namespaced: true,
    name: 'paraveterinarian',
})
export class ParaveterinarianModule extends VuexModule {
    public paraveterinarian: IParaveterinarian|null = localStorageService.loadObject('paraveterinarian');
    public status: string|null = null;

    get loggedParaveterinarian(): IParaveterinarian|null {
        return this.paraveterinarian;
    }

    @Action({rawError: true})
    public async fetchParaveterinarian(id: string): Promise<IParaveterinarian> {
        return new Promise<IParaveterinarian>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.paraveterinarian
                .getParaveterinarian(id)
                .then((response: IParaveterinarian) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_PARAVETERINARIAN, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({rawError: true})
    public async createParaveterinarian(data: ICreateOrganizationParaveterinarianParams): Promise<IParaveterinarian> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.paraveterinarian
                .createParaveterinarian(data)
                .then((response: IParaveterinarian) => {
                    this.context.commit(REQUEST_SUCCESS);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async inviteParaveterinarian(data: IInviteOrganizationParaveterinarianParams): Promise<IParaveterinarian> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.paraveterinarian
                .inviteParaveterinarian(data)
                .then((response: IParaveterinarian) => {
                    this.context.commit(REQUEST_SUCCESS);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async changeParaveterinarianRole(
        data: IChangeOrganizationParaveterinarianRoleParams,
    ): Promise<IParaveterinarian> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.paraveterinarian
                .changeParaveterinarianRole(data)
                .then((response: IParaveterinarian) => {
                    this.context.commit(REQUEST_SUCCESS);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async update(paraveterinarian: IUpdateParaveterinarianPresentationParams): Promise<IParaveterinarian> {
        return new Promise<IParaveterinarian>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.paraveterinarian
                .updateLoggedParaveterinarian(paraveterinarian)
                .then((response: IParaveterinarian) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(UPDATE_PARAVETERINARIAN);

                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
        this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.status = null;
        this.paraveterinarian = null;

        localStorageService.remove('paraveterinarian');
    }

    @Mutation
    private [SET_PARAVETERINARIAN](data: IParaveterinarian) {
        this.paraveterinarian = data;
        localStorageService.storeObject('paraveterinarian', data);
    }

    @Mutation
    private [UPDATE_PARAVETERINARIAN](params: IUpdateParaveterinarianPresentationParams) {
        this.paraveterinarian = Object.assign(this.paraveterinarian as IParaveterinarian, params);
    }
}
