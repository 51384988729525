import {
    CreateOrganizationParams,
    IAccountResponse,
    IAvatar,
    ICancelOrganizationJoinRequestParams,
    ICreateSubscriptionParams,
    IDeleteOrganizationParaveterinarian,
    IDeleteOrganizationPictureParams,
    IDeleteOrganizationVeterinarian,
    IDeleteOrganizationVeterinarianPictureParams,
    IGetInvoiceParams,
    IGetOrganizationVeterinarianParams,
    IInvoice,
    IOrganization,
    IOrganizationJoinRequest,
    IOrganizationSearchParams,
    IOrganizationSocials,
    IOrganizationSubscription,
    IOrganizationType,
    IParaveterinarian,
    IPostOrganizationAvatarParams,
    IPostOrganizationPicturesParams,
    IPostOrganizationPicturesResult,
    IPostOrganizationSocialsParams,
    IPostOrganizationVeterinarianAvatarParams,
    IPostOrganizationVeterinarianPicturesParams,
    IPostVeterinarianPicturesResult,
    IUpdateOrganizationJoinRequestParams,
    IUpdateOrganizationParams,
    IUpdateOrganizationVeterinarianParams,
    IUpdateSubscriptionParams,
    IVeterinarian,
    ICreateSetupIntentParams,
    IOrganizationInvoce,
} from '@/types';

import AbstractService from './abstract.service';

export class OrganizationService extends AbstractService {
    public create(params: CreateOrganizationParams) {
        return new Promise<IAccountResponse>((resolve, reject) => {
            this.api({
                url: `/organizations`,
                data: params,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public reset(organizationId: string) {
        return new Promise<void>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/reset`,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public getSubscription(organizationId: string) {
        return new Promise<IOrganizationSubscription>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/subscription`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public createSubscription(params: ICreateSubscriptionParams, organizationId: string) {
        return new Promise<IOrganizationSubscription>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/subscription`,
                data: params,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public createSetupIntent(params: ICreateSetupIntentParams, organizationId: string) {
        return new Promise<IOrganizationInvoce>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/setup-intent`,
                data: params,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public updateSubscription(params: IUpdateSubscriptionParams, organizationId: string) {
        return new Promise<IOrganizationSubscription>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/subscription`,
                data: params,
                method: 'PUT',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public search(params: IOrganizationSearchParams) {
        return new Promise<IOrganization[]>((resolve, reject) => {
            this.api({
                url: `/organizations`,
                method: 'GET',
                params,
            })
                .then((response) => {
                    resolve(response.data.organizations);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public typesList() {
        return new Promise<IOrganizationType[]>((resolve, reject) => {
            this.api({
                url: `/organizations/types`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.organization_types);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public getOrganization(organizationId: string) {
        return new Promise<IOrganization>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
                ;
        });
    }

    public updatePresentation(params: IUpdateOrganizationParams) {
        const organization_id = params.organization_id;
        delete (params as IUpdateOrganizationParams).organization_id;

        return new Promise<IOrganization>((resolve, reject) => {
            this.api({
                url: `/organizations/${organization_id}`,
                method: 'PUT',
                data: params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
                ;
        });
    }

    public postAvatar(params: IPostOrganizationAvatarParams, organizationId: string) {
        return new Promise<IAvatar>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/avatars`,
                data: params.avatar,
                method: 'POST',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
                ;
        });
    }

    public postPictures(params: IPostOrganizationPicturesParams) {
        return new Promise<IPostOrganizationPicturesResult>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/pictures`,
                method: 'POST',
                data: params.pictures,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public deletePicture(params: IDeleteOrganizationPictureParams) {
        return new Promise<boolean>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/pictures/${params.picture_id}`,
                method: 'DELETE',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public postSocials(params: IPostOrganizationSocialsParams) {
        return new Promise<IOrganizationSocials>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/social-links`,
                data: params.social_links,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
                ;
        });
    }

    public veterinariansList(organizationId: string) {
        return new Promise<IVeterinarian[]>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/veterinarians`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.veterinarians);
                })
                .catch((error) => {
                    reject(error);
                })
                ;
        });
    }

    public paraveterinariansList(organizationId: string) {
        return new Promise<IParaveterinarian[]>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/paraveterinarians`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.paraveterinarians);
                })
                .catch((error) => {
                    reject(error);
                })
                ;
        });
    }

    public joinRequestsList(organizationId: string) {
        return new Promise<IOrganizationJoinRequest[]>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/join-requests`,
                method: 'GET',
                params: {status: 'pending'},
            })
                .then((response) => {
                    resolve(response.data.organization_join_requests);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public updateJoinRequest(params: IUpdateOrganizationJoinRequestParams) {
        const organizationId = params.organization_id;
        const joinRequestId = params.join_request_id;

        return new Promise<IOrganizationJoinRequest>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/join-requests/${joinRequestId}`,
                method: 'PUT',
                data: {status: params.status},
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public cancelJoinRequest(params: ICancelOrganizationJoinRequestParams) {
        const organizationId = params.organization_id;
        const joinRequestId = params.join_request_id;

        return new Promise<boolean>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/join-requests/${joinRequestId}`,
                method: 'DELETE',
            })
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public createjoinRequest(organizationId: string) {
        return new Promise<IOrganizationJoinRequest>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/join-requests`,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public getVeterinarian(params: IGetOrganizationVeterinarianParams) {
        return new Promise<IVeterinarian>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/veterinarians/${params.veterinarian_id}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
                ;
        });
    }

    public deleteVeterinarian(params: IDeleteOrganizationVeterinarian) {
        return new Promise<IVeterinarian>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/veterinarians/${params.veterinarian_id}`,
                method: 'DELETE',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public deleteParaveterinarian(params: IDeleteOrganizationParaveterinarian) {
        return new Promise<IParaveterinarian>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/paraveterinarians/${params.paraveterinarian_id}`,
                method: 'DELETE',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public postOrganizationVeterinarianAvatar(params: IPostOrganizationVeterinarianAvatarParams) {
        return new Promise<IAvatar>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/veterinarians/${params.veterinarian_id}/avatars`,
                data: params.avatar,
                method: 'POST',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
                ;
        });
    }

    public updateOrganizationVeterinarian(params: IUpdateOrganizationVeterinarianParams) {
        return new Promise<IVeterinarian>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/veterinarians/${params.veterinarian_id}`,
                method: 'PUT',
                data: params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public postOrganizationVeterinarianPictures(params: IPostOrganizationVeterinarianPicturesParams) {
        return new Promise<IPostVeterinarianPicturesResult>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/veterinarians/${params.veterinarian_id}/pictures`,
                method: 'POST',
                data: params.pictures,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public deleteOrganizationVeterinarianPicture(params: IDeleteOrganizationVeterinarianPictureParams) {
        return new Promise<boolean>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/veterinarians/${params.veterinarian_id}/pictures/${params.picture_id}`,
                method: 'DELETE',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public getSyncToken(organizationId: string) {
        return new Promise<string>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/synchronization-token`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
                ;
        });
    }

    public generateSyncToken(organizationId: string, type: string) {
        return new Promise<string>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/synchronization-token`,
                method: 'POST',
                data: {
                    sync_type: type,
                },
            })
                .then((response) => {
                    resolve(response.data.token);
                })
                .catch((error) => {
                    reject(error);
                })
                ;
        });
    }

    public deleteSyncToken(organizationId: string) {
        return new Promise<void>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/synchronization-token`,
                method: 'DELETE',
            })
            .then((response) => {
                resolve();
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public listInvoices(organizationId: string) {
        return new Promise<IInvoice[]>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/invoices`,
                method: 'GET',
            })
            .then((response) => {
                resolve(response.data.invoices);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public getInvoice(params: IGetInvoiceParams) {
        return new Promise<IInvoice>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/invoices/${params.invoice_id}`,
                method: 'GET',
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }
}
