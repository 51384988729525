import {
    IPlacePosition,
    IPlaceSuggestion,
} from '@/types';

import AbstractService from './abstract.service';

export class PlaceService extends AbstractService {
    public placeAutocomplete(val: string) {
        return new Promise<IPlaceSuggestion[]>((resolve, reject) => {
            this.api({
                url: `/pro-places?q=${encodeURIComponent(val)}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.suggestions);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public reverseGeocode(coords: IPlacePosition) {
        return new Promise<IPlaceSuggestion>((resolve, reject) => {
            const id = `reverse_geocode::${coords.latitude},${coords.longitude}`;

            this.api({
                url: `/pro-places/${id}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }
}
