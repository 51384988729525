import Vue from 'vue';
import {Module, VuexModule, Action, Mutation} from 'vuex-module-decorators';

import {localStorageService} from '@/storage/localstorage.service';

import {
    IRegistration,
    IInvitation,
} from '@/types';

import {
    SET_REGISTRATION_DATA,
    CLEAR_REGISTRATION_DATA,
    SET_INVITATION_DATA,
} from '@/types/store/mutations/registration.mutations';

@Module({
    namespaced: true,
    name: 'registration',
})
export class RegistrationModule extends VuexModule {
    public registrationData: IRegistration|null = localStorageService.loadObject('registration_data', null);

    private invitationData: IInvitation|null = localStorageService.loadObject('invitation_data', null);

    get invitation(): IInvitation|null {
        return this.invitationData;
    }

    get registration(): IRegistration|null {
        return this.registrationData;
    }


    @Action({rawError: true})
    public fetchInvitation(invitationId: string): Promise<IInvitation> {
        return new Promise((resolve, reject) => {

            (Vue.prototype as Vue).$api.account
                .invitation(invitationId)
                .then((response: IInvitation) => {
                    this.context.commit(SET_INVITATION_DATA, response);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    @Action({rawError: true})
    public setRegistrationData(data: IRegistration) {
        this.context.commit(SET_REGISTRATION_DATA, data);
    }

    @Action({rawError: true})
    public clearRegistrationData() {
        this.context.commit(CLEAR_REGISTRATION_DATA);
    }

    @Mutation
    private [SET_INVITATION_DATA](data: IInvitation) {
        this.invitationData = data;
        localStorageService.storeObject('invitation_data', this.invitationData);
    }

    @Mutation
    private [SET_REGISTRATION_DATA](data: IRegistration) {
        this.registrationData = data;
        localStorageService.storeObject('registration_data', this.registrationData);
    }

    @Mutation
    private [CLEAR_REGISTRATION_DATA]() {
        this.registrationData = null;
        this.invitationData = null;
        localStorageService.remove('registration_data');
        localStorageService.remove('invitation_data');
    }
}
