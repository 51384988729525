import Vue from 'vue';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';

import {
    IOrganizationInstruction,
    IUpdateOrganizationInstructionParams,
    ISearchInstructionsParams,
    ISearchInstructionsResult,
    ISearchParams,
    IInstructionPicture,
    IPostInstructionPicturesParams,
    IPostInstructionPicturesResult,
    IDeleteInstructionPictureParams,
    IDeleteOrganizationInstructionParams,
    IInstruction,
} from '@/types';


import {
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';


import {
    SET_ENABLED_INSTRUCTIONS_TOTAL,
    SET_ENABLED_INSTRUCTIONS,
    SET_DISABLED_INSTRUCTIONS_TOTAL,
    SET_DISABLED_INSTRUCTIONS,
    SET_INSTRUCTION_PICTURES,
    SET_ENABLED_INSTRUCTIONS_PICTURE,
    SET_PUBLIC_INSTRUCTIONS,
} from '@/types/store/mutations/instruction.mutations';

@Module({
    namespaced: true,
    name: 'instruction',
})
export class InstructionModule extends VuexModule {
    public status: string|null = null;
    public enabledInstructions: IOrganizationInstruction[] = [];
    public disabledInstructions: IOrganizationInstruction[] = [];
    public enabledInstructionsTotal: number = 0;
    public disabledInstructionsTotal: number = 0;
    public instuctionPictures: IInstructionPicture[] = [];
    public publicInstructions: IOrganizationInstruction[] = [];
    public instruction: IInstruction[] = [];

    get enabledInstructionsList(): IOrganizationInstruction[] {
        return this.enabledInstructions;
    }

    get disabledInstructionsList(): IOrganizationInstruction[] {
        return this.disabledInstructions;
    }

    get publicInstructionsList(): IOrganizationInstruction[] {
        return this.publicInstructions;
    }

    get getEnabledInstructionsTotal(): number {
        return this.enabledInstructionsTotal;
    }

    get getDisabledInstructionsTotal(): number {
        return this.disabledInstructionsTotal;
    }

    @Action({rawError: true})
    public async fetchInstructionsEnabled(params: ISearchInstructionsParams): Promise<IOrganizationInstruction[]> {
        return new Promise<IOrganizationInstruction[]>((resolve, reject) => {
            (Vue.prototype as Vue).$api.instruction
                .listInstructions(params)
                .then((response: IOrganizationInstruction[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ENABLED_INSTRUCTIONS_TOTAL, response);
                    this.context.commit(SET_ENABLED_INSTRUCTIONS, response);
                    resolve(response);
                })
                
            ;
        });
    }

    @Action({rawError: true})
    public async fetchInstructionsDisabled(params: ISearchInstructionsParams): Promise<IOrganizationInstruction[]> {
        return new Promise<IOrganizationInstruction[]>((resolve, reject) => {
            (Vue.prototype as Vue).$api.instruction
                .listInstructions(params)
                .then((response: IOrganizationInstruction[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_DISABLED_INSTRUCTIONS_TOTAL, response);
                    this.context.commit(SET_DISABLED_INSTRUCTIONS, response);
                    resolve(response);
                })
                .catch((error: Error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async createInstruction(params: IOrganizationInstruction): Promise<IOrganizationInstruction> {
        return new Promise<IOrganizationInstruction>((resolve, reject) => {
            (Vue.prototype as Vue).$api.instruction
                .create(params)
                .then((response: IOrganizationInstruction) => {
                    this.context.commit(REQUEST_SUCCESS);
                    resolve(response);
                })
                .catch((error: Error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async fetchInstructionsPublic(params: ISearchParams): Promise<IOrganizationInstruction[]> {
        return new Promise<IOrganizationInstruction[]>((resolve, reject) => {
            (Vue.prototype as Vue).$api.instruction
                .listPublicInstructions(params)
                .then((response: IOrganizationInstruction[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_PUBLIC_INSTRUCTIONS, response);
                    resolve(response);
                })
                .catch((error: Error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async updateInstruction(params: IUpdateOrganizationInstructionParams): Promise<IOrganizationInstruction> {
        return new Promise<IOrganizationInstruction>((resolve, reject) => {
            (Vue.prototype as Vue).$api.instruction
                .update(params)
                .then((response: IOrganizationInstruction) => {
                    this.context.commit(REQUEST_SUCCESS);
                    resolve(response);
                })
                .catch((error: Error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async deleteInstruction(params: IDeleteOrganizationInstructionParams): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            (Vue.prototype as Vue).$api.instruction
                .delete(params)
                .then(() => {
                    this.context.commit(REQUEST_SUCCESS);
                    resolve();
                })
                .catch((error: Error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({ rawError: true })
    public async postPictures(params: IPostInstructionPicturesParams): Promise<IPostInstructionPicturesResult> {
        return new Promise<IPostInstructionPicturesResult>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.instruction
                .postPictures(params)
                .then((response: IPostInstructionPicturesResult) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_INSTRUCTION_PICTURES, response.uploaded);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async deletePicture(params: IDeleteInstructionPictureParams): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.instruction
                .deletePicture(params)
                .then((response: boolean) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ENABLED_INSTRUCTIONS_PICTURE, params);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
        this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [SET_ENABLED_INSTRUCTIONS](data: IOrganizationInstruction[]) {
        this.enabledInstructions = [...data].sort((value: any) => {
            return value.is_private ? 1 : -1;
        });
    }

    @Mutation
    private [SET_PUBLIC_INSTRUCTIONS](data: any[]) {
        const publicInstructions = data.map((instruction) => {
              instruction.typeof = 'dynamic';
              return instruction;
            })
            .sort((a, b) => {
                const titleA = a.title.toUpperCase();
                const titleB = b.title.toUpperCase();

                if (titleA < titleB) {
                    return -1;
                }
                if (titleA > titleB) {
                    return 1;
                }

                return 0;
            })
        ;

        publicInstructions.push({
            is_private: true,
            post_booking_instruction: null,
            pre_booking_instruction: null,
            status: 'enabled',
            title: 'Consigne libre',
            typeof: 'free',
            variables: [],
        });

        this.publicInstructions = publicInstructions;
    }

    @Mutation
    private [SET_ENABLED_INSTRUCTIONS_TOTAL](data: ISearchInstructionsResult) {
        this.enabledInstructionsTotal = data.total;
    }

    @Mutation
    private [SET_DISABLED_INSTRUCTIONS](data: IOrganizationInstruction[]) {
        this.disabledInstructions = [...data].sort((value: any) => {
            return value.is_private ? 1 : -1;
        });
    }

    @Mutation
    private [SET_DISABLED_INSTRUCTIONS_TOTAL](data: ISearchInstructionsResult) {
        this.disabledInstructionsTotal = data.total;
    }

    @Mutation
    private [SET_INSTRUCTION_PICTURES](picture: IInstructionPicture[]) {
        this.instuctionPictures = picture;
    }

    @Mutation
    private [SET_ENABLED_INSTRUCTIONS_PICTURE](params: IDeleteInstructionPictureParams) {
        this.enabledInstructions?.forEach((enabledInstructions: IOrganizationInstruction) => {
            enabledInstructions.organization_instruction_files?.forEach(
                (picture: IInstructionPicture, index: number) => {
                    if (picture.id === params.picture_id) {
                        enabledInstructions.organization_instruction_files?.splice(index, 1);
                    }
                },
            );
        });
    }

}
