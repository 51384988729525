
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';

    import {
        IClient,
        IBookingForm,
        IOrganization,
    } from '@/types';

    const clientNamespace = namespace('client');
    const organizationNamespace = namespace('organization');

    import ClientCardProfile from '@/components/ClientCardProfile.vue';
    import ClientCardAnimals from '@/components/ClientCardAnimals.vue';
    import ClientCardBookings from '@/components/ClientCardBookings.vue';

    @Component<ClientCard>({
        components: {
            ClientCardProfile,
            ClientCardAnimals,
            ClientCardBookings,
        },
    })
    export default class ClientCard extends Vue {
        public tab: number = 0;
        public clientIsCreated: boolean = false;

        @Prop({
            type: String,
            required: false,
            default: '',
        })
        public clientToEdit!: string;

        @Prop({
            type: Object,
            required: false,
        })
        public booking!: IBookingForm;

        @clientNamespace.Getter('currentClient')
        public currentClient!: IClient;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        get activeTabName(): string {
            switch (this.tab) {
                case 0:
                    return 'Coordonnées';
                case 1:
                    return 'Animaux';
                case 2:
                    return 'Historique';
                default:
                    return '';
            }
        }
    }
