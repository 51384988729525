import { render, staticRenderFns } from "./TheDrawerLeft.vue?vue&type=template&id=1fa3f5cc&"
import script from "./TheDrawerLeft.vue?vue&type=script&lang=ts&"
export * from "./TheDrawerLeft.vue?vue&type=script&lang=ts&"
import style0 from "./TheDrawerLeft.vue?vue&type=style&index=0&id=1fa3f5cc&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports