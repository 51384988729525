/* tslint:disable:no-console */

import { register } from 'register-service-worker';
import Cookies from 'js-cookie';

register(`./service-worker.js`, {
  ready() {
    const version = process.env.PACKAGE_VERSION;
    const oldVersion: string = Cookies.get('version') || '0.0.0';
    const [major, _minor, _patch]: string[] = version ? version.split('.') : [];
    const [oldMajor, _oldMinor, _oldPatch]: string[] = oldVersion ? oldVersion.split('.') : [];
    
    //si version anterieur ou version non trouvé
    if (oldMajor !== major && version) {
      Cookies.set('version', version, {expires: 365});
      window.localStorage.clear();
      window.location.reload();
    }
    console.log('Service worker is ready.');
  },
  registered() {
    console.log('Service worker has been registered.');
  },
  cached() {
    console.log('Content has been cached for offline use.');
  },
  updatefound() {
    console.log('New content is downloading.');
  },
  updated() {
    console.log('New content is available; please refresh.');
  },
  offline() {
    console.log('No internet connection found. App is running in offline mode.');
  },
  error(error) {
    console.error('Error during service worker registration:', error);
  },
});
