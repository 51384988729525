
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {DateTime} from 'luxon';

    import {
        IAccount,
        IBooking,
        IComment,
        IOrganization,
        ISms,
    } from '@/types';


    import {EventModule} from '@/store/modules';

    const accountNamespace = namespace('account');
    const eventNamespace = namespace('event');
    const organizationNamespace = namespace('organization');

    import AddBookingComment from '@/components/forms/AddBookingComment.vue';
    import BookingFile from '@/components/booking/BookingFile.vue';

    @Component<BookingCommentsCard>({
        components: {
            AddBookingComment,
            BookingFile,
        },
    })

    export default class BookingCommentsCard extends Vue {
        public commentDialog: boolean = false;
        public isLoadingFiles: boolean = false;
        public isLoadingComments: boolean = false;
        public confirmDeleteMsgDialog: boolean = false;
        public isDeletingMsg: boolean = false;

        public msgToDelete: IBooking|null = null;

        @Prop({
            type: Object,
            required: true,
        })

        public booking!: IBooking;

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount;

        @eventNamespace.Getter('commentsList')
        public comments!: IComment[];

        @eventNamespace.Getter('commentsAndFilesList')
        public commentsAndFiles!: IComment[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        public DATE_SHORT = DateTime.DATE_SHORT;
        public TIME_24_SIMPLE = DateTime.TIME_24_SIMPLE;

        public isRefent(comment: IComment) {
            if (comment.author.type === 'veterinarian' && comment.author.id !== this.loggedAccount.id) {
                return 'is-referent';
            }
        }

        private deleteMsg(comment: IBooking) {
            this.msgToDelete = comment;
            this.confirmDeleteMsgDialog = true;
        }

        private sendDeleteRequest(comment: IComment) {
            const eventModule = getModule(EventModule, this.$store);

            eventModule
                .deleteComment({
                    organization_id: this.loggedOrganization.id,
                    booking_id: this.booking.booking.id,
                    comment_id: comment.id,
                })
                .then(() => {
                    this.isLoadingFiles = false;
                    this.confirmDeleteMsgDialog = false;
                })
                .catch(() => this.isLoadingFiles = false)
                .finally(() => {
                    this.isLoadingFiles = false;
                    this.confirmDeleteMsgDialog = false;
                })
            ;
        }

        private scrollToBottom() {
            this.scrollToBottomOf(this.$refs.commentsContainer as HTMLElement);
        }

        private scrollToBottomOf(element: HTMLElement) {
            const cardText = this.$refs.cardText as HTMLElement;

            this.$nextTick(() => {
                cardText.scrollTo({
                    top: element.scrollHeight,
                    left: 0,
                    behavior: 'smooth',
                });
            });
        }

        private mounted() {
            this.isLoadingComments = true;

            const params = {
                organization_id: this.loggedOrganization.id,
                booking_id: this.booking.booking.id,
            };

            const eventModule = getModule(EventModule, this.$store);

            const bookingComments = eventModule.listBookingComments(params);
            const bookingFiles = eventModule.listBookingFiles(params);
            const smsStatus = eventModule.listSmsStatus(params);

            Promise
                .all([bookingComments, bookingFiles, smsStatus])
                .then(() => this.isLoadingComments = false )
            ;
        }
    }
