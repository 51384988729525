import Vue from 'vue';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';
import {Settings} from 'luxon';

import {localStorageService} from '@/storage/localstorage.service';

import {
    CreateOrganizationParams,
    IAccountResponse,
    IAvatar,
    ICancelOrganizationJoinRequestParams,
    ICreateSubscriptionParams,
    IDeleteOrganizationParaveterinarian,
    IDeleteOrganizationPictureParams,
    IDeleteOrganizationVeterinarian,
    IDeleteOrganizationVeterinarianPictureParams,
    IGetInvoiceParams,
    IGetOrganizationVeterinarianParams,
    IInvoice,
    IOrganization,
    IOrganizationJoinRequest,
    IOrganizationPicture,
    IOrganizationSocials,
    IOrganizationSubscription,
    IOrganizationType,
    IParaveterinarian,
    IPostOrganizationAvatarParams,
    IPostOrganizationPicturesParams,
    IPostOrganizationPicturesResult,
    IPostOrganizationSocialsParams,
    IPostOrganizationVeterinarianAvatarParams,
    IPostOrganizationVeterinarianPicturesParams,
    IPostVeterinarianPicturesResult,
    IUpdateOrganizationJoinRequestParams,
    IUpdateOrganizationParams,
    IUpdateOrganizationVeterinarianParams,
    IUpdateSubscriptionParams,
    IVeterinarian,
    IVeterinarianPicture,
    ICreateSetupIntentParams
} from '@/types';

import {
    CLEAR_STATE,
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';

import {
    ADD_ORGANIZATION_JOIN_REQUEST,
    ADD_PARAVETERINARIAN,
    ADD_VETERINARIAN,
    DELETE_ORGANIZATION_PARAVETERINARIAN,
    DELETE_ORGANIZATION_PICTURE,
    DELETE_ORGANIZATION_VETERINARIAN,
    DELETE_ORGANIZATION_VETERINARIAN_PICTURE,
    SET_ORGANIZATION,
    SET_ORGANIZATION_AVATAR,
    SET_ORGANIZATION_JOIN_REQUESTS_LIST,
    SET_ORGANIZATION_PARAVETERINARIANS_LIST,
    SET_ORGANIZATION_PICTURES,
    SET_ORGANIZATION_SOCIALS,
    SET_ORGANIZATION_SUBSCRIPTION,
    SET_ORGANIZATION_VETERINARIAN,
    SET_ORGANIZATION_VETERINARIANS_LIST,
    SET_ORGANIZATION_VETERINARIAN_AVATAR,
    SET_ORGANIZATION_VETERINARIAN_PICTURES,
    SET_TYPES,
    TYPES_REQUEST,
    UPDATE_ORGANIZATION,
    UPDATE_ORGANIZATION_INVOICE,
    UPDATE_ORGANIZATION_JOIN_REQUEST,
    UPDATE_ORGANIZATION_PARAVETERINARIAN_ROLE,
    UPDATE_ORGANIZATION_VETERINARIAN,
    UPDATE_ORGANIZATION_VETERINARIAN_ROLE,
    UPDATE_SYNCHRONIZATION_ALLOWED,
    SET_ORGANIZATION_INVOICES,
} from '@/types/store/mutations/organization.mutations';

@Module({
    namespaced: true,
    name: 'organization',
})
export class OrganizationModule extends VuexModule {
    public status: string|null = null;
    public organization: IOrganization|null = localStorageService.loadObject('organization');
    public types: IOrganizationType[] = [];
    public organizationVeterinarian: IVeterinarian|null = localStorageService.loadObject('organization_veterinarian');
    public organizationVeterinariansList: IVeterinarian[] = [];
    public organizationParaveterinariansList: IParaveterinarian[] = [];
    public organizationJoinRequestsList: IOrganizationJoinRequest[] = [];
    public subscription: IOrganizationSubscription|null = null;
    public invoices: IInvoice[] = [];

    private typesRequest: Promise<IOrganizationType[]>|null = null;

    get typesList(): IOrganizationType[] {
        return this.types;
    }

    get loggedOrganization(): IOrganization|null {
        return this.organization;
    }

    get veterinarian(): IVeterinarian|null {
        return this.organizationVeterinarian;
    }

    get veterinariansList(): IVeterinarian[] {
        return this.organizationVeterinariansList.map((val: IVeterinarian) => {
            return Object.assign(val, {full_name: `${val.first_name} ${val.last_name}`});
        });
    }

    get paraveterinariansList(): IParaveterinarian[] {
        return this.organizationParaveterinariansList.map((val: IParaveterinarian) => {
            return Object.assign(val, {full_name: `${val.first_name} ${val.last_name}`});
        });
    }

    get joinRequestsList(): IOrganizationJoinRequest[] {
        return this.organizationJoinRequestsList.map((val: IOrganizationJoinRequest) => {
            if (val.veterinarian) {
                return Object.assign(val, {full_name: `${val.veterinarian.first_name} ${val.veterinarian.last_name}`});
            }

            if (val.paraveterinarian) {
                return Object.assign(val, {full_name: `${val.paraveterinarian.first_name} ${val.paraveterinarian.last_name}`});
            }

            return val;
        });
    }

    get invoiceList(): IInvoice[] {
        return this.invoices;
    }

    @Action({rawError: true})
    public async create(organization: CreateOrganizationParams): Promise<IAccountResponse> {
        return new Promise<IAccountResponse>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .create(organization)
                .then((response: IAccountResponse) => {
                    this.context.commit(REQUEST_SUCCESS);

                    if (!this.loggedOrganization) {
                        this.context.commit(SET_ORGANIZATION, response.organization);
                    }

                    this.context.commit(`account/${REQUEST_SUCCESS}`, response, {root: true});
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async update(organization: IUpdateOrganizationParams): Promise<IOrganization> {
        return new Promise<IOrganization>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .updatePresentation(organization)
                .then((response: IOrganization) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(UPDATE_ORGANIZATION, response);

                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async fetchOrganization(id: string): Promise<IOrganization> {
        return new Promise<IOrganization>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .getOrganization(id)
                .then((response: IOrganization) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ORGANIZATION, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async postAvatar(params: IPostOrganizationAvatarParams): Promise<IAvatar> {
        return new Promise<IAvatar>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .postAvatar(params, params.organization_id)
                .then((response: IAvatar) => {
                    this.context.commit(REQUEST_SUCCESS);

                    this.context.commit(SET_ORGANIZATION_AVATAR, response);

                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async postSocials(params: IPostOrganizationSocialsParams): Promise<IOrganizationSocials> {
        return new Promise<IOrganizationSocials>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .postSocials(params)
                .then((response: IOrganizationSocials) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ORGANIZATION_SOCIALS, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async postPictures(params: IPostOrganizationPicturesParams): Promise<IPostOrganizationPicturesResult> {
        return new Promise<IPostOrganizationPicturesResult>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .postPictures(params)
                .then((response: IPostOrganizationPicturesResult) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ORGANIZATION_PICTURES, response.uploaded);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async deletePicture(params: IDeleteOrganizationPictureParams): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .deletePicture(params)
                .then((response: boolean) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(DELETE_ORGANIZATION_PICTURE, params.picture_id);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({rawError: true})
    public async fetchSubscription(organizationId: string) {
        return new Promise<IOrganizationSubscription>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .getSubscription(organizationId)
                .then((response: IOrganizationSubscription) => {
                    this.context.commit(SET_ORGANIZATION_SUBSCRIPTION, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                });
        });
    }

    

    @Action({rawError: true})
    public async createSetupIntent(
        {organizationId, subscription}: {
            organizationId: string,
            subscription: ICreateSetupIntentParams,
        },
    ): Promise<IOrganizationSubscription> {
        return new Promise<IOrganizationSubscription>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .createSetupIntent(subscription, organizationId)
                .then((response: any) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ORGANIZATION_SUBSCRIPTION, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async createSubscription(
        {organizationId, subscription}: {
            organizationId: string,
            subscription: ICreateSubscriptionParams,
        },
    ): Promise<IOrganizationSubscription> {
        return new Promise<IOrganizationSubscription>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .createSubscription(subscription, organizationId)
                .then((response: IOrganizationSubscription) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ORGANIZATION_SUBSCRIPTION, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async updateSubscription(
        {organizationId, subscription}: {
            organizationId: string,
            subscription: IUpdateSubscriptionParams,
        },
    ): Promise<IOrganizationSubscription> {
        return new Promise<IOrganizationSubscription>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .updateSubscription(subscription, organizationId)
                .then((response: IOrganizationSubscription) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ORGANIZATION_SUBSCRIPTION, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async fetchTypes(): Promise<IOrganizationType[]> {
        if (this.types.length > 0) {
            return new Promise<IOrganizationType[]>((resolve) => resolve(this.types));
        }

        if (!this.typesRequest) {
            this.context.commit(TYPES_REQUEST, new Promise<IOrganizationType[]>((resolve, reject) => {
                (Vue.prototype as Vue).$api.organization
                    .typesList()
                    .then((response: IOrganizationType[]) => {
                        this.context.commit(REQUEST_SUCCESS);
                        this.context.commit(SET_TYPES, response);
                        resolve(response);
                    })
                    .catch((error) => {
                        this.context.commit(REQUEST_ERROR);
                        reject(error);
                    })
                ;
            }));
        }

        return this.typesRequest as Promise<IOrganizationType[]>;
    }

    @Action({rawError: true})
    public selectOrganization(organization: IOrganization|null) {
        const shouldReload = !!this.organization;

        // Set organization
        new Promise<IOrganization|null>((resolve, reject) => {
            if (organization) {
                return this.context.dispatch('fetchOrganization', organization.id)
                    .then((response: IOrganization) => resolve(response))
                    .catch(() => reject())
                ;
            }

            return resolve(null);
        }).then((response: IOrganization|null) => {
            // Clear state
            this.context.commit(`agenda/${CLEAR_STATE}`, null, {root: true});
            this.context.commit(`animal/${CLEAR_STATE}`, null, {root: true});
            this.context.commit(`businessHour/${CLEAR_STATE}`, null, {root: true});
            this.context.commit(`client/${CLEAR_STATE}`, null, {root: true});
            this.context.commit(`event/${CLEAR_STATE}`, null, {root: true});
            this.context.commit(`layout/${CLEAR_STATE}`, null, {root: true});
            this.context.commit(`notification/${CLEAR_STATE}`, null, {root: true});
            this.context.commit(`reason/${CLEAR_STATE}`, null, {root: true});

            // Update organization status in account roles to keep things sync
            if (response) {
                this.context.dispatch(
                    'account/updateOrganizationStatusFromChannel',
                    {id: response.id, status: response.status},
                    {root: true},
                );
            }

            // Refetch data
            if (shouldReload) {
                window.location.reload();
            } else if (response) {
                Promise
                    .all([
                        this.context.dispatch('agenda/fetchAgendas', response.id, {root: true}),
                        this.context.dispatch('agenda/fetchAgendasGroups', response.id, {root: true}),
                        this.context.dispatch(
                            'reason/fetchOrganizationReasonsEnabled',
                            {
                                organization_id: response.id,
                                per_page: 200,
                            },
                            {root: true},
                        ),
                    ])
                    .then(([]) => {
                        this.context.dispatch('agenda/selectAllAgendas', null, {root: true});
                        this.context.dispatch('reason/selectAllReasons', null, {root: true});
                    })
                ;
            }
        });
    }

    @Action({rawError: true})
    public async fetchOrganizationVeterinariansList(organizationId: string): Promise<IVeterinarian[]> {
        return new Promise<IVeterinarian[]>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .veterinariansList(organizationId)
                .then((response: IVeterinarian[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ORGANIZATION_VETERINARIANS_LIST, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({rawError: true})
    public async fetchOrganizationParaveterinariansList(organizationId: string): Promise<IParaveterinarian[]> {
        return new Promise<IParaveterinarian[]>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .paraveterinariansList(organizationId)
                .then((response: IParaveterinarian[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ORGANIZATION_PARAVETERINARIANS_LIST, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({rawError: true})
    public async fetchOrganizationJoinRequestsList(organizationId: string): Promise<IOrganizationJoinRequest[]> {
        return new Promise<IOrganizationJoinRequest[]>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .joinRequestsList(organizationId)
                .then((response: IOrganizationJoinRequest[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ORGANIZATION_JOIN_REQUESTS_LIST, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({rawError: true})
    public async updateJoinRequest(params: IUpdateOrganizationJoinRequestParams): Promise<IOrganizationJoinRequest> {
        return new Promise<IOrganizationJoinRequest>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .updateJoinRequest(params)
                .then((response: IOrganizationJoinRequest) => {
                    this.context.commit(REQUEST_SUCCESS);
                    // Update handled by websockets
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({rawError: true})
    public async cancelJoinRequest(params: ICancelOrganizationJoinRequestParams): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .cancelJoinRequest(params)
                .then(() => {
                    this.context.commit(REQUEST_SUCCESS);
                    // Update handled by websockets
                    resolve(true);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({rawError: true})
    public async fetchOrganizationVeterinarian(params: IGetOrganizationVeterinarianParams): Promise<IVeterinarian> {
        return new Promise<IVeterinarian>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .getVeterinarian(params)
                .then((response: IVeterinarian) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ORGANIZATION_VETERINARIAN, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({rawError: true})
    public async postOrganizationVeterinarianAvatar(
        params: IPostOrganizationVeterinarianAvatarParams,
    ): Promise<IAvatar> {
        return new Promise<IAvatar>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .postOrganizationVeterinarianAvatar(params)
                .then((response: IAvatar) => {
                    this.context.commit(REQUEST_SUCCESS);

                    this.context.commit(SET_ORGANIZATION_VETERINARIAN_AVATAR, response);

                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({rawError: true})
    public async updateOrganizationVeterinarian(
        veterinarian: IUpdateOrganizationVeterinarianParams,
    ): Promise<IVeterinarian> {
        return new Promise<IVeterinarian>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .updateOrganizationVeterinarian(veterinarian)
                .then((response: IVeterinarian) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(UPDATE_ORGANIZATION_VETERINARIAN, response);

                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({rawError: true})
    public async deleteOrganizationVeterinarian(params: IDeleteOrganizationVeterinarian): Promise<IVeterinarian> {
        return new Promise<IVeterinarian>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .deleteVeterinarian(params)
                .then((response: IVeterinarian) => {
                    this.context.commit(REQUEST_SUCCESS);
                    // Update handled by websockets
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({rawError: true})
    public async deleteOrganizationParaveterinarian(
        params: IDeleteOrganizationParaveterinarian,
    ): Promise<IParaveterinarian> {
        return new Promise<IParaveterinarian>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .deleteParaveterinarian(params)
                .then((response: IParaveterinarian) => {
                    this.context.commit(REQUEST_SUCCESS);
                    // Update handled by websockets
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({rawError: true})
    public async postOrganizationVeterinarianPictures(
        params: IPostOrganizationVeterinarianPicturesParams,
    ): Promise<IPostVeterinarianPicturesResult> {
        return new Promise<IPostVeterinarianPicturesResult>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .postOrganizationVeterinarianPictures(params)
                .then((response: IPostVeterinarianPicturesResult) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ORGANIZATION_VETERINARIAN_PICTURES, response.uploaded);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({rawError: true})
    public async deleteOrganizationVeterinarianPicture(
        params: IDeleteOrganizationVeterinarianPictureParams,
    ): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .deleteOrganizationVeterinarianPicture(params)
                .then((response: boolean) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(DELETE_ORGANIZATION_VETERINARIAN_PICTURE, params.picture_id);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({rawError: true})
    public async fetchInvoices(organizationId: string): Promise<IInvoice[]> {
        return new Promise<IInvoice[]>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .listInvoices(organizationId)
                .then((response: IInvoice[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ORGANIZATION_INVOICES, response);

                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                });
        });
    }

    @Action({rawError: true})
    public async getInvoice(params: IGetInvoiceParams): Promise<IInvoice> {
        return new Promise<IInvoice>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .getInvoice(params)
                .then((response: IInvoice) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(UPDATE_ORGANIZATION_INVOICE, response);

                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                });
        });
    }

    @Action({rawError: true})
    public async resetOrganization(organizationId: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .reset(organizationId)
                .then((response) => {
                    this.context.commit(REQUEST_SUCCESS);
                    resolve();
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                });
        });
    }

    // Direct actions used by websockets

    @Action({rawError: true})
    public addOrganizationJoinRequestFromChannel(params: IOrganizationJoinRequest) {
        this.context.commit(ADD_ORGANIZATION_JOIN_REQUEST, params);
    }

    @Action({rawError: true})
    public updateOrganizationJoinRequestFromChannel(params: {id: string, status: string}) {
        this.context.commit(UPDATE_ORGANIZATION_JOIN_REQUEST, params);
    }

    @Action({rawError: true})
    public addParaveterinarianFromChannel(params: IParaveterinarian) {
        this.context.commit(ADD_PARAVETERINARIAN, params);
    }

    @Action({rawError: true})
    public deleteParaveterinarianFromChannel(params: string) {
        this.context.commit(DELETE_ORGANIZATION_PARAVETERINARIAN, params);
    }

    @Action({rawError: true})
    public updateParaveterinarianRoleFromChannel(params: {id: string, role: string}) {
        this.context.commit(UPDATE_ORGANIZATION_PARAVETERINARIAN_ROLE, params);
    }

    @Action({rawError: true})
    public updateSynchronizationAllowed(params: boolean) {
        this.context.commit(UPDATE_SYNCHRONIZATION_ALLOWED, params);
    }

    @Action({rawError: true})
    public addVeterinarianFromChannel(params: IVeterinarian) {
        this.context.commit(ADD_VETERINARIAN, params);
    }

    @Action({rawError: true})
    public deleteVeterinarianFromChannel(params: string) {
        this.context.commit(DELETE_ORGANIZATION_VETERINARIAN, params);
    }

    @Action({rawError: true})
    public updateVeterinarianRoleFromChannel(params: {id: string, role: string}) {
        this.context.commit(UPDATE_ORGANIZATION_VETERINARIAN_ROLE, params);
    }

    @Action({rawError: true})
    public updateOrganizationStatusFromChannel(params: {id: string, status: string}) {
        this.context.commit(UPDATE_ORGANIZATION, params);
    }

    // Mutations

    @Mutation
    private [UPDATE_ORGANIZATION](params: IOrganization) {
        if (this.organization && this.organization.id === params.id) {
            this.organization = Object.assign(this.organization, params);
            localStorageService.storeObject('organization', this.organization);
        }
    }

    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
        this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [TYPES_REQUEST](promise: Promise<IOrganizationType[]>) {
        this.status = 'loading';
        this.typesRequest = promise;
    }

    @Mutation
    private [SET_TYPES](data: IOrganizationType[]) {
        this.types = data;
        localStorageService.storeObject('types', this.types);
    }

    @Mutation
    private [SET_ORGANIZATION](data: IOrganization|null) {
        this.status = null;
        this.organization = data;
        this.organizationVeterinarian = null;
        this.organizationVeterinariansList = [];
        this.organizationParaveterinariansList = [];
        this.organizationJoinRequestsList = [];

        localStorageService.remove('organization_veterinarian');

        if (this.organization) {
            localStorageService.storeObject('organization', this.organization);
        } else {
            localStorageService.remove('organization');
        }
    }

    @Mutation
    private [SET_ORGANIZATION_AVATAR](data: IAvatar) {
        if (this.organization) {
            this.organization.avatar = data;
            localStorageService.storeObject('organization', this.organization);
        }
    }

    @Mutation
    private [SET_ORGANIZATION_PICTURES](pictures: IOrganizationPicture[]) {
        pictures.forEach((picture) => {
            if (this.organization) {
                this.organization.pictures.push(picture);
            }
        });
        localStorageService.storeObject('organization', this.organization);
    }

    @Mutation
    private [DELETE_ORGANIZATION_PICTURE](data: string) {
        if (this.organization) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.organization.pictures.length; ++i) {
                if (this.organization.pictures[i].id === data) {
                    this.organization.pictures.splice(i, 1);
                    break;
                }
            }
        }
        localStorageService.storeObject('organization', this.organization);
    }


    @Mutation
    private [SET_ORGANIZATION_SOCIALS](data: IOrganizationSocials) {
        if (this.organization) {
            this.organization.social_links = data;
            localStorageService.storeObject('organization', this.organization);
        }
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.status = null;
        this.organization = null;
        this.organizationVeterinarian = null;
        this.organizationVeterinariansList = [];
        this.organizationParaveterinariansList = [];
        this.organizationJoinRequestsList = [];

        localStorageService.remove('organization');
        localStorageService.remove('organization_veterinarian');
    }

    @Mutation
    private [SET_ORGANIZATION_VETERINARIAN](data: IVeterinarian) {
        this.organizationVeterinarian = data;
        localStorageService.storeObject('organization_veterinarian', this.organizationVeterinarian);
    }

    @Mutation
    private [SET_ORGANIZATION_VETERINARIAN_AVATAR](data: IAvatar) {
        if (this.organizationVeterinarian) {
            this.organizationVeterinarian.avatar = data;
            localStorageService.storeObject('organization_veterinarian', this.organizationVeterinarian);
        }
    }

    @Mutation
    private [UPDATE_ORGANIZATION_VETERINARIAN](
        veterinarian: IVeterinarian,
    ) {
        this.organizationVeterinarian = Object.assign(this.organizationVeterinarian as IVeterinarian, veterinarian);

        for (let i = 0; i < this.organizationVeterinariansList.length; ++i) {
            if (this.organizationVeterinariansList[i].id === veterinarian.id) {
                this.organizationVeterinariansList[i] = veterinarian;
            }
        }
    }

    @Mutation
    private [UPDATE_ORGANIZATION_VETERINARIAN_ROLE](
        data: {id: string, role: string},
    ) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.organizationVeterinariansList.length; ++i) {
            if (this.organizationVeterinariansList[i].id === data.id) {
                (this.organizationVeterinariansList[i] as any).role = data.role;
                break;
            }
        }
    }

    @Mutation
    private [DELETE_ORGANIZATION_VETERINARIAN](veterinarianId: string) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.organizationVeterinariansList.length; ++i) {
            if (this.organizationVeterinariansList[i].id === veterinarianId) {
                this.organizationVeterinariansList.splice(i, 1);
                break;
            }
        }
    }

    @Mutation
    private [DELETE_ORGANIZATION_PARAVETERINARIAN](paraveterinarianId: string) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.organizationParaveterinariansList.length; ++i) {
            if (this.organizationParaveterinariansList[i].id === paraveterinarianId) {
                this.organizationParaveterinariansList.splice(i, 1);
                break;
            }
        }
    }

    @Mutation
    private [UPDATE_ORGANIZATION_PARAVETERINARIAN_ROLE](
        data: {id: string, role: string},
    ) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.organizationParaveterinariansList.length; ++i) {
            if (this.organizationParaveterinariansList[i].id === data.id) {
                (this.organizationParaveterinariansList[i] as any).role = data.role;
                break;
            }
        }
    }

    @Mutation
    private [SET_ORGANIZATION_VETERINARIANS_LIST](data: IVeterinarian[]) {
        this.organizationVeterinariansList = data;
    }

    @Mutation
    private [ADD_VETERINARIAN](data: IVeterinarian) {
        this.organizationVeterinariansList.push(data);
    }

    @Mutation
    private [SET_ORGANIZATION_PARAVETERINARIANS_LIST](data: IParaveterinarian[]) {
        this.organizationParaveterinariansList = data;
    }

    @Mutation
    private [ADD_PARAVETERINARIAN](data: IParaveterinarian) {
        this.organizationParaveterinariansList.push(data);
    }

    @Mutation
    private [SET_ORGANIZATION_JOIN_REQUESTS_LIST](data: IOrganizationJoinRequest[]) {
        this.organizationJoinRequestsList = data;
    }

    @Mutation
    private [ADD_ORGANIZATION_JOIN_REQUEST](data: IOrganizationJoinRequest) {
        this.organizationJoinRequestsList.push(data);
    }

    @Mutation
    private [UPDATE_ORGANIZATION_JOIN_REQUEST](data: {id: string, status: string}) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.organizationJoinRequestsList.length; ++i) {
            if (this.organizationJoinRequestsList[i].id === data.id) {
                this.organizationJoinRequestsList[i].status = data.status;

                if (this.organizationJoinRequestsList[i].veterinarian) {
                    const veterinarian = this.organizationJoinRequestsList[i].veterinarian as IVeterinarian;

                    // tslint:disable-next-line:prefer-for-of
                    for (let j = 0; j < this.organizationVeterinariansList.length; ++j) {
                        if (this.organizationVeterinariansList[j].id === veterinarian.id) {
                            this.organizationVeterinariansList[j].has_pending_join_request = false;
                            this.organizationVeterinariansList[j].email = null;
                            break;
                        }
                    }
                } else if (this.organizationJoinRequestsList[i].paraveterinarian) {
                    const paraveterinarian = this.organizationJoinRequestsList[i].paraveterinarian as IParaveterinarian;

                    // tslint:disable-next-line:prefer-for-of
                    for (let j = 0; j < this.organizationParaveterinariansList.length; ++j) {
                        if (this.organizationParaveterinariansList[j].id === paraveterinarian.id) {
                            this.organizationParaveterinariansList[j].has_pending_join_request = false;
                            this.organizationParaveterinariansList[j].email = null;
                            break;
                        }
                    }
                }

                break;
            }
        }
    }

    @Mutation
    private [SET_ORGANIZATION_VETERINARIAN_PICTURES](pictures: IVeterinarianPicture[]) {
        pictures.forEach((picture) => {
            if (this.organizationVeterinarian) {
                this.organizationVeterinarian.pictures.push(picture);
            }
        });
        localStorageService.storeObject('organization_veterinarian', this.organizationVeterinarian);
    }

    @Mutation
    private [DELETE_ORGANIZATION_VETERINARIAN_PICTURE](data: string) {
        if (this.organizationVeterinarian) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.organizationVeterinarian.pictures.length; ++i) {
                if (this.organizationVeterinarian.pictures[i].id === data) {
                    this.organizationVeterinarian.pictures.splice(i, 1);
                    break;
                }
            }
        }
        localStorageService.storeObject('organization_veterinarian', this.organizationVeterinarian);
    }

    @Mutation
    private [SET_ORGANIZATION_SUBSCRIPTION](subscription: IOrganizationSubscription) {
        this.subscription = subscription;
    }

    @Mutation
    private [SET_ORGANIZATION_INVOICES](invoices: IInvoice[]) {
        this.invoices = invoices;
    }

    @Mutation
    private [UPDATE_ORGANIZATION_INVOICE](invoice: IInvoice) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.invoices.length; ++i) {
            if (this.invoices[i].id === invoice.id) {
                this.invoices[i] = invoice;
            }
        }
    }

    @Mutation
    private [UPDATE_SYNCHRONIZATION_ALLOWED](synchronizationAllowed: boolean) {
        if (this.organization) {
            this.organization.synchronization_allowed = synchronizationAllowed;
        }
    }
}
