import {
    IBooking,
    IBookingFile,
    ICreateBookingCommentParams,
    ICreateBookingFilesParams,
    ICreateBookingFilesResult,
    ICreateBookingParams,
    ICreateUnavailabilityParams,
    IDeleteEventParams,
    IDeleteCommentParams,
    IDeleteBookingFilesParams,
    IEvent,
    IFirstEvent,
    IGetBookingFileParams,
    IListBookingCommentsParams,
    IListBookingFilesParams,
    IListEventsParams,
    IMoveEventParams,
    IUnavailability,
    IUpdateBookingParams,
    IIUpdateEventRecurrenceParams,
    IUpdateUnavailabilityParams,
    IComment,
    ISms,
    ICreateHospitalizationParams,
} from '@/types';

import AbstractService from './abstract.service';

export class EventService extends AbstractService {
    public list(params: IListEventsParams) {
        return new Promise<IEvent[]>((resolve, reject) => {
            const organizationId = params.organization_id;

            delete (params as IListEventsParams).organization_id;

            this.api({
                url: `/organizations/${organizationId}/events`,
                method: 'GET',
                params,
            })
                .then((response) => {
                    resolve(response.data.events);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public first(params: IFirstEvent) {
        return new Promise<IEvent>((resolve, reject) => {
            const organizationId = params.organization_id;
            const bookingId = params.booking_id;

            this.api({
                url: `/organizations/${organizationId}/bookings/${bookingId}`,
                method: 'GET',
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public moveEvent(params: IMoveEventParams) {
        return new Promise<IEvent>((resolve, reject) => {
            const organizationId = params.organization_id;
            const eventId = params.event_id;

            delete (params as IMoveEventParams).organization_id;
            delete (params as IMoveEventParams).event_id;

            this.api({
                url: `/organizations/${organizationId}/events/${eventId}`,
                method: 'PUT',
                data: params,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public updateRecurrence(params: IIUpdateEventRecurrenceParams) {
        return new Promise<IEvent>((resolve, reject) => {
            const organizationId = params.organization_id;
            const eventId = params.event_id;

            delete (params as IIUpdateEventRecurrenceParams).organization_id;
            delete (params as IIUpdateEventRecurrenceParams).event_id;

            this.api({
                url: `/organizations/${organizationId}/events/${eventId}`,
                method: 'PUT',
                data: params,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public deleteEvent(params: IDeleteEventParams) {
        return new Promise<boolean>((resolve, reject) => {
            const organizationId = params.organization_id;
            const eventId = params.event_id;

            this.api({
                url: `/organizations/${organizationId}/events/${eventId}`,
                method: 'DELETE',
            })
            .then(() => {
                resolve(true);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public deleteComment(params: IDeleteCommentParams) {
        return new Promise<boolean>((resolve, reject) => {
            const organizationId = params.organization_id;
            const bookingId = params.booking_id;
            const commentId = params.comment_id;

            this.api({
                url: `/organizations/${organizationId}/bookings/${bookingId}/comments/${commentId}`,
                method: 'DELETE',
            })
            .then(() => {
                resolve(true);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public deleteNote(params: IDeleteCommentParams) {
        return new Promise<boolean>((resolve, reject) => {
            const organizationId = params.organization_id;
            const bookingId = params.booking_id;
            const noteId = params.comment_id;

            this.api({
                url: `/organizations/${organizationId}/bookings/${bookingId}/notes/${noteId}`,
                method: 'DELETE',
            })
            .then(() => {
                resolve(true);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public createBooking(params: ICreateBookingParams) {
        return new Promise<IBooking>((resolve, reject) => {
            const organizationId = params.organization_id;

            delete (params as ICreateBookingParams).organization_id;

            this.api({
                url: `/organizations/${organizationId}/bookings`,
                method: 'POST',
                data: params,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public createHospitalization(params: ICreateHospitalizationParams) {
        return new Promise<IBooking>((resolve, reject) => {
            const organizationId = params.organization_id;

            delete (params as ICreateHospitalizationParams).organization_id;

            this.api({
                url: `/organizations/${organizationId}/bookings`,
                method: 'POST',
                data: params,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public updateBooking(params: IUpdateBookingParams) {
        return new Promise<IBooking>((resolve, reject) => {
            const organizationId = params.organization_id;
            const bookingId = params.booking_id;

            delete (params as IUpdateBookingParams).organization_id;
            delete (params as IUpdateBookingParams).booking_id;

            this.api({
                url: `/organizations/${organizationId}/bookings/${bookingId}`,
                method: 'PUT',
                data: params,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public createUnavailability(params: ICreateUnavailabilityParams) {
        return new Promise<IUnavailability>((resolve, reject) => {
            const organizationId = params.organization_id;

            delete (params as ICreateUnavailabilityParams).organization_id;

            this.api({
                url: `/organizations/${organizationId}/unavailabilities`,
                method: 'POST',
                data: params,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public updateUnavailability(params: IUpdateUnavailabilityParams) {
        return new Promise<IUnavailability>((resolve, reject) => {
            const organizationId = params.organization_id;
            const unavailabilityId = params.unavailability_id;

            delete (params as IUpdateUnavailabilityParams).organization_id;
            delete (params as IUpdateUnavailabilityParams).unavailability_id;

            this.api({
                url: `/organizations/${organizationId}/unavailabilities/${unavailabilityId}`,
                method: 'PUT',
                data: params,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public listBookingComments(params: IListBookingCommentsParams) {
        return new Promise<IComment[]>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/bookings/${params.booking_id}/comments`,
                method: 'GET',
            })
            .then((response) => {
                resolve(response.data.bookings_comments);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public listSmsStatus(params: IListBookingCommentsParams) {
        return new Promise<ISms[]>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/bookings/${params.booking_id}/sms`,
                method: 'GET',
            })
            .then((response) => {
                resolve(response.data.sms);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public createBookingComment(params: ICreateBookingCommentParams) {
        return new Promise<IComment>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/bookings/${params.booking_id}/comments`,
                method: 'POST',
                data: {
                    comment: params.comment,
                    notify_client: params.notify_client,
                },
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public listBookingFiles(params: IListBookingFilesParams) {
        return new Promise<IBookingFile[]>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/bookings/${params.booking_id}/files`,
                method: 'GET',
            })
            .then((response) => {
                resolve(response.data.bookings_files);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public createBookingFiles(params: ICreateBookingFilesParams) {
        return new Promise<ICreateBookingFilesResult>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/bookings/${params.booking_id}/files`,
                method: 'POST',
                data: params.files,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public getBookingFile(params: IGetBookingFileParams) {
        return new Promise<string>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/bookings/${params.booking_id}/files/${params.file_id}`,
                method: 'GET',
            })
            .then((response) => {
                resolve(response.data.url);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public deleteBookingFile(params: IDeleteBookingFilesParams) {
        return new Promise<boolean>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/bookings/${params.booking_id}/files/${params.file_id}`,
                method: 'DELETE',
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public listBookingNotes(params: IListBookingCommentsParams) {
        return new Promise<IComment[]>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/bookings/${params.booking_id}/notes`,
                method: 'GET',
            })
            .then((response) => {
                resolve(response.data.bookings_comments);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public createBookingNote(params: ICreateBookingCommentParams) {
        return new Promise<IComment>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/bookings/${params.booking_id}/notes`,
                method: 'POST',
                data: {
                    comment: params.comment,
                },
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }
}
