
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {Emit, Model, Prop, Watch} from 'vue-property-decorator';
    import {debounce} from 'debounce';

    import {
        IOrganization,
        ISearchClientsResult,
        IClient,
    } from '@/types';

    import ClientFormCard from '@/components/ClientFormCard.vue';

    const organizationNamespace = namespace('organization');

    @Component<ClientAutocomplete>({
        components: {
            ClientFormCard,
        },
    })
    export default class ClientAutocomplete extends Vue {
        public isLoading: boolean = false;
        public items: IClient[] = [];

        public createClientDialog: boolean = false;
        public clientToCreate: IClient|null = null;

        public searchText: string|null = null;
        public innerModel: IClient|null = null;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @Model('input', {type: Object}) public readonly value!: IClient|null;

        @Prop({
            type: Boolean,
            required: false,
            default: false,
        })
        public allowNew!: boolean;

        private debouncedAutocompleteQuery = debounce(this.sendAutocompleteQuery, 300);

        public get search() {
            return this.searchText ?
                this.searchText :
                this.innerModel ?
                    this.innerModel.full_name :
                    ''
            ;
        }

        public set search(val: string) {
            if (val) {
                this.searchText = val;
            } else {
                this.searchText = (this.innerModel ? this.innerModel.full_name : null);
            }
        }

        public setClient(value: IClient|null) {
            this.createClientDialog = false;

            if (value) {
                const item = JSON.parse(JSON.stringify(value));
                this.items = [item as IClient];
            }

            this.innerModel = value;
            this.$emit('input', this.innerModel);
        }

        public openCreateClientForm(prefillName: boolean) {
            if (prefillName) {
                (this.clientToCreate as {}) = {last_name: this.searchText};
            } else {
                (this.clientToCreate as {}) = {};
            }
            this.createClientDialog = true;
        }

        @Watch('value', {immediate: true})
        private valueChanged(val: IClient) {
            if (val !== this.innerModel) {
                if (val) {
                    this.$nextTick(() => {
                        const item = JSON.parse(JSON.stringify(val));
                        this.items = [item as IClient];
                        this.innerModel = item;
                    });
                } else {
                    this.items = [];
                    this.innerModel = null;
                }
            }
        }

        @Watch('searchText')
        private onSearchTextChange(val: IClient | IClient[] | {} | string) {
            if (!val) {
                this.items = [];
                return;
            }

            // Inner model was set manually, don't need to fetch suggestions again
            if (
                this.innerModel &&
                val === this.innerModel.full_name &&
                this.items.length === 1
            ) {
                return;
            }

            this.debouncedAutocompleteQuery(val as string);
        }

        @Emit('input')
        private onClientChange() {
            return this.innerModel;
        }

        private sendAutocompleteQuery(val: string) {
            this.isLoading = true;

            return this.$api.client
                .search({
                    query: val,
                    organization_id: this.loggedOrganization.id,
                })
                .then((suggestions: ISearchClientsResult) => {
                    this.items = suggestions.organizations_clients;
                })
                .finally(() => this.isLoading = false)
            ;
        }
    }
