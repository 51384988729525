import {
    IBulkUpdateReasonsParams,
    ICreateReasonParams,
    IReason,
    ISearchParams,
    IUpdateReasonParams,
} from '@/types';

import AbstractService from './abstract.service';

export class ReasonService extends AbstractService {
    public create(params: ICreateReasonParams) {
        return new Promise<IReason>((resolve, reject) => {
            const organizationId = params.organization_id;

            delete (params as ICreateReasonParams).organization_id;

            this.api({
                url: `/organizations/${organizationId}/private-reasons`,
                method: 'POST',
                data: params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public updatePrivateReason(params: IUpdateReasonParams) {
        return new Promise<IReason>((resolve, reject) => {
            const organizationId = params.organization_id;
            const reasonId = params.reason_id;

            delete (params as IUpdateReasonParams).organization_id;
            delete (params as IUpdateReasonParams).reason_id;

            if (params.status === 'enabled') {
                params.send_sms = true;
                params.send_email = true;
            }

            this.api({
                url: `/organizations/${organizationId}/private-reasons/${reasonId}`,
                method: 'PUT',
                data: params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public updatePublicReason(params: IUpdateReasonParams) {
        return new Promise<IReason>((resolve, reject) => {
            const organizationId = params.organization_id;
            const reasonId = params.reason_id;

            delete (params as IUpdateReasonParams).organization_id;
            delete (params as IUpdateReasonParams).reason_id;

            this.api({
                url: `/organizations/${organizationId}/public-reasons/${reasonId}`,
                method: 'PUT',
                data: params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public deleteReason(organizationId: string, reasonId: string) {
        return new Promise<boolean>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/private-reasons/${reasonId}`,
                method: 'DELETE',
            })
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public bulkUpdateReasons(params: IBulkUpdateReasonsParams) {
        const [reasonsPrivate, reasonsPublic] =
            params.reasons.reduce(([privateReasons, publicReasons]: any, elm: IReason) => {
                if (elm.is_private) {
                    privateReasons.push(elm);
                } else {
                    publicReasons.push(elm);
                }

                return [privateReasons, publicReasons];
        }, [[], []]);

        const bulkUpdatePrivateReasonsRequest = this.api({
            url: `/organizations/${params.organization_id}/private-reasons/bulk`,
            method: 'PUT',
            data: reasonsPrivate,
        });

        const bulkUpdatePublicReasonsRequest = this.api({
            url: `/organizations/${params.organization_id}/public-reasons/bulk`,
            method: 'PUT',
            data: reasonsPublic,
        });

        return Promise.all([bulkUpdatePrivateReasonsRequest, bulkUpdatePublicReasonsRequest]);
    }

    public organizationReasonsListStatus(params: ISearchParams, status: string) {
        return new Promise<IReason[]>((resolve, reject) => {
            let search = null;

            if (params.search && params.search.length >= 2) {
                search = params.search;
            }

            this.api({
                url: `/organizations/${params.organization_id}/reasons`,
                method: 'GET',
                params: {
                    page: params.page,
                    per_page: params.per_page,
                    status,
                    search,
                },
            })
            .then((response) => {
                response.data.organizations_reasons.total = parseInt(response.headers.total, 10);
                resolve(response.data.organizations_reasons);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public publicReasonsList() {
        return new Promise<IReason[]>((resolve, reject) => {
            this.api({
                url: `/reasons`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.reasons);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }
}
