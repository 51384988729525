import Vue from 'vue';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';

import {
    IBusinessHour,
    ICreateBusinessHourParams,
    IDeleteBusinessHourParams,
    IListBusinessHoursParams,
    IUpdateBusinessHourParams,
} from '@/types';

import {
    CLEAR_STATE,
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';

import {
    ADD_BUSINESS_HOUR,
    DELETE_BUSINESS_HOUR,
    SET_BUSINESS_HOURS,
    UPDATE_BUSINESS_HOUR,
} from '@/types/store/mutations/business-hour.mutations';

@Module({
    namespaced: true,
    name: 'businessHour',
})
export class BusinessHourModule extends VuexModule {
    public status: string|null = null;
    public businessHours: IBusinessHour[] = [];

    get businessHoursList(): IBusinessHour[] {
        return this.businessHours;
    }

    get requestStatus(): string|null {
        return this.status;
    }

    @Action({rawError: true})
    public async fetchBusinessHours(params: IListBusinessHoursParams): Promise<IBusinessHour[]> {
        return new Promise<IBusinessHour[]>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.businessHour
                .list(params)
                .then((response: IBusinessHour[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_BUSINESS_HOURS, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async createBusinessHour(params: ICreateBusinessHourParams): Promise<IBusinessHour> {
        return new Promise<IBusinessHour>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.businessHour
                .create(params)
                .then((response: IBusinessHour) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(ADD_BUSINESS_HOUR, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async updateBusinessHour(params: IUpdateBusinessHourParams): Promise<IBusinessHour> {
        return new Promise<IBusinessHour>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.businessHour
                .update(params)
                .then((response: IBusinessHour) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(UPDATE_BUSINESS_HOUR, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async deleteBusinessHour(params: IDeleteBusinessHourParams): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.businessHour
                .deleteBusinessHour(params)
                .then((response: boolean) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(DELETE_BUSINESS_HOUR, params.business_hour_id);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public clearState() {
        this.context.commit(CLEAR_STATE);
    }

    // Direct actions used by websockets

    @Action({rawError: true})
    public addBusinessHourFromChannel(params: IBusinessHour) {
        this.context.commit(ADD_BUSINESS_HOUR, params);
    }

    @Action({rawError: true})
    public updateBusinessHourFromChannel(params: IBusinessHour) {
        this.context.commit(UPDATE_BUSINESS_HOUR, params);
    }

    @Action({rawError: true})
    public deleteBusinessHourFromChannel(params: string) {
        this.context.commit(DELETE_BUSINESS_HOUR, params);
    }

    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
        this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [SET_BUSINESS_HOURS](data: IBusinessHour[]) {
        this.businessHours = data;
    }

    @Mutation
    private [ADD_BUSINESS_HOUR](data: IBusinessHour) {
        if (!this.businessHours.find((el: IBusinessHour) => el.id === data.id)) {
            this.businessHours.push(data);
        }
    }

    @Mutation
    private [UPDATE_BUSINESS_HOUR](data: IBusinessHour) {
        let found = false;

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.businessHours.length; ++i) {
            if (this.businessHours[i].id === data.id) {
                found = true;
                Vue.set(this.businessHours, i, data);
                break;
            }
        }

        if (!found) {
            this.businessHours.push(data);
        }
    }

    @Mutation
    private [DELETE_BUSINESS_HOUR](data: string) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.businessHours.length; ++i) {
            if (this.businessHours[i].id === data) {
                this.businessHours.splice(i, 1);
                break;
            }
        }
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.status = null;
        this.businessHours = [];
    }
}
