import Vue from 'vue';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';
import {DateTime} from 'luxon';

import {
    CLEAR_STATE,
} from '@/types/store/mutations/store.mutations';

import {
    ADD_NOTIFICATION,
    DELETE_EXPIRED_NOTIFICATIONS,
    DELETE_NOTIFICATION,
    DISPLAY_DESKTOP_NOTIFICATION,
} from '@/types/store/mutations/notification.mutations';

import {
    INotification,
    IBookingNotification,
    INewBookingCommentNotification,
} from '@/types';

import {localStorageService} from '@/storage/localstorage.service';

@Module({
    namespaced: true,
    name: 'notification',
})
export class NotificationModule extends VuexModule {
    public notifications: IBookingNotification[] = localStorageService.loadObject('notifications', []);

    get notificationsList(): IBookingNotification[] {
        return this.notifications;
    }

    get unseenNotifications(): number {
        return this.notifications.length;
    }

    get notificationsFilteredByAgenda() {
        return this.notifications.reduce((acc: any, cur: any) => {
            if (!acc[cur.event.agenda.id]) {
                acc[cur.event.agenda.id] = {
                    agenda: {id: cur.event.agenda.id, name: cur.event.agenda.name},
                    notifications_count: 0,
                    bookings: [],
                };
            }

            acc[cur.event.agenda.id].notifications_count++;

            if (!acc[cur.event.agenda.id].bookings[cur.event.id]) {
                acc[cur.event.agenda.id].bookings[cur.event.id] = {
                    event: cur.event,
                    is_new: false,
                    is_moved: false,
                    is_cancelled: false,
                    new_messages: 0,
                    new_files: 0,
                    notifications: [],
                };
            }

            acc[cur.event.agenda.id].bookings[cur.event.id].notifications.push(cur);

            switch (cur.type) {
                case 'new_booking':
                    acc[cur.event.agenda.id].bookings[cur.event.id].is_new = true;
                    acc[cur.event.agenda.id].bookings[cur.event.id].creation_date = cur.inserted_at;
                    break;

                case 'new_booking_comment':
                    acc[cur.event.agenda.id].bookings[cur.event.id].new_messages++;
                    break;

                case 'new_booking_file':
                    acc[cur.event.agenda.id].bookings[cur.event.id].new_files++;
                    break;

                case 'booking_cancelled':
                    acc[cur.event.agenda.id].bookings[cur.event.id].is_cancelled = true;
                    acc[cur.event.agenda.id].bookings[cur.event.id].cancellation_date = cur.inserted_at;
                    break;

                case 'booking_moved':
                    acc[cur.event.agenda.id].bookings[cur.event.id].is_moved = true;
                    break;
            }

            return acc;
        }, {});
    }

    @Action({rawError: true})
    public addNotification({notification, shouldNotify}: {notification: INotification, shouldNotify: boolean}) {
        this.context.commit(ADD_NOTIFICATION, notification);

        if (shouldNotify) {
            this.context.commit(DISPLAY_DESKTOP_NOTIFICATION, notification);
        }
    }

    @Action({rawError: true})
    public notificationSeen(params: INotification) {
        (Vue.prototype as Vue).$socketHandler.getAgendaNotificationChannel(params.agenda_id)?.markRead(params.id);
    }

    @Action({rawError: true})
    public deleteNotification(id: string) {
        this.context.commit(DELETE_NOTIFICATION, id);
    }

    @Action({rawError: true})
    public deleteExpiredNotifications() {
        this.context.commit(DELETE_EXPIRED_NOTIFICATIONS);
    }

    @Action({rawError: true})
    public allNotificationsSeen() {
        this.notifications
            .forEach((notification) => {
                const channel = (Vue.prototype as Vue).$socketHandler
                    .getAgendaNotificationChannel(notification.event.agenda.id as string);

                if (channel) {
                    channel.markRead(notification.id);
                }
            })
        ;
    }

    @Action({rawError: true})
    public agendaNotificationsSeen(agendaId: string) {
        const channel = (Vue.prototype as Vue).$socketHandler.getAgendaNotificationChannel(agendaId);

        if (!channel) {
            return;
        }

        this.notifications
            .forEach((notification) => {
                if (notification.event.agenda.id === agendaId) {
                    channel.markRead(notification.id);
                }
            })
        ;
    }

    @Mutation
    private [ADD_NOTIFICATION](data: IBookingNotification) {
        const exists = this.notifications.find((value: IBookingNotification) => value.id === data.id);

        if (!exists) {
            this.notifications.push(data);
        }
    }

    @Mutation
    private [DELETE_EXPIRED_NOTIFICATIONS]() {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.notifications.length; ++i) {
            const expiration = DateTime.fromSeconds(this.notifications[i].expires_at);

            if (expiration < DateTime.now()) {
                this.notifications.splice(i, 1);
            }
        }
    }

    @Mutation
    private [DELETE_NOTIFICATION](id: string) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.notifications.length; ++i) {
            if (this.notifications[i].id === id) {
                this.notifications.splice(i, 1);
                break;
            }
        }
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.notifications = [];
        localStorageService.remove('notifications');
    }

    @Mutation
    private[DISPLAY_DESKTOP_NOTIFICATION](data: INotification) {
        if (!('Notification' in window)) {
            return;
        }

        let title!: string;
        let body!: string;

        switch (data.type) {
            case 'new_booking':
                const event = (data as IBookingNotification).event;
                const date = DateTime
                    .fromISO(event.start)
                    .toLocaleString({day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit'})
                ;

                title = 'Nouveau RDV';
                body = `${event.agenda.name} - ${date}`;
                break;

            case 'new_booking_comment':
                const bookingComment = (data as INewBookingCommentNotification).booking_comment;
                const client = (data as INewBookingCommentNotification).event.booking.client;
                const firstName = client.first_name;
                const lastName = client.last_name;
                const comment = bookingComment.comment;

                title = 'Nouveau message';
                body = `${firstName} ${lastName} dit : ${comment}`;
                break;

            case 'new_booking_file':
                title = 'Nouveau document';
                body = `Le client vous a envoyé un nouveau document`;
                break;

            case 'booking_cancelled':
                title = 'Annulation RDV';
                body = `Le client vient d'annuler son RDV`;
                break;

            case 'booking_moved':
                title = 'Modification RDV';
                body = `Le client vient de déplacer son RDV`;
                break;
        }

        if (title && body) {
            if (Notification.permission === 'granted') {
                // tslint:disable-next-line:no-unused-expression
                new Notification(title, {body});
            } else if (Notification.permission !== 'denied') {
                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        // tslint:disable-next-line:no-unused-expression
                        new Notification(title, {body});
                    }
                });
            }
        }
    }
}
