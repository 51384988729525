
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {LayoutModule, AccountModule} from '@/store/modules';

    const layoutNamespace = namespace('layout');
    const accountNamespace = namespace('account');
    const organizationNamespace = namespace('organization');

     import {
        IAccountRole,
        IOrganization,
    } from '@/types';

    @Component<TheDrawer>({})
    export default class TheDrawer extends Vue {
        @layoutNamespace.Getter('isDrawerOpened')
        public isDrawerOpened!: boolean;

        @accountNamespace.Getter('isLoggedIn')
        public isLoggedIn!: boolean;

        @accountNamespace.Getter('loggedAccountRoles')
        public loggedAccountRoles!: IAccountRole[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        get opened() {
            return this.isDrawerOpened;
        }

        set opened(value: boolean) {
            getModule(LayoutModule, this.$store).setDrawerOpened(value);
        }

        get currentRoute() {
            return this.$route.name;
        }

        public logout() {
            getModule(AccountModule, this.$store)
                .logout()
                .then(() => {
                    this.$router.push({name: 'home'});
                })
            ;
        }
    }
