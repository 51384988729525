import Vue from 'vue';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';

import {
    ICreateReasonParams,
    IReason,
    IUpdateReasonParams,
    ISearchParams,
    ISearchResult, IBulkUpdateReasonsParams,
} from '@/types';

import {localStorageService} from '@/storage/localstorage.service';

import {
    CLEAR_STATE,
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';

import {
    ADD_REASON,
    DELETE_REASON,
    PUBLIC_REASONS_REQUEST,
    SELECT_ALL_REASONS,
    SELECT_REASON,
    SET_ORGANIZATION_REASONS_DISABLED,
    SET_ORGANIZATION_REASONS_ENABLED,
    SET_PUBLIC_REASONS,
    SET_REASONS_DISABLED_TOTAL,
    SET_REASONS_ENABLED_TOTAL,
    DECREMENT_REASONS_ENABLED_TOTAL,
    INCREMENT_REASONS_ENABLED_TOTAL,
    DECREMENT_REASONS_DISABLED_TOTAL,
    INCREMENT_REASONS_DISABLED_TOTAL,
    TOGGLE_REASON,
    UPDATE_REASON,
} from '@/types/store/mutations/reason.mutations';

@Module({
    namespaced: true,
    name: 'reason',
})

export class ReasonModule extends VuexModule {
    public status: string|null = null;
    public totalEnabled: number = 0;
    public totalDisabled: number = 0;
    public selectedReasons: IReason[] = [];
    public organizationReasonsEnabled: IReason[] = [];
    public organizationReasonsDisabled: IReason[] = [];

    public publicReasons: IReason[] = localStorageService.loadObject('public_reasons', []);

    private publicReasonsRequest: Promise<IReason[]>|null = null;


    get publicReasonsList(): IReason[] {
        return this.publicReasons;
    }

    get selectedReasonsList(): IReason[] {
        return this.selectedReasons;
    }

    get areAllReasonsSelected(): boolean {
        return this.organizationReasonsEnabledList.length === this.selectedReasonsList.length;
    }

    get organizationReasonsEnabledList(): IReason[] {
        return this.organizationReasonsEnabled;
    }

    get organizationReasonsEnabledListSorted(): IReason[] {
        return this.organizationReasonsEnabled.sort((a, b) => {
            const aName = a.name.toUpperCase();
            const bName = b.name.toUpperCase();
            return aName === bName ? 0 : aName > bName ? 1 : -1;
        });
    }

    get organizationReasonsDisabledList(): IReason[] {
        return this.organizationReasonsDisabled;
    }

    get reasonsTotalEnabled(): number {
        return this.totalEnabled;
    }

    get reasonsTotalDisabled(): number {
        return this.totalDisabled;
    }

    @Action({rawError: true})
    public async fetchPublicReasons(): Promise<IReason[]> {
        if (this.publicReasons.length > 0) {
            return new Promise<IReason[]>((resolve) => resolve(this.publicReasons));
        }

        if (!this.publicReasonsRequest) {
            this.context.commit(PUBLIC_REASONS_REQUEST, new Promise<IReason[]>((resolve, reject) => {
                (Vue.prototype as Vue).$api.reason
                    .publicReasonsList()
                    .then((response: IReason[]) => {
                        this.context.commit(REQUEST_SUCCESS);
                        this.context.commit(SET_PUBLIC_REASONS, response);
                        resolve(response);
                    })
                    .catch((error) => {
                        this.context.commit(REQUEST_ERROR);
                        reject(error);
                    })
                ;
            }));
        }

        return this.publicReasonsRequest as Promise<IReason[]>;
    }

    @Action({rawError: true})
    public async bulkUpdateReasons(params: IBulkUpdateReasonsParams): Promise<IReason> {
        return new Promise<IReason>((resolve, reject) => {
            this.context.commit(REQUEST);
            (Vue.prototype as Vue).$api.reason
                .bulkUpdateReasons(params)
                .then((response: any) => {
                    this.context.commit(REQUEST_SUCCESS);
                    resolve(response);
                })
                .catch((error: Error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async fetchOrganizationReasonsEnabled(params: ISearchParams): Promise<IReason[]> {
        return new Promise<IReason[]>((resolve, reject) => {
            this.context.commit(REQUEST);
            (Vue.prototype as Vue).$api.reason
                .organizationReasonsListStatus(params, 'enabled')
                .then((response: IReason[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_REASONS_ENABLED_TOTAL, response);
                    this.context.commit(SET_ORGANIZATION_REASONS_ENABLED, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async fetchOrganizationReasonsDisabled(params: ISearchParams): Promise<IReason[]> {
        return new Promise<IReason[]>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.reason
                .organizationReasonsListStatus(params, 'disabled')
                .then((response: IReason[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_REASONS_DISABLED_TOTAL, response);
                    this.context.commit(SET_ORGANIZATION_REASONS_DISABLED, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async createReason(params: ICreateReasonParams): Promise<IReason> {
        return new Promise<IReason>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.reason
                .create(params)
                .then((response: IReason) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(ADD_REASON, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async updatePrivateReason(params: IUpdateReasonParams): Promise<IReason> {
        return new Promise<IReason>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.reason
                .updatePrivateReason(params)
                .then((response: IReason) => {
                    this.context.commit(REQUEST_SUCCESS);
                    response.status = params.status;

                    if (params.status === 'enabled') {
                        this.context.commit(DECREMENT_REASONS_DISABLED_TOTAL);
                        this.context.commit(INCREMENT_REASONS_ENABLED_TOTAL);
                    } else if (params.status === 'disabled') {
                        this.context.commit(INCREMENT_REASONS_DISABLED_TOTAL);
                        this.context.commit(DECREMENT_REASONS_ENABLED_TOTAL);
                    }

                    this.context.commit(UPDATE_REASON, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async updatePublicReason(params: IUpdateReasonParams): Promise<IReason> {
        return new Promise<IReason>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.reason
                .updatePublicReason(params)
                .then((response: IReason) => {
                    this.context.commit(REQUEST_SUCCESS);
                    response.status = params.status;

                    if (params.status === 'enabled') {
                        this.context.commit(DECREMENT_REASONS_DISABLED_TOTAL);
                        this.context.commit(INCREMENT_REASONS_ENABLED_TOTAL);
                    } else if (params.status === 'disabled') {
                        this.context.commit(INCREMENT_REASONS_DISABLED_TOTAL);
                        this.context.commit(DECREMENT_REASONS_ENABLED_TOTAL);
                    }

                    this.context.commit(UPDATE_REASON, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async deleteReason(
        {organizationId, reasonId}: {organizationId: string, reasonId: string},
    ): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.reason
                .deleteReason(organizationId, reasonId)
                .then((response: boolean) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(DELETE_REASON, reasonId);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public selectAllReasons() {
        this.context.commit(SELECT_ALL_REASONS);
    }

    @Action({rawError: true})
    public selectReason(reason: IReason) {
        this.context.commit(SELECT_REASON, reason);
    }

    @Action({rawError: true})
    public toggleReason(reason: IReason) {
        this.context.commit(TOGGLE_REASON, reason);
    }

    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
        this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [PUBLIC_REASONS_REQUEST](promise: Promise<IReason[]>) {
        this.status = 'loading';
        this.publicReasonsRequest = promise;
    }

    @Mutation
    private [SET_PUBLIC_REASONS](data: IReason[]) {
        this.publicReasons = data;
        localStorageService.storeObject('public_reasons', data);
    }

    @Mutation
    private [SET_ORGANIZATION_REASONS_ENABLED](data: IReason[]) {
        this.organizationReasonsEnabled = [...data].sort((value: any) => {
            return value.is_private ? 1 : -1;
        });
    }

    @Mutation
    private [SET_ORGANIZATION_REASONS_DISABLED](data: IReason[]) {
        this.organizationReasonsDisabled = [...data].sort((value: any) => {
            return value.is_private ? 1 : -1;
        });
    }

    @Mutation
    private [SET_REASONS_ENABLED_TOTAL](data: ISearchResult) {
        this.totalEnabled = data.total;
    }

    @Mutation
    private [SET_REASONS_DISABLED_TOTAL](data: ISearchResult) {
        this.totalDisabled = data.total;
    }

    @Mutation
    private [DECREMENT_REASONS_ENABLED_TOTAL](data: ISearchResult) {
        this.totalEnabled--;
    }

    @Mutation
    private [INCREMENT_REASONS_ENABLED_TOTAL](data: ISearchResult) {
        this.totalEnabled++;
    }

    @Mutation
    private [DECREMENT_REASONS_DISABLED_TOTAL](data: ISearchResult) {
        this.totalDisabled--;
    }

    @Mutation
    private [INCREMENT_REASONS_DISABLED_TOTAL](data: ISearchResult) {
        this.totalDisabled++;
    }

    @Mutation
    private [ADD_REASON](data: IReason) {
        this.organizationReasonsEnabled.push(data);
    }

    @Mutation
    private [UPDATE_REASON](data: IReason) {
        if (data.status === 'disabled') {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.organizationReasonsEnabled.length; ++i) {
                if (this.organizationReasonsEnabled[i].id === data.id) {
                    data.send_email = false;
                    data.send_sms = false;
                    this.organizationReasonsEnabled.splice(i, 1);
                    this.organizationReasonsDisabled.push(data);
                    break;
                }
            }

            this.organizationReasonsDisabled = [...this.organizationReasonsDisabled].sort((value: any) => {
                return value.is_private ? 1 : -1;
            });
        } else if(data.status === 'enabled') {
            // tslint:disable-next-line:prefer-for-of')
            for (let i = 0; i < this.organizationReasonsDisabled.length; ++i) {
                if (this.organizationReasonsDisabled[i].id === data.id) {
                    data.send_email=true;
                    data.send_sms=true;
                    this.organizationReasonsDisabled.splice(i, 1);
                    this.organizationReasonsEnabled.push(data);
                    break;
                }
            }

            this.organizationReasonsEnabled = [...this.organizationReasonsEnabled].sort((value: any) => {
                return value.is_private ? 1 : -1;
            });
        }
    }

    @Mutation
    private [DELETE_REASON](data: string) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.organizationReasonsEnabled.length; ++i) {
            if (this.organizationReasonsEnabled[i].id === data) {
                this.organizationReasonsEnabled.splice(i, 1);
                break;
            }
        }
    }

    @Mutation
    private [SELECT_ALL_REASONS]() {
        this.selectedReasons =
            [...this.organizationReasonsEnabled, ...this.organizationReasonsDisabled]
            .map((reason: IReason) => reason)
        ;
    }

    @Mutation
    private [SELECT_REASON](data: IReason) {
        this.selectedReasons = [data];
    }

    @Mutation
    private [TOGGLE_REASON](data: IReason) {
        const index = this.selectedReasons.indexOf(data);

        if (index === -1) {
            this.selectedReasons.push(data);
        } else {
            this.selectedReasons.splice(index, 1);
        }
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.status = null;
        this.selectedReasons = [];
        this.organizationReasonsEnabled = [];
        this.organizationReasonsDisabled = [];
    }
}
