import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import {
    AccountModule,
    AgendaModule,
    AnimalModule,
    BusinessHourModule,
    EventModule,
    LayoutModule,
    NotificationModule,
    OrganizationModule,
    ClientModule,
    ReasonModule,
    SnackbarModule,
    VeterinarianModule,
    ParaveterinarianModule,
    RegistrationModule,
    InstructionModule,
    RuleModule,
} from '@/store/modules';

export const store = new Vuex.Store({
    modules: {
        account: AccountModule,
        agenda: AgendaModule,
        animal: AnimalModule,
        businessHour: BusinessHourModule,
        event: EventModule,
        layout: LayoutModule,
        notification: NotificationModule,
        organization: OrganizationModule,
        client: ClientModule,
        reason: ReasonModule,
        snackbar: SnackbarModule,
        veterinarian: VeterinarianModule,
        paraveterinarian: ParaveterinarianModule,
        registration: RegistrationModule,
        instruction: InstructionModule,
        rule: RuleModule,
    },
    strict: process.env.NODE_ENV !== 'production',
});
