import Vue from 'vue';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';
import {reqHelper} from '@/helpers';
import {
    IBooking,
    IBookingFile,
    IComment,
    IFirstEvent,
    ICreateBookingCommentParams,
    ICreateBookingFilesParams,
    ICreateBookingFilesResult,
    ICreateBookingParams,
    ICreateUnavailabilityParams,
    IDeleteBookingFilesParams,
    IDeleteEventParams,
    IDeleteCommentParams,
    IEvent,
    IListBookingCommentsParams,
    IListBookingFilesParams,
    IListEventsParams,
    IMoveEventParams,
    ISms,
    IUnavailability,
    IUpdateBookingParams,
    IIUpdateEventRecurrenceParams,
    IUpdateUnavailabilityParams,
    ICreateHospitalizationParams,
    IErrorParams,
    ISchemaAJV,
    IDisplaySettings,
} from '@/types';

import {
    CLEAR_STATE,
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';

import {
    ADD_BOOKING_COMMENT,
    ADD_BOOKING_FILES,
    ADD_BOOKING_NOTE,
    ADD_BOOKING_NOTE_STRING,
    ADD_EVENT,
    DELETE_BOOKING_FILE,
    DELETE_EVENT,
    DELETE_COMMENT,
    DELETE_NOTE,
    SET_BOOKING_COMMENTS,
    SET_BOOKING_FILES,
    SET_BOOKING_NOTES,
    SET_BOOKING_SMS,
    SET_EVENTS,
    UPDATE_EVENT,
    SET_CLIENT_BOOKINGS,
} from '@/types/store/mutations/event.mutations';

@Module({
    namespaced: true,
    name: 'event',
})
export class EventModule extends VuexModule {
    public status: string|null = null;
    public events: IEvent[] = [];
    public clientBookings: IEvent[] = [];
    public comments: IComment[] = [];
    public commentsSms: ISms[] = [];
    public files: IBookingFile[] = [];
    public notes: IComment[] = [];

    private schemaAjv: ISchemaAJV = {
        type: 'object',
        properties: {},
        additionalProperties: true,
        required: [],
    };

    get eventsList(): IEvent[] {
        return this.events;
    }

    get clientBookingsList(): IEvent[] {
        return this.clientBookings;
    }

    get commentsList(): IComment[] {
        return this.comments;
    }

    get filesList(): IBookingFile[] {
        return this.files;
    }

    get notesList(): IComment[] {
        return this.notes;
    }

    get requestStatus(): string|null {
        return this.status;
    }

    get commentsAndFilesList(): ISms[] {
        const getDateSmsFrom = (a: ISms) => {
            switch(a.type) {
                case 'cancellation':
                    a.content_type = 'd\'annulation';
                break;
                case 'confirmation':
                    a.content_type = 'de confirmation';
                break;
                case 'file':
                    a.content_type = 'de notification de nouveau fichier';
                break;
                case 'public_comment':
                    a.content_type = 'de notification de nouveau message';
                break;
                case 'reminder':
                    a.content_type = 'de rappel';
                break;
            }

            switch(a.status) {
                case 'pending':
                    return a.planned_at;
                case 'received':
                    return a.received_at;
                case 'sent':
                    return a.sent_at;
                case 'delivered':
                    return a.delivered_at;
                case 'error':
                    return a.planned_at;
            }
        };

        return (this.comments as any)
            .concat(this.files, this.commentsSms)
            .sort((a: any, b: any) => {
                if(!a.inserted_at) {
                    a.inserted_at = getDateSmsFrom(a);
                }
                if(!b.inserted_at) {
                    b.inserted_at = getDateSmsFrom(b);
                }
                return a.inserted_at < b.inserted_at ? -1 : 1;
            });
    }

    @Action({rawError: true})
    public async fetchEvents(params: IListEventsParams): Promise<IEvent[]> {
        return new Promise<IEvent[]>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.event
                .list(params)
                .then((response: IEvent[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_EVENTS, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async fetchEvent(params: IFirstEvent): Promise<IEvent> {
        return new Promise<IEvent>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.event
                .first(params)
                .then((response: IEvent) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(UPDATE_EVENT, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async fetchClientBookings(params: IListEventsParams): Promise<IEvent[]> {
        return new Promise<IEvent[]>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.event
                .list(params)
                .then((response: IEvent[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_CLIENT_BOOKINGS, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async moveEvent(params: IMoveEventParams): Promise<IEvent> {
        return new Promise<IEvent>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.event
                .moveEvent(params)
                .then((response: IEvent) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(UPDATE_EVENT, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async updateRecurrence(params: IIUpdateEventRecurrenceParams): Promise<IEvent> {
        return new Promise<IEvent>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.event
                .updateRecurrence(params)
                .then((response: IEvent) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(UPDATE_EVENT, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async deleteEvent(params: IDeleteEventParams): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.context.commit(REQUEST);
            (Vue.prototype as Vue).$api.event
                .deleteEvent(params)
                .then((response: boolean) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(DELETE_EVENT, params.event_id);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async deleteComment(params: IDeleteCommentParams): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.event
                .deleteComment(params)
                .then((response: boolean) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(DELETE_COMMENT, params);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async deleteNote(params: IDeleteCommentParams): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.event
                .deleteNote(params)
                .then((response: boolean) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(DELETE_NOTE, params);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async createBooking(params: ICreateBookingParams): Promise<IBooking> {
        return new Promise<IBooking>((resolve, reject) => {
            this.context.commit(REQUEST);

            this.schemaAjv.properties = {
                client_id: {type: 'string'},
                organization_id: {type: 'string'},
                agenda_id: {type: 'string'}
            };
            this.schemaAjv.required = ['client_id', 'organization_id', 'animal_id', 'agenda_id'];

            const callbackData: any|IErrorParams = reqHelper.handlerJson(this.schemaAjv, params);
            
            if (callbackData.errors) {
                reject({errors: callbackData.errors});
            } else {
                (Vue.prototype as Vue).$api.event
                    .createBooking(params)
                    .then((response: IBooking) => {
                        this.context.commit(REQUEST_SUCCESS);
                        this.context.commit(ADD_EVENT, response);
                        resolve(response);
                    })
                    .catch((error) => {
                        this.context.commit(REQUEST_ERROR);
                        reject(error);
                    })
                ;
            }
        });
    }

    @Action({rawError: true})
    public async createHospitalization(params: ICreateHospitalizationParams): Promise<IBooking> {
        return new Promise<IBooking>((resolve, reject) => {
            this.context.commit(REQUEST);

            this.schemaAjv.properties = {
                client_id: {type: 'string'},
                organization_id: {type: 'string'},
                animal_id: {type: 'string'},
                agenda_id: {type: 'string'}
            };
            this.schemaAjv.required = ['client_id', 'organization_id', 'animal_id', 'agenda_id'];

            const callbackData: any|IErrorParams = reqHelper.handlerJson(this.schemaAjv, params);
            
            if (callbackData.errors) {
                reject({errors: callbackData.errors});
            } else {
                (Vue.prototype as Vue).$api.event
                    .createBooking(params)
                    .then((response: IBooking) => {
                        this.context.commit(REQUEST_SUCCESS);
                        this.context.commit(ADD_EVENT, response);
                        resolve(response);
                    })
                    .catch((error) => {
                        this.context.commit(REQUEST_ERROR);
                        reject(error);
                    })
                ;
            }
        });
    }

    @Action({rawError: true})
    public async updateBooking(params: IUpdateBookingParams): Promise<IBooking> {
        return new Promise<IBooking>((resolve, reject) => {
            this.context.commit(REQUEST);
            
            (Vue.prototype as Vue).$api.event
                .updateBooking(params)
                .then((response: IBooking) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(UPDATE_EVENT, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async createUnavailability(params: ICreateUnavailabilityParams): Promise<IUnavailability> {
        return new Promise<IUnavailability>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.event
                .createUnavailability(params)
                .then((response: IUnavailability) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(ADD_EVENT, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async updateUnavailability(params: IUpdateUnavailabilityParams): Promise<IUnavailability> {
        return new Promise<IUnavailability>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.event
                .updateUnavailability(params)
                .then((response: IUnavailability) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(UPDATE_EVENT, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async listBookingComments(params: IListBookingCommentsParams): Promise<IComment[]> {
        return new Promise<IComment[]>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.event
                .listBookingComments(params)
                .then((response: IComment[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_BOOKING_COMMENTS, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async listSmsStatus(params: IListBookingCommentsParams): Promise<ISms[]> {
        return new Promise<ISms[]>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.event
                .listSmsStatus(params)
                .then((response: ISms[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_BOOKING_SMS, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async listBookingFiles(params: IListBookingFilesParams): Promise<IBookingFile[]> {
        return new Promise<IBookingFile[]>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.event
                .listBookingFiles(params)
                .then((response: IBookingFile[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_BOOKING_FILES, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async listBookingNotes(params: IListBookingCommentsParams): Promise<IComment[]> {
        return new Promise<IComment[]>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.event
                .listBookingNotes(params)
                .then((response: IComment[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_BOOKING_NOTES, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async addBookingComment(params: ICreateBookingCommentParams): Promise<IComment> {
        return new Promise<IComment>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.event
                .createBookingComment(params)
                .then((response: IComment) => {
                    this.context.commit(REQUEST_SUCCESS);

                    const sms: ISms = {
                        delivered_at: '',
                        phone: '',
                        planned_at: '',
                        received_at: '',
                        sent_at: '',
                        status: 'pending',
                        type: '',
                        content_type: '',
                    };

                    if (params.notify_client) {
                        response.sms  = sms;
                    }

                    this.context.commit(ADD_BOOKING_COMMENT, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async addBookingFiles(params: ICreateBookingFilesParams): Promise<ICreateBookingFilesResult> {
        return new Promise<ICreateBookingFilesResult>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.event
                .createBookingFiles(params)
                .then((response: ICreateBookingFilesResult) => {
                    this.context.commit(REQUEST_SUCCESS);

                    const sms: ISms = {
                        delivered_at: '',
                        phone: '',
                        planned_at: '',
                        received_at: '',
                        sent_at: '',
                        status: 'pending',
                        type: '',
                        content_type: '',
                    };

                    if (params.notify_client) {
                        response.uploaded[0].sms  = sms;
                    }

                    this.context.commit(ADD_BOOKING_FILES, response.uploaded);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async deleteBookingFile(params: IDeleteBookingFilesParams): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.event
                .deleteBookingFile(params)
                .then((response: boolean) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(DELETE_BOOKING_FILE, params.file_id);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async addBookingNote(params: ICreateBookingCommentParams): Promise<IComment> {
        return new Promise<IComment>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.event
                .createBookingNote(params)
                .then((response: IComment) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(ADD_BOOKING_NOTE, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public clearState() {
        this.context.commit(CLEAR_STATE);
    }

    // Direct actions used by websockets

    @Action({rawError: true})
    public addEventFromChannel(params: IEvent): void {
        this.context.commit(ADD_EVENT, params);
    }

    @Action({rawError: true})
    public updateEventFromChannel(params: IEvent): void {
        this.context.commit(UPDATE_EVENT, params);
    }

    @Action({rawError: true})
    public deleteEventFromChannel(params: string): void {
        this.context.commit(DELETE_EVENT, params);
    }

    @Action({rawError: true})
    public addBookingNoteFromChannel(params: IComment) {
        this.context.commit(ADD_BOOKING_NOTE_STRING, params);
    }

    // Mutations

    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
        this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [SET_EVENTS](data: IEvent[]) {
        this.events = data;
    }

    @Mutation
    private [SET_CLIENT_BOOKINGS](data: IEvent[]) {
        this.clientBookings = data;
    }

    @Mutation
    private [DELETE_COMMENT](data: IDeleteCommentParams) {
        for (let i = 0; i < this.comments.length; ++i) {
            if (this.comments[i].id === data.comment_id) {
                this.comments.splice(i, 1);
                break;
            }
        }
    }

    @Mutation
    private [DELETE_NOTE](data: IDeleteCommentParams) {
        for (let i = 0; i < this.notes.length; ++i) {
            if (this.notes[i].id === data.comment_id) {
                this.notes.splice(i, 1);
                break;
            }
        }
    }

    @Mutation
    private [ADD_EVENT](data: IEvent) {
        if (!this.events.find((el: IEvent) => el.id === data.id)) {
            this.events.push(data);
        }
    }

    @Mutation
    private [UPDATE_EVENT](data: IEvent) {
        let found = false;

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.events.length; ++i) {
            if (this.events[i].id === data.id) {
                found = true;
                Vue.set(this.events, i, data);
                break;
            }
        }

        if (!found) {
            this.events.push(data);
        }
    }

    @Mutation
    private [DELETE_EVENT](data: string) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.events.length; ++i) {
            if (this.events[i].id === data) {
                this.events.splice(i, 1);
                break;
            }
        }
    }

    @Mutation
    private [SET_BOOKING_COMMENTS](comments: IComment[]) {
        this.comments = comments;
    }

    @Mutation
    private [SET_BOOKING_SMS](sms: ISms[]) {
        this.commentsSms = sms;
    }

    @Mutation
    private [SET_BOOKING_FILES](files: IBookingFile[]) {
        this.files = files;
    }

    @Mutation
    private [SET_BOOKING_NOTES](notes: IComment[]) {
        this.notes = notes;
    }

    @Mutation
    private [ADD_BOOKING_COMMENT](comment: IComment) {
        this.comments.push(comment);
    }

    @Mutation
    private [ADD_BOOKING_FILES](files: IBookingFile[]) {
        files.forEach((file) => {
            this.files.push(file);
        });
    }

    @Mutation
    private [DELETE_BOOKING_FILE](data: string) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.files.length; ++i) {
            if (this.files[i].id === data) {
                this.files.splice(i, 1);
                break;
            }
        }
    }

    @Mutation
    private [ADD_BOOKING_NOTE](note: IComment) {
        this.notes.push(note);
    }

    @Mutation
    private [ADD_BOOKING_NOTE_STRING](note: IComment) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.events.length; ++i) {
            if (this.events[i].type === 'booking' && (this.events[i] as IBooking).booking.id === note.booking_id) {
                (this.events[i] as IBooking).notes.push(note.comment);
                break;
            }
        }
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.status = null;
        this.events = [];
        this.clientBookings = [];
        this.comments = [];
        this.commentsSms = [];
        this.files = [];
        this.notes = [];
    }
}
