import Vue from 'vue';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';
import {reqHelper} from '@/helpers';
import {localStorageService} from '@/storage/localstorage.service';

import {
    IAnimal,
    IAnimalType,
    IBreed,
    ICreateAnimalParams,
    IDeleteAnimalParams,
    IFetchAnimalsParams,
    ISpecies,
    IUpdateAnimalParams,
    IErrorParams,
    ISchemaAJV,
} from '@/types';

import {
    CLEAR_STATE,
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';

import {
    ADD_ANIMAL,
    BREEDS_REQUEST,
    DELETE_ANIMAL,
    SET_ANIMALS_LIST,
    SET_BREEDS,
    SET_SPECIES,
    SET_TYPES,
    SPECIES_REQUEST,
    SET_SPECIES_BY_TYPE,
    TYPES_REQUEST,
    UPDATE_ANIMAL,
} from '@/types/store/mutations/animal.mutations';

@Module({
    namespaced: true,
    name: 'animal',
})
export class AnimalModule extends VuexModule {
    public status: string|null = null;
    public animals: IAnimal[] = [];
    public speciesByType: ISpecies[] = localStorageService.loadObject('species_by_type', []);
    public types: IAnimalType[] = localStorageService.loadObject('animal_types', []);
    public species: ISpecies[] = localStorageService.loadObject('species', []);
    public breeds: IBreed[] = localStorageService.loadObject('breeds', []);

    public speciesUpdatedAt = localStorageService.loadObject('speciesUpdatedAt', null);
    public animalTypesUpdatedAt = localStorageService.loadObject('animalTypesUpdatedAt', null);
    public breedsUpdatedAt = localStorageService.loadObject('breedsUpdatedAt', null);
    public speciesByTypeUpdatedAt = localStorageService.loadObject('speciesByTypeUpdatedAt', null);

    private typesRequest: Promise<IAnimalType[]>|null = null;
    private speciesRequest: Promise<ISpecies[]>|null = null;
    private breedsRequest: Promise<IBreed[]>|null = null;
    private schemaAjv: ISchemaAJV = {
        type: 'object',
        properties: {},
        additionalProperties: true,
        required: [],
    };

    get typesList(): IAnimalType[] {
        return this.types;
    }

    get speciesList(): ISpecies[] {
        return this.species;
    }

    get speciesByTypeList(): ISpecies[] {
        return this.speciesByType;
    }

    get breedsList(): IBreed[] {
        return this.breeds;
    }

    get animalsList(): IAnimal[] {
        return this.animals;
    }

    @Action({rawError: true})
    public async computeSpeciesByTypeList(): Promise<ISpecies[]> {
        if (this.speciesByType.length > 0 && localStorageService.checkValidity(this.speciesByTypeUpdatedAt)) {
            return new Promise<ISpecies[]>((resolve) => resolve(this.speciesByType));
        }

        return new Promise<ISpecies[]>((resolve, reject) => {
            Promise
                .all([this.fetchTypes(), this.fetchSpecies()])
                .then(([types, species]) => {
                    const typesById = types.reduce((carry: any, type: IAnimalType) => {
                        carry[type.id] = type;
                        return carry;
                    }, {});

                    const speciesByType = species.reduce((carry: any, speciesItem: ISpecies) => {
                        if (!carry[speciesItem.animal_type_id]) {
                            (carry as any)[speciesItem.animal_type_id] = {
                                name: typesById[speciesItem.animal_type_id].name,
                                species: [],
                            };
                        }

                        carry[speciesItem.animal_type_id].species.push(speciesItem);

                        return carry;
                    }, {});

                    const sortedKeys: string[] = Object.keys(speciesByType).sort((a: string, b: string) => {
                        const aName = speciesByType[a].name;
                        const bName = speciesByType[b].name;

                        return aName < bName ? -1 : 1;
                    });

                    const sortedSpeciesByType = sortedKeys.reduce((carry: any[], key: string) => {
                        const typeSpecies = speciesByType[key];
                        const sortedSpecies = typeSpecies.species.sort((a: ISpecies, b: ISpecies) => {
                            return a.name < b.name ? -1 : 1;
                        });

                        return [...carry, {header: typeSpecies.name}, ...sortedSpecies];
                    }, []);

                    this.context.commit(SET_SPECIES_BY_TYPE, sortedSpeciesByType);
                    resolve(sortedSpeciesByType);
                })
                .catch((error) => reject(error));
        });
    }

    @Action({rawError: true})
    public async fetchTypes(): Promise<IAnimalType[]> {
        if (this.types.length > 0 && localStorageService.checkValidity(this.animalTypesUpdatedAt)) {
            return new Promise<IAnimalType[]>((resolve) => resolve(this.types));
        }

        if (!this.typesRequest) {
            this.context.commit(TYPES_REQUEST, new Promise<IAnimalType[]>((resolve, reject) => {
                (Vue.prototype as Vue).$api.animal
                    .typesList()
                    .then((response: IAnimalType[]) => {
                        this.context.commit(REQUEST_SUCCESS);
                        this.context.commit(SET_TYPES, response);
                        resolve(response);
                    })
                    .catch((error) => {
                        this.context.commit(REQUEST_ERROR);
                        reject(error);
                    })
                ;
            }));
        }

        return this.typesRequest as Promise<IAnimalType[]>;
    }

    @Action({rawError: true})
    public async fetchSpecies(): Promise<ISpecies[]> {
        if (this.species.length > 0 && localStorageService.checkValidity(this.speciesUpdatedAt)) {
            return new Promise<ISpecies[]>((resolve) => resolve(this.species));
        }

        if (!this.speciesRequest) {
            this.context.commit(SPECIES_REQUEST, new Promise((resolve, reject) => {
                (Vue.prototype as Vue).$api.animal
                    .speciesList()
                    .then((response: ISpecies[]) => {
                        this.context.commit(REQUEST_SUCCESS);
                        this.context.commit(SET_SPECIES, response);
                        resolve(response);
                    })
                    .catch((error) => {
                        this.context.commit(REQUEST_ERROR);
                        reject(error);
                    })
                ;
            }));
        }

        return this.speciesRequest as Promise<ISpecies[]>;
    }

    @Action({rawError: true})
    public async fetchSpeciesById(id: string): Promise<ISpecies> {
        return new Promise<ISpecies>((resolve, reject) => {
            this
                .fetchSpecies()
                .then((speciesList: ISpecies[]) => {
                    const species = speciesList.find((el: ISpecies) => {
                        return el.id === id;
                    });

                    if (species) {
                        resolve(species);
                    } else {
                        reject('Species not found');
                    }
                })
            ;
        });
    }

    @Action({rawError: true})
    public async fetchBreeds(): Promise<IBreed[]> {
        if (this.breeds.length > 0 && localStorageService.checkValidity(this.breedsUpdatedAt)) {
            return new Promise<IBreed[]>((resolve) => resolve(this.breeds));
        }

        if (!this.breedsRequest) {
            this.context.commit(BREEDS_REQUEST, new Promise((resolve, reject) => {
                (Vue.prototype as Vue).$api.animal
                    .breedsList()
                    .then((response: IBreed[]) => {
                        this.context.commit(REQUEST_SUCCESS);
                        this.context.commit(SET_BREEDS, response);
                        resolve(response);
                    })
                    .catch((error) => {
                        this.context.commit(REQUEST_ERROR);
                        reject(error);
                    })
                ;
            }));
        }

        return this.breedsRequest as Promise<IBreed[]>;
    }

    @Action({rawError: true})
    public async fetchAnimals(params: IFetchAnimalsParams): Promise<IAnimal[]> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.animal
                .list(params)
                .then((response: IAnimal[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ANIMALS_LIST, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async createAnimal(params: ICreateAnimalParams): Promise<IAnimal> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);
            (Vue.prototype as Vue).$api.animal
                .create(params)
                .then((response: IAnimal) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(ADD_ANIMAL, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async updateAnimal(params: IUpdateAnimalParams): Promise<IAnimal> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            this.schemaAjv.properties = {
                client_id: {type: 'string'},
                organization_id: {type: 'string'}
            };
            this.schemaAjv.required = ['client_id', 'organization_id'];
            
            const callbackData: any|IErrorParams = reqHelper.handlerJson(this.schemaAjv, params);
            if (callbackData.errors) {
                reject({errors: callbackData.errors});
            } else {
                (Vue.prototype as Vue).$api.animal
                    .update(params)
                    .then((response: IAnimal) => {
                        this.context.commit(REQUEST_SUCCESS);
                        this.context.commit(UPDATE_ANIMAL, response);
                        resolve(response);
                    })
                    .catch((error) => {
                        this.context.commit(REQUEST_ERROR);
                        reject(error);
                    })
                ;
            }
        });
    }

    @Action({rawError: true})
    public async deleteAnimal(params: IDeleteAnimalParams): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            this.schemaAjv.properties = {
                client_id: {type: 'string'},
                organization_id: {type: 'string'},
                animal_id: {type: 'string'}
            };
            this.schemaAjv.required = ['client_id', 'organization_id', 'animal_id'];

            const callbackData: any|IErrorParams = reqHelper.handlerJson(this.schemaAjv, params);
            
            if (callbackData.errors) {
                reject({errors: callbackData.errors});
            } else {
                (Vue.prototype as Vue).$api.animal
                    .remove(params)
                    .then((response: boolean) => {
                        this.context.commit(REQUEST_SUCCESS);
                        this.context.commit(DELETE_ANIMAL, params.animal_id);
                        resolve(response);
                    })
                    .catch((error) => {
                        this.context.commit(REQUEST_ERROR);
                        reject(error);
                    })
                ;
            }
        });
    }

    @Action({rawError: true})
    public clearAnimalsList() {
        this.context.commit(SET_ANIMALS_LIST, []);
    }

    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
        this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [TYPES_REQUEST](promise: Promise<IAnimalType[]>) {
        this.status = 'loading';
        this.typesRequest = promise;
    }

    @Mutation
    private [SPECIES_REQUEST](promise: Promise<ISpecies[]>) {
        this.status = 'loading';
        this.speciesRequest = promise;
    }

    @Mutation
    private [BREEDS_REQUEST](promise: Promise<IBreed[]>) {
        this.status = 'loading';
        this.breedsRequest = promise;
    }

    @Mutation
    private [SET_TYPES](data: IAnimalType[]) {
        this.types = data;
        localStorageService.storeObject('animal_types', data);
    }

    @Mutation
    private [SET_SPECIES](data: ISpecies[]) {
        this.species = data;
        localStorageService.storeObject('species', data);
    }

    @Mutation
    private [SET_SPECIES_BY_TYPE](data: ISpecies[]) {
        this.speciesByType = data;
        localStorageService.storeObject('species_by_type', data);
    }

    @Mutation
    private [SET_BREEDS](data: IBreed[]) {
        this.breeds = data;
        localStorageService.storeObject('breeds', data);
    }

    @Mutation
    private [SET_ANIMALS_LIST](data: IAnimal[]) {
        this.animals = data;
    }

    @Mutation
    private [ADD_ANIMAL](data: IAnimal) {
        this.animals.push(data);
    }

    @Mutation
    private [UPDATE_ANIMAL](data: IAnimal) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.animals.length; ++i) {
            if (this.animals[i].id === data.id) {
                Vue.set(this.animals, i, data);
                break;
            }
        }
    }

    @Mutation
    private [DELETE_ANIMAL](animalId: string) {
        this.animals = this.animals.filter((animal: IAnimal) => animal.id !== animalId);
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.status = null;
        this.animals = [];
    }
}
