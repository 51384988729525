import Vue from 'vue';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';
import {reqHelper} from '@/helpers';
import {
    ICreateClientParams,
    IDeleteClientParams,
    IClient,
    ISearchClientsParams,
    ISearchClientsResult,
    IUpdateClientParams,
    IFetchClientParams,
    IErrorParamsReq,
    ISchemaAJV,
} from '@/types';

import {
    CLEAR_STATE,
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';

import {
    DELETE_PATIENT,
    SET_PATIENTS_LIST,
    SET_PATIENTS_TOTAL,
    DECREMENT_PATIENTS_TOTAL,
    INCREMENT_PATIENTS_TOTAL,
    SET_PATIENT,
    ADD_PATIENT,
    CLEAR_PATIENT,
    UPDATE_PATIENT,
} from '@/types/store/mutations/client.mutations';

@Module({
    namespaced: true,
    name: 'client',
})
export class ClientModule extends VuexModule {
    public status: string|null = null;
    public clients: IClient[] = [];
    public total: number = 0;
    public client: IClient|null = null;

    private schemaAjv: ISchemaAJV = {
        type: 'object',
        properties: {},
        additionalProperties: true,
        required: [],
    };

    get currentClient(): IClient|null {
        return this.client;
    }

    get clientsList(): IClient[] {
        return this.clients;
    }
    get clientsTotal(): number {
        return this.total;
    }

    @Action({rawError: true})
    public async fetchClients(params: ISearchClientsParams): Promise<ISearchClientsResult> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.client
                .search(params)
                .then((response: ISearchClientsResult) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_PATIENTS_LIST, response);
                    this.context.commit(SET_PATIENTS_TOTAL, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async fetchClient(params: IFetchClientParams): Promise<IClient> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.client
                .getClient(params)
                .then((response: IClient) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_PATIENT, response);
                    this.context.commit(UPDATE_PATIENT, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async createClient(params: ICreateClientParams): Promise<IClient> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);
                (Vue.prototype as Vue).$api.client
                    .create(params)
                    .then((response: IClient) => {
                        this.context.commit(REQUEST_SUCCESS);
                        this.context.commit(INCREMENT_PATIENTS_TOTAL);
                        this.context.commit(ADD_PATIENT, response);
                        resolve(response);
                    })
                    .catch((error) => {
                        this.context.commit(REQUEST_ERROR);
                        reject(error);
                    })
                ;
        });
    }

    @Action({rawError: true})
    public async resetClient() {
        this.context.commit(CLEAR_PATIENT);
    }

    @Action({rawError: true})
    public async updateClient(params: IUpdateClientParams): Promise<IClient> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);
                (Vue.prototype as Vue).$api.client
                    .update(params)
                    .then((response: IClient) => {
                        this.context.commit(REQUEST_SUCCESS);
                        this.context.commit(UPDATE_PATIENT, response);
                        resolve(response);
                    })
                    .catch((error) => {
                        this.context.commit(REQUEST_ERROR);
                        reject(error);
                    })
                ;
            }
        );
    }

    @Action({rawError: true})
    public async deleteClient(params: IDeleteClientParams): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            this.schemaAjv.properties = {
                client_id: {type: 'string'},
                organization_id: {type: 'string'}
            };
            this.schemaAjv.required = ['client_id', 'organization_id'];

            const callbackData: any|IErrorParamsReq = reqHelper.handlerJson(this.schemaAjv, params);
            
            if (callbackData.errors) {
                reject({errors: callbackData.errors});
            } else {
                (Vue.prototype as Vue).$api.client
                    .remove(params)
                    .then((response: boolean) => {
                        this.context.commit(REQUEST_SUCCESS);
                        this.context.commit(DELETE_PATIENT, params.client_id);
                        this.context.commit(DECREMENT_PATIENTS_TOTAL);
                        this.context.commit(CLEAR_PATIENT);
                        resolve(response);
                    })
                    .catch((error) => {
                        this.context.commit(REQUEST_ERROR);
                        reject(error);
                    })
                ;
            }
        });
    }

    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
        this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [SET_PATIENTS_LIST](data: ISearchClientsResult) {
        this.clients = data.organizations_clients;
    }

    @Mutation
    private [ADD_PATIENT](data: IClient) {
        this.clients.push(data);
    }

    @Mutation
    private [SET_PATIENT](data: IClient) {
        this.client = data;
    }

    @Mutation
    private [CLEAR_PATIENT](data: IClient) {
        this.client = null;
    }

    @Mutation
    private [SET_PATIENTS_TOTAL](data: ISearchClientsResult) {
        this.total = data.total;
    }

    @Mutation
    private [DECREMENT_PATIENTS_TOTAL]() {
        this.total--;
    }

    @Mutation
    private [INCREMENT_PATIENTS_TOTAL]() {
        this.total++;
    }

    @Mutation
    private [UPDATE_PATIENT](data: IClient) {
        this.clients = this.clients.map((client: IClient) => {
            if (client.id === data.id) {
                return data;
            }

            return client;
        });

        if (this.client && this.client.id === data.id) {
            this.client = data;
        }
    }

    @Mutation
    private [DELETE_PATIENT](clientId: string) {
        this.clients = this.clients.filter((client: IClient) => client.id !== clientId);
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.status = null;
        this.clients = [];
        this.client = null;
        this.total = 0;
    }
}
