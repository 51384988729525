
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {namespace} from 'vuex-class';
    import {Model, Prop} from 'vue-property-decorator';
    import {AnimalModule, SnackbarModule,EventModule} from '@/store/modules';

    import {
        IAnimal,
        IBreed,
        ICreateAnimalParams,
        IOrganization,
        ISpecies,
        IBookingForm,
        IFirstEvent,
        IUpdateAnimalParams,
    } from '@/types';

    import AnimalForm from '@/components/AnimalForm.vue';

    const animalNamespace = namespace('animal');
    const organizationNamespace = namespace('organization');

    

    @Component<AnimalFormCard>({
        components: {
            AnimalForm,
        },
    })
    export default class AnimalFormCard extends Vue {
        public animalFormValid = false;
        public innerModel!: IAnimal;

        @animalNamespace.Getter('speciesList')
        public speciesList!: ISpecies[];

        @animalNamespace.Getter('breedsList')
        public breedsList!: IBreed[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @Prop({
            required: false,
            default: false,
        })
        public booking!: IBookingForm;

        @Model('input', {type: Object}) public readonly value!: IAnimal;

        @Prop({
            type: Boolean,
            required: false,
            default: true,
        })
        public isEditable!: boolean;

        get cardTitle() {
            if (this.innerModel.id) {
                return 'Modifier un animal';
            }

            return 'Ajouter un animal';
        }

        get submitButtonTitle() {
            if (this.innerModel.id) {
                return 'Modifier';
            }

            return 'Ajouter';
        }

        get params(): ICreateAnimalParams|IUpdateAnimalParams {
            const params: ICreateAnimalParams|IUpdateAnimalParams =  {
                name: this.innerModel.name,
                sex: this.innerModel.sex,
                is_sterilized: this.innerModel.is_sterilized,
                is_crossbreed: this.innerModel.is_crossbreed,
                species_id: this.innerModel.species.id,
                breed_id: this.innerModel.breed ? this.innerModel.breed.id : null,
                client_id: this.booking ? this.booking.client?.id : this.innerModel.client_id,
                organization_id: this.loggedOrganization.id,
            };

            if (this.innerModel.birth_date) {
                params.birth_date = this.innerModel.birth_date;
            }

            if (this.innerModel.id) {
                (params as IUpdateAnimalParams).animal_id = this.innerModel.id;
            }

            return params;
        }

        public submitButtonClicked() {
            if (this.innerModel.id) {
                return this.submitUpdateAnimal();
            }

            return this.submitCreateAnimal();
        }

        public submitCreateAnimal() {
            const animalModule = getModule(AnimalModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            animalModule
                .createAnimal(this.params as ICreateAnimalParams)
                .then((animal: IAnimal) => {
                    snackbarModule.displaySuccess(`${this.innerModel.name} a bien été créé(e) !`);
                    this.$emit('input', animal);
                    this.$emit('close');
                }).catch((error)=> {
                    this.animalFormValid = false;
                    snackbarModule.displayError(error);
                })
            ;
        }

        public submitUpdateAnimal() {
            const animalModule = getModule(AnimalModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);
            const eventModule = getModule(EventModule, this.$store);

            animalModule
                .updateAnimal(this.params as IUpdateAnimalParams)
                .then((animal: IAnimal) => {
                    snackbarModule.displaySuccess(`${this.innerModel.name} a bien été modifié(e) !`);
                    
                    const params: IFirstEvent = {
                        booking_id: this.booking.id,
                        organization_id: this.loggedOrganization.id,
                    }

                    eventModule.fetchEvent(params as IFirstEvent).then();

                    this.$emit('input', animal);
                    this.$emit('close');
                }).catch((error)=> {
                    this.animalFormValid = false;
                    snackbarModule.displayError(error);
                })
            ;
        }

        private data() {
            return {
                innerModel: Object.assign({}, this.value),
            };
        }
    }
