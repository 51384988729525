export const ADD_AGENDA = '_addAgenda';
export const ADD_AGENDAS_TO_SELECTION = '_addAgendasToSelection';
export const DELETE_AGENDA = '_deleteAgenda';
export const DESELECT_AGENDAS = '_deselectAgendas';
export const SELECT_AGENDA = '_selectAgenda';
export const SELECT_AGENDAS = '_selectAgendas';
export const SELECT_ALL_AGENDAS = '_selectAllAgendas';
export const SELECT_DATE = '_selectDate';
export const SELECT_VIEW = '_selectView';
export const SET_AGENDAS = '_setAgendas';
export const SET_GROUPS = '_setGroups';
export const TOGGLE_AGENDA = '_toggleAgenda';
export const UPDATE_AGENDA = '_updateAgenda';
