
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {localStorageService} from '@/storage/localstorage.service';

    @Component<RebrandDialog>({})
    export default class RebrandDialog extends Vue {
        public openedSync: boolean = false;
        public santevetInfo: string|null = localStorageService.load('santevet-infos');
        
        public mounted() {
            this.openedSync = this.santevetInfo === 'false' || !this.santevetInfo;
        }
        public handleClick() {
            localStorageService.store('santevet-infos', 'true');
            this.openedSync = false;
        }
    }
