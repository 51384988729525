import {Channel} from 'phoenix';
import {getModule} from 'vuex-module-decorators';

import {store} from '@/store';

import {
    IBookingNotification,
    INewBookingCommentNotification,
    INewBookingFileNotification,
    INotification,
} from '@/types';

import AccountSocket from '@/sockets/account.socket';

import {
    NotificationModule,
} from '@/store/modules';

export default class AgendaNotificationChannel {
    private channel!: Channel;

    constructor(agendaId: string) {
        AccountSocket
            .getInstance()
            .ensureConnected()
            .then((accountSocket: AccountSocket) => {
                accountSocket
                    .channel(`agenda_notification:${agendaId}`)
                    .then((channel) => {
                        this.channel = channel;
                        this.addEventHandlers();
                        this.channel
                            .join()
                            .receive(
                                'ok',
                                ({notifications}) => {
                                    notifications.forEach((notification: INotification) => {
                                        getModule(NotificationModule, store)
                                            .addNotification({notification, shouldNotify: false});
                                    });
                                },
                            )
                        ;
                    })
                ;
            })
        ;
    }

    public leave() {
        if (this.channel) {
            this.channel.leave();
        }
    }

    public markRead(id: string) {
        return new Promise<void>((resolve, reject) => {
            if (!this.channel) {
                return reject('Channel not connected');
            }

            this.channel.push('seen', {id});
            resolve();
        });
    }

    private addEventHandlers() {
        this.channel.on('new_booking', (message: IBookingNotification) => {
            getModule(NotificationModule, store).addNotification({notification: message, shouldNotify: true});
        });

        this.channel.on('new_booking_comment', (message: INewBookingCommentNotification) => {
            getModule(NotificationModule, store).addNotification({notification: message, shouldNotify: true});
        });

        this.channel.on('new_booking_file', (message: INewBookingFileNotification) => {
            getModule(NotificationModule, store).addNotification({notification: message, shouldNotify: true});
        });

        this.channel.on('booking_cancelled', (message: IBookingNotification) => {
            getModule(NotificationModule, store).addNotification({notification: message, shouldNotify: true});
        });

        this.channel.on('booking_moved', (message: IBookingNotification) => {
            getModule(NotificationModule, store).addNotification({notification: message, shouldNotify: true});
        });
    }
}
