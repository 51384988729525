export const ADD_REASON = '_addReason';
export const DELETE_REASON = '_deleteReason';
export const PUBLIC_REASONS_REQUEST = '_publicReasonsRequest';
export const SELECT_ALL_REASONS = '_selectAllReasons';
export const SELECT_REASON = '_selectReason';
export const SET_ORGANIZATION_REASONS_DISABLED = '_setOrganizationReasonsDisabled';
export const SET_ORGANIZATION_REASONS_ENABLED = '_setOrganizationReasonsEnabled';
export const SET_REASONS_DISABLED_TOTAL = '_setReasonsDisabledTotal';
export const SET_REASONS_ENABLED_TOTAL = '_setReasonsEnabledTotal';
export const SET_PUBLIC_REASONS = '_setPublicReasons';
export const DECREMENT_REASONS_ENABLED_TOTAL = '_decrementReasonsEnabledTotal';
export const INCREMENT_REASONS_ENABLED_TOTAL = '_incrementReasonsEnabledTotal';
export const DECREMENT_REASONS_DISABLED_TOTAL = '_decrementReasonsDisabledTotal';
export const INCREMENT_REASONS_DISABLED_TOTAL = '_incrementReasonsDisabledTotal';
export const TOGGLE_REASON = '_toggleReason';
export const UPDATE_REASON = '_updateReason';
