import {IResizeImageOptions} from '@/types';

export const imageHelper = {
    resizeImage,
};

function resizeImage(settings: IResizeImageOptions): Promise<File> {
    const file = settings.file;
    const reader = new FileReader();
    const image = new Image();

    return new Promise((resolve, reject) => {
        if (!file.type.match(/image.*/)) {
            return reject(new Error('Not an image'));
        }

        reader.onload = (readerEvent: ProgressEvent<FileReader |  any>) => {
            image.onload = () => doResize(file, image, settings.maxWidth, settings.maxHeight)
                .then((resizedFile: File) => resolve(resizedFile))
                .catch((error) => reject(error))
            ;
            if (readerEvent.target) {
                image.src = readerEvent.target.result;
            }
        };

        reader.readAsDataURL(file);
    });
}

function doResize(oringinalFile: File, image: HTMLImageElement, maxWidth: number, maxHeight: number): Promise<File> {
    const canvas: HTMLCanvasElement = document.createElement('canvas');

    let width = image.width;
    let height = image.height;

    if (width > height) {
        if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
        }
    } else {
        if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
        }
    }

    canvas.width = width;
    canvas.height = height;
    (canvas.getContext('2d') as any).drawImage(image, 0, 0, width, height);

    return new Promise((resolve, reject) => {
        canvas.toBlob((blob: Blob|null) => {
            if (!blob) {
                return reject('can\'t create blob');
            }

            const file = new File(
                [blob],
                oringinalFile.name,
                {
                    lastModified: oringinalFile.lastModified,
                },
            );

            return resolve(file);
        }, 'image/jpeg');
    });
}
