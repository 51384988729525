
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';


    import {
        AccountModule,
        LayoutModule,
        OrganizationModule,
    } from '@/store/modules';

    import {
        IAccountRole,
        IOrganization,
        IParaveterinarian,
        IVeterinarian,
    } from '@/types';

    const accountNamespace = namespace('account');
    const organizationNamespace = namespace('organization');
    const notificationNamespace = namespace('notification');

    @Component<TheHeader>({
        props: {
            drawer: {
                type: Boolean,
                default: false,
            },
        },
    })
    export default class TheHeader extends Vue {
        public drawerLeft: boolean = false;

        @accountNamespace.Getter('isLoggedIn')
        public isLoggedIn!: boolean;

        @accountNamespace.Getter('isImpersonated')
        public isImpersonated!: boolean;

        @accountNamespace.Getter('loggedVeterinarian')
        public loggedVeterinarian!: IVeterinarian|null;

        @accountNamespace.Getter('loggedParaveterinarian')
        public loggedParaveterinarian!: IParaveterinarian|null;

        @accountNamespace.Getter('loggedAccountRoles')
        public loggedAccountRoles!: IAccountRole[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @notificationNamespace.Getter('unseenNotifications')
        public unseenNotifications!: number;

        get currentRoute() {
            return this.$route.name;
        }

        get organizations() {
            return this.loggedAccountRoles
                .filter((accountRole: IAccountRole) => {
                    return !!accountRole.organization &&
                        (!this.loggedOrganization || accountRole.organization.id !== this.loggedOrganization.id)
                    ;
                })
                .map((accountRole: IAccountRole) => {
                    return accountRole.organization;
                })
            ;
        }


        get hasDrawerLeft() {
            return this.$route.matched.some((r) => r.meta.hasDrawerLeft);
        }

        get shortOrganizationName() {
            const lengthName = this.loggedOrganization.name.split('');

            if (lengthName.length < 23) {
                return this.loggedOrganization.name;
            } else {
                let newName: string = '';
                lengthName.map((e, i) => {
                        if (i < 24) {
                            newName = newName+e;
                        }
                    },
                );

                return newName + '...';
            }
        }

        get spltName() {
            if(!this.loggedVeterinarian) {
                return;
            }

            const splitedFirstName = this.loggedVeterinarian.first_name.charAt(0);
            const splitedLastName = this.loggedVeterinarian.last_name.charAt(0);

            return splitedFirstName+'.'+splitedLastName;
        }

        get loggedName() {
            if (this.loggedVeterinarian) {
                return `${this.loggedVeterinarian.first_name} ${this.loggedVeterinarian.last_name}`;
            }

            if (this.loggedParaveterinarian) {
                return `${this.loggedParaveterinarian.first_name} ${this.loggedParaveterinarian.last_name}`;
            }

            return 'Mon compte';
        }

        get barProps() {
            const color = this.currentRoute === 'business-hours' ? 'info' : 'primary';

            return {
                absolute: false,
                app: true,
                color,
                hideOnScroll: false,
            };
        }

        public selectOrganization(organization: IOrganization) {
            const organizationModule = getModule(OrganizationModule, this.$store);

            organizationModule.selectOrganization(organization);

            // TODO(#2): don't reload, clean and refetch main data instead
            // window.location.reload();
        }

        public toggleDrawerLeft() {
            getModule(LayoutModule, this.$store).toggleDrawerLeft();
        }

        public toggleNotificationDrawer() {
            getModule(LayoutModule, this.$store).toggleNotificationDrawer();
        }

        public logout() {
            getModule(AccountModule, this.$store)
                .logout()
                .then(() => {
                    if (this.$route.name !== 'home') {
                        this.$router.push('/');
                    }
                })
            ;
        }
    }
