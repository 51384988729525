
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {DateTime} from 'luxon';

    import {
        IAccount,
        IAgenda,
        IAnimal,
        IBooking,
        IBookingForm,
        IOrganization,
        IClient,
        IUpdateBookingParams,
        IReason,
    } from '@/types';

    import AttendanceSelect from '@/components/forms/AttendanceSelect.vue';
    import BookingForm from '@/components/BookingForm.vue';
    import CancelBookingDialog from '@/components/dialogs/CancelBookingDialog.vue';
    import ConfirmAbsentDialog from '@/components/dialogs/ConfirmAbsentDialog.vue';
    import ClientCard from '@/components/ClientCard.vue';
    import AnimalFormCard from '@/components/AnimalFormCard.vue';

    import {EventModule, SnackbarModule} from '@/store/modules';

    const accountNamespace = namespace('account');
    const organizationNamespace = namespace('organization');

    @Component<BookingSummaryCard>({
        components: {
            AttendanceSelect,
            BookingForm,
            CancelBookingDialog,
            ConfirmAbsentDialog,
            ClientCard,
            AnimalFormCard,
        },
    })
    export default class BookingSummaryCard extends Vue {
        public reminderDialogIsOpen: boolean = false;
        public reminderTypes: string[] = [
            'Vaccin',
            'Antiparasitaires',
            'Contrôle post opératoire',
            'Consultation pubertaire',
            'Stérilisation',
            'Personnalisé',
        ];
        public reminderDelay: string[] = [
            '1 mois',
            '2 mois',
            '3 mois',
            '4 mois',
            '5 mois',
            '6 mois',
            '7 mois',
            '8 mois',
            '9 mois',
            '10 mois',
            '11 mois',
            '12 mois',
        ];
        public reminders = [];
        public clientDialogIsOpen: boolean = false;
        public animalDialogIsOpen: boolean = false;
        public cancelDialog: boolean = false;
        public confirmAbsentDialog: boolean = false;
        public bookingFormValid: boolean = false;

        public editableBooking: IBookingForm|null = null;
        public isEditingBookingForm: boolean = false;
        public isSubmittingBookingForm: boolean = false;

        public isSubmittingAttendance: boolean = false;

        @Prop({
            type: Object,
            required: true,
        })
        public booking!: IBooking;

        @Prop({
            type: Date,
            required: true,
        })
        public occurrenceStart!: Date;

        @Prop({
            type: Date,
            required: true,
        })
        public occurrenceEnd!: Date;

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        set attendance(value: string) {
            this.isSubmittingAttendance = true;

            if (value === 'absent') {
                this.confirmAbsentDialog = true;
            } else {
                const snackbarModule = getModule(SnackbarModule, this.$store);
                const params: IUpdateBookingParams = {
                    booking_id: this.booking.booking.id,
                    organization_id: this.loggedOrganization.id,
                    attendance: value,
                };

                getModule(EventModule, this.$store)
                    .updateBooking(params)
                    .then((booking: IBooking) => {
                        snackbarModule.displaySuccess
                        ('Le statut de présence de votre rendez-vous a bien été modifié !');
                    })
                    .catch(() => snackbarModule.displayError())
                    .finally(() => this.isSubmittingAttendance = false)
                ;
            }
        }

        get attendance() {
            return this.booking.booking.attendance;
        }

        get agenda(): IAgenda {
            return this.booking.agenda;
        }

        get client(): IClient {
            return this.booking.booking.client;
        }

        get animal(): IAnimal {
            return this.booking.booking.animal;
        }

        get createdDateTimeObject() {
            return DateTime.fromISO(this.booking.inserted_at, {zone: this.booking.timezone});
        }

        get startDateTimeObject() {
            return DateTime.fromJSDate(this.occurrenceStart, {zone: this.booking.timezone});
        }

        get endDateTimeObject() {
            return DateTime.fromJSDate(this.occurrenceEnd, {zone: this.booking.timezone});
        }

        get createdDate() {
            return this.createdDateTimeObject.toLocaleString({day: '2-digit', month: '2-digit'});
        }

        get createdTime() {
            return this.createdDateTimeObject.toLocaleString(DateTime.TIME_SIMPLE);
        }

        get startDate() {
            return this.startDateTimeObject.toLocaleString({weekday: 'long', day: 'numeric', month: 'long'});
        }

        get startTime() {
            return this.startDateTimeObject.toLocaleString(DateTime.TIME_SIMPLE);
        }

        get endTime() {
            return this.endDateTimeObject.toLocaleString(DateTime.TIME_SIMPLE);
        }

        get reason() {
            return this.booking.booking.reason;
        }

        get isPast() {
            return DateTime.now() > this.startDateTimeObject;
        }

        get animalDepositDate() {
            const startDate = DateTime.fromISO(this.booking.booking.deposit_date)
            .toLocaleString({weekday: 'long', day: 'numeric', month: 'long'});
            const startTime = DateTime.fromISO(this.booking.booking.deposit_date)
            .toLocaleString(DateTime.TIME_SIMPLE) as string;

            return startDate + ' à ' + startTime;
        }

        get editBookingParams(): IUpdateBookingParams|null {
            if (!this.editableBooking) {
                return null;
            }

            const params: IUpdateBookingParams = {
                booking_id: this.booking.booking.id,
                organization_id: this.loggedOrganization.id,
                agenda_id: (this.editableBooking.agenda as IAgenda).id,
                client_id: (this.editableBooking.client as IClient).id,
                reason_id: (this.editableBooking.reason as IReason).id,
                start: this.editableBooking.start,
                end: this.editableBooking.end,
            };

            if (this.editableBooking.animal) {
                params.animal_id = this.editableBooking.animal.id;
            }

            return params;
        }

        private onAbsentConfirmed() {
            this.isSubmittingAttendance = false;
        }

        private submitEditBooking() {
            this.isSubmittingBookingForm = true;

            const snackbarModule = getModule(SnackbarModule, this.$store);
            const params = this.editBookingParams;

            if (!params) {
                this.isSubmittingBookingForm = true;
                return;
            }

            getModule(EventModule, this.$store)
                .updateBooking(params)
                .then((booking: IBooking) => {
                    snackbarModule.displaySuccess('Votre rendez-vous a bien été modifié !');
                    this.isEditingBookingForm = false;
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isSubmittingBookingForm = false)
            ;
        }

        private startEditingBooking() {
            this.editableBooking = {
                event_id: this.booking.id,
                booking_id: this.booking.booking.id,
                agenda: this.agenda,
                animal: this.animal,
                client: this.client,
                reason: this.reason,
                start: this.booking.start,
                end: this.booking.end,
            };

            this.isEditingBookingForm = true;
        }
    }
