import Vue from 'vue';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';
import {DateTime} from 'luxon';
import { localStorageService } from '@/storage/localstorage.service';

import {
    AgendaViewValue,
    IAgenda,
    IAgendaGroup,
    IAgendasByGroup,
    ICreateAgendaParams,
    ISaveSynchronizedAgendasParams,
    ISynchronizedAgenda,
    IUpdateAgendaParams,
} from '@/types';

import {
    CLEAR_STATE,
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';

import {
    ADD_AGENDA,
    ADD_AGENDAS_TO_SELECTION,
    DELETE_AGENDA,
    DESELECT_AGENDAS,
    SELECT_AGENDA,
    SELECT_AGENDAS,
    SELECT_ALL_AGENDAS,
    SELECT_DATE,
    SELECT_VIEW,
    SET_AGENDAS,
    SET_GROUPS,
    TOGGLE_AGENDA,
    UPDATE_AGENDA,
} from '@/types/store/mutations/agenda.mutations';

@Module({
    namespaced: true,
    name: 'agenda',
})
export class AgendaModule extends VuexModule {
    public status: string|null = null;
    public agendas: IAgenda[] = [];
    public groups: IAgendaGroup[] = [];
    public selectedAgendas: IAgenda[] = localStorageService.loadObject('selected_agendas', []);
    public selectedDate: string = DateTime.local().toISODate() as string;
    public selectedView: AgendaViewValue = null;

    get agendasList(): IAgenda[] {
        return this.agendas;
    }

    get groupsList(): IAgendaGroup[] {
        return this.groups;
    }

    get agendasByGroup() {
        return this.agendas.reduce((carry: any, agenda: IAgenda) => {
            if(!agenda.groups) return;
            agenda.groups.forEach((group: IAgendaGroup) => {
                if (!carry[group.id as string]) {
                    carry[group.id as string] = {
                        name: group.name,
                        agendas: [],
                    };
                }

                carry[group.id as string].agendas.push(agenda.id as string);
            });

            return carry;
        }, {} as IAgendasByGroup);
    }

    get selectedAgendasList(): IAgenda[] {
        return this.selectedAgendas;
    }

    get selectedDateValue(): string {
        return this.selectedDate;
    }

    get selectedViewValue(): AgendaViewValue {
        return this.selectedView;
    }

    get requestStatus(): string|null {
        return this.status;
    }

    @Action({rawError: true})
    public async fetchAgendasGroups(organizationId: string): Promise<IAgendaGroup[]> {
        return new Promise<IAgendaGroup[]>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.agenda
                .listGroups(organizationId)
                .then((response: IAgendaGroup[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_GROUPS, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async fetchAgendas(organizationId: string): Promise<IAgenda[]> {
        return new Promise<IAgenda[]>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.agenda
                .list(organizationId)
                .then((response: IAgenda[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_AGENDAS, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async fetchSynchronizedAgendas(organizationId: string): Promise<ISynchronizedAgenda[]> {
        return new Promise<ISynchronizedAgenda[]>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.agenda
                .listSynchronized(organizationId)
                .then((response: ISynchronizedAgenda[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async deleteSynchronizedAgenda(
        {organizationId, synchronizedAgendaId}: {organizationId: string, synchronizedAgendaId: string},
    ): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.agenda
                .deleteSynchronized(organizationId, synchronizedAgendaId)
                .then(() => {
                    this.context.commit(REQUEST_SUCCESS);
                    resolve();
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async saveSynchronizedAgendas(
        {organizationId, data}: {organizationId: string, data: ISaveSynchronizedAgendasParams[]},
    ): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.agenda
                .saveSynchronized(organizationId, data)
                .then(() => {
                    this.context.commit(REQUEST_SUCCESS);
                    resolve();
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async createAgenda(params: ICreateAgendaParams): Promise<IAgenda> {
        return new Promise<IAgenda>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.agenda
                .create(params)
                .then((response: IAgenda) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(ADD_AGENDA, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async updateAgenda(params: IUpdateAgendaParams): Promise<IAgenda> {
        return new Promise<IAgenda>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.agenda
                .update(params)
                .then((response: IAgenda) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(UPDATE_AGENDA, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async deleteAgenda(
        {organizationId, agendaId}: {organizationId: string, agendaId: string},
    ): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.agenda
                .deleteAgenda(organizationId, agendaId)
                .then((response: boolean) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(DELETE_AGENDA, agendaId);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public selectAllAgendas() {
        this.context.commit(SELECT_ALL_AGENDAS);
    }

    @Action({rawError: true})
    public selectAgenda(agenda: IAgenda) {
        return new Promise<IAgenda>((resolve, reject) => {
            this.context.commit(SELECT_AGENDA, agenda);
            resolve(agenda);
        })
    }

    @Action({rawError: true})
    public selectAgendas(agendasIds: string[]) {
        const agendas = this.agendas.filter((agenda: IAgenda) => {
            if(agenda.id) return agendasIds.indexOf(agenda.id) > -1;
        });

        this.context.commit(SELECT_AGENDAS, agendas);
    }

    @Action({rawError: true})
    public toggleAgenda(agenda: IAgenda) {
        this.context.commit(TOGGLE_AGENDA, agenda);
    }

    @Action({rawError: true})
    public toggleAgendas(agendasIds: string[]) {
        const agendas = this.agendas.filter((agenda: IAgenda) => {
            if(agenda.id) return agendasIds.indexOf(agenda.id) > -1;
        });

        const agendasSelected = agendas.every((agenda: IAgenda) => {
            return this.selectedAgendas.indexOf(agenda) > -1;
        });

        if (agendasSelected) {
            this.context.commit(DESELECT_AGENDAS, agendas);
        } else {
            this.context.commit(ADD_AGENDAS_TO_SELECTION, agendas);
        }
    }

    @Action({rawError: true})
    public selectDate(date: string) {
        this.context.commit(SELECT_DATE, date);
    }

    @Action({rawError: true})
    public selectView(view: AgendaViewValue) {
        this.context.commit(SELECT_VIEW, view);
    }

    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
       this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [SET_AGENDAS](data: IAgenda[]) {
        this.agendas = data;
    }

    @Mutation
    private [SET_GROUPS](data: IAgendaGroup[]) {
        this.groups = data;
    }

    @Mutation
    private [ADD_AGENDA](data: IAgenda) {
        this.agendas.push(data);
    }

    @Mutation
    private [UPDATE_AGENDA](data: IAgenda) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.agendas.length; ++i) {
            if (this.agendas[i].id === data.id) {
                Vue.set(this.agendas, i, data);
                break;
            }
        }
    }

    @Mutation
    private [DELETE_AGENDA](data: string) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.agendas.length; ++i) {
            if (this.agendas[i].id === data) {
                this.agendas.splice(i, 1);
                break;
            }
        }
    }

    @Mutation
    private [SELECT_ALL_AGENDAS]() {
        this.selectedAgendas = this.agendas.map((agenda: IAgenda) => agenda);
        localStorageService.storeObject('selected_agendas', this.selectedAgendas);
    }

    @Mutation
    private [SELECT_AGENDA](data: IAgenda) {
        this.selectedAgendas = [data];
        localStorageService.storeObject('selected_agendas', this.selectedAgendas);
    }

    @Mutation
    private [SELECT_AGENDAS](data: IAgenda[]) {
        this.selectedAgendas = data;
        localStorageService.storeObject('selected_agendas', this.selectedAgendas);
    }

    @Mutation
    private [ADD_AGENDAS_TO_SELECTION](data: IAgenda[]) {
        data.forEach((agenda: IAgenda) => {
            if (this.selectedAgendas.indexOf(agenda) === -1) {
                this.selectedAgendas.push(agenda);
            }
        });

        localStorageService.storeObject('selected_agendas', this.selectedAgendas);
    }

    @Mutation
    private [DESELECT_AGENDAS](data: IAgenda[]) {
        this.selectedAgendas = this.selectedAgendas.filter((agenda: IAgenda) => {
            return data.indexOf(agenda) === -1;
        });

        if (this.selectedAgendas.length === 0 && this.agendas.length > 0) {
            this.selectedAgendas = [this.agendas[0]];
        }

        localStorageService.storeObject('selected_agendas', this.selectedAgendas);
    }

    @Mutation
    private [TOGGLE_AGENDA](data: IAgenda) {
        const index = this.selectedAgendas.indexOf(data);

        if (index === -1) {
            this.selectedAgendas = [...this.selectedAgendas, data];
        } else {
            this.selectedAgendas = this.selectedAgendas.filter((agenda: IAgenda) => agenda.id !== data.id);
        }

        localStorageService.storeObject('selected_agendas', this.selectedAgendas);
    }

    @Mutation
    private [SELECT_DATE](data: string) {
        this.selectedDate = data;
    }

    @Mutation
    private [SELECT_VIEW](data: AgendaViewValue) {
        this.selectedView = data;
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.status = null;
        this.agendas = [];
        this.groups = [];
        this.selectedAgendas = [];

        localStorageService.remove('selected_agendas');
    }
}
