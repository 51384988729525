import {
    IAccountResponse,
    IAccountRole,
    ICancelAccountJoinRequestParams,
    ICreateParaveterinarianData,
    ICreateVeterinarianData,
    IDisplaySettings,
    IOrganizationJoinRequest,
    IRefreshTokenParams,
    IRegisterData,
    IUpdateAccountData,
    IUpdateAccountJoinRequestParams,
    IUpdatePasswordData,
    IUser,
    IInvitation,
} from '@/types';

import AbstractService from './abstract.service';

export class AccountService extends AbstractService {
    public exists(email: string) {
        return new Promise<boolean>((resolve, reject) => {
            this.api({
                url: `/accounts/exists`,
                params: {
                    type: 'client',
                    email,
                },
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.exists);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public login(email: string, password: string) {
        return new Promise<IAccountResponse>((resolve, reject) => {
            this.api({
                url: `/authentication`,
                data: {
                    email,
                    password,
                    type: 'pro',
                },
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public impersonate() {
        return new Promise<IAccountResponse>((resolve, reject) => {
            this.api({
                url: `/authentication/impersonate`,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public refreshToken(params: IRefreshTokenParams) {
        return new Promise<IAccountResponse>((resolve, reject) => {
            this.api({
                url: `/authentication/refresh-token`,
                data: {refresh_token: params.refresh_token},
                method: 'POST',
                headers: {
                    'X-Auth-Failed-No-Retry': true,
                },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public verifyEmail(token: string) {
        return new Promise<IAccountResponse>((resolve, reject) => {
            this.api({
                url: `/accounts/verify-email`,
                data: {token},
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public resendVerificationEmail(accountId: string) {
        return new Promise<IAccountResponse>((resolve, reject) => {
            this.api({
                url: `/accounts/${accountId}/resend-verification-email`,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public authenticateWebsocket() {
        return new Promise<string>((resolve, reject) => {
            this.api({
                url: `/authentication/websocket`,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data.token);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public register(registerData: IRegisterData) {
        return new Promise<IAccountResponse>((resolve, reject) => {
            this.api({
                url: `/accounts`,
                data: registerData,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public createVeterinarian(createVeterinarianData: ICreateVeterinarianData) {
        return new Promise<IUser>((resolve, reject) => {
            this.api({
                url: `/veterinarians`,
                data: createVeterinarianData,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public createParaveterinarian(createParaveterinariantData: ICreateParaveterinarianData) {
        return new Promise<IUser>((resolve, reject) => {
            this.api({
                url: `/paraveterinarians`,
                data: createParaveterinariantData,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public updateAccount(updateAccountData: IUpdateAccountData, accountId: string) {
        return new Promise<IUser>((resolve, reject) => {
            this.api({
                url: `/accounts/${accountId}`,
                data: updateAccountData,
                method: 'PUT',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public deleteAccount(accountId: string) {
        return new Promise<boolean>((resolve, reject) => {
            this.api({
                url: `/accounts/${accountId}`,
                method: 'DELETE',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public sendResetPasswordLink(email: string) {
        return new Promise<boolean>((resolve, reject) => {
            this.api({
                url: `/accounts/send-reset-link`,
                data: {
                    email,
                },
                method: 'POST',
            })
                .then((response) => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public updatePassword(updatePasswordData: IUpdatePasswordData) {
        return new Promise<boolean>((resolve, reject) => {
            this.api({
                url: `/accounts/reset-password`,
                data: updatePasswordData,
                method: 'POST',
            })
                .then((response) => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public saveDisplaySettings(params: IDisplaySettings, accountId: string) {
        return new Promise<IDisplaySettings>((resolve, reject) => {
            this.api({
                url: `/accounts/${accountId}/display-settings`,
                data: params,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public getDisplaySettings(accountId: string) {
        return new Promise<IDisplaySettings>((resolve, reject) => {
            this.api({
                url: `/accounts/${accountId}/display-settings`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public listAccountRoles(accountId: string) {
        return new Promise<IAccountRole[]>((resolve, reject) => {
            this.api({
                url: `/accounts/${accountId}/roles`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.account_roles);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public deleteAccountRole(accountId: string, accountRole: IAccountRole) {
        return new Promise<void>((resolve, reject) => {
            this.api({
                url: `/accounts/${accountId}/roles/${accountRole.id}`,
                method: 'DELETE',
            })
                .then((response) => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public listAccountJoinRequests(accountId: string) {
        return new Promise<IOrganizationJoinRequest[]>((resolve, reject) => {
            this.api({
                url: `/accounts/${accountId}/join-requests`,
                method: 'GET',
                params: {
                    status: 'pending',
                    source: 'account',
                },
            })
                .then((response) => {
                    resolve(response.data.organization_join_requests);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public updateJoinRequest(params: IUpdateAccountJoinRequestParams) {
        return new Promise<IOrganizationJoinRequest>((resolve, reject) => {
            this.api({
                url: `/accounts/${params.account_id}/join-requests/${params.join_request_id}`,
                method: 'PUT',
                data: {status: params.status},
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public cancelJoinRequest(params: ICancelAccountJoinRequestParams) {
        return new Promise<boolean>((resolve, reject) => {
            this.api({
                url: `/accounts/${params.account_id}/join-requests/${params.join_request_id}`,
                method: 'DELETE',
            })
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public invitation(invitationId: string) {
        return new Promise<IInvitation>((resolve, reject) => {
            this.api({
                url: `/accounts/invitations/${invitationId}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
