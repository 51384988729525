import {
    IAgenda,
    IAgendaGroup,
    ICreateAgendaParams,
    ISaveSynchronizedAgendasParams,
    ISynchronizedAgenda,
    IUpdateAgendaParams,
} from '@/types';

import AbstractService from './abstract.service';

export class AgendaService extends AbstractService {
    public create(params: ICreateAgendaParams) {
        return new Promise<IAgenda>((resolve, reject) => {
            const organizationId = params.organization_id;
            delete (params as ICreateAgendaParams).organization_id;

            this.api({
                url: `/organizations/${organizationId}/agendas`,
                method: 'POST',
                data: params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public update(params: IUpdateAgendaParams) {
        return new Promise<IAgenda>((resolve, reject) => {
            const organizationId = params.organization_id;
            const agendaId = params.agenda_id;

            delete (params as IUpdateAgendaParams).organization_id;
            delete (params as IUpdateAgendaParams).agenda_id;

            this.api({
                url: `/organizations/${organizationId}/agendas/${agendaId}`,
                method: 'PUT',
                data: params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public deleteAgenda(organizationId: string, agendaId: string) {
        return new Promise<boolean>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/agendas/${agendaId}`,
                method: 'DELETE',
            })
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public list(organizationId: string) {
        return new Promise<IAgenda[]>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/agendas`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.agendas);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public listSynchronized(organizationId: string) {
        return new Promise<ISynchronizedAgenda[]>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/synchronized-agendas`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.synchronized_agendas);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public deleteSynchronized(organizationId: string, synchronizedAgendaId: string) {
        return new Promise<void>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/synchronized-agendas/${synchronizedAgendaId}`,
                method: 'DELETE',
            })
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public saveSynchronized(organizationId: string, params: ISaveSynchronizedAgendasParams[]) {
        return new Promise<void>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/synchronized-agendas`,
                method: 'POST',
                data: params,
            })
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public listGroups(organizationId: string) {
        return new Promise<IAgendaGroup[]>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/agendas-groups`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.agendas_groups);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }
}
