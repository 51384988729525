
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop, PropSync} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {SnackbarModule, EventModule} from '@/store/modules';

    import {
        IBooking,
        IOrganization,
        IUpdateBookingParams,
    } from '@/types';

    const organizationNamespace = namespace('organization');

    @Component<ConfirmAbsentDialog>({})
    export default class ConfirmAbsentDialog extends Vue {
        private isSubmittingAbsent: boolean = false;
        private isSubmittingAbsentExcused: boolean = false;

        @Prop({
            type: Object,
            required: true,
        })
        private booking!: IBooking;

        @PropSync('opened', {
            type: Boolean,
            default: () => false,
        })
        private openedSync!: boolean;

        @organizationNamespace.Getter('loggedOrganization')
        private loggedOrganization!: IOrganization;

        get isSubmitting() {
            return this.isSubmittingAbsent || this.isSubmittingAbsentExcused;
        }

        private sendCancelRequest(attendance: string) {
            if (attendance === 'absent') {
                this.isSubmittingAbsent = true;
            } else {
                this.isSubmittingAbsentExcused = true;
            }

            const snackbarModule = getModule(SnackbarModule, this.$store);
            const params: IUpdateBookingParams = {
                booking_id: this.booking.booking.id,
                organization_id: this.loggedOrganization.id,
                attendance,
            };

            getModule(EventModule, this.$store)
                .updateBooking(params)
                .then((booking: IBooking) => {
                    snackbarModule.displaySuccess('Le statut de présence de votre rendez-vous a bien été modifié !');
                    this.openedSync = false;
                    this.$emit('confirmed', attendance);
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => {
                    this.isSubmittingAbsent = true;
                    this.isSubmittingAbsentExcused = true;
                })
            ;
        }

    }
