
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {Prop} from 'vue-property-decorator';
    import {DateTime, DateTimeFormatOptions, LocaleOptions} from 'luxon';

    import {
        IBookingNotifications,
        INotification,
    } from '@/types';
    import {NotificationModule} from '@/store/modules';


    import BookingCard from '@/components/BookingCard.vue';

    @Component<BookingNotifications>({
        components: {
            BookingCard,
        },
    })
    export default class BookingNotifications extends Vue {
        public bookingCardIsOpen: boolean = false;

        @Prop({
            type: Object,
            required: true,
        })
        public bookingNotifications!: IBookingNotifications;

        get notificationIcon() {
            if (this.bookingNotifications.is_cancelled) {
                    return 'mdi-cancel';
            } else if (this.bookingNotifications.is_new) {
                return 'mdi-clock';
            } else if (this.bookingNotifications.is_moved) {
                    return 'mdi-calendar-edit';
            } if (this.bookingNotifications.new_files > 0) {
                    return 'mdi-paperclip';
            } if (this.bookingNotifications.new_messages > 0) {
                    return 'mdi-comment';
            }

            return '';
        }

        get commentTabIsOpen() {
            return this.bookingNotifications.new_messages  > 0
                ||  this.bookingNotifications.new_files  > 0
            ;
        }

        get bookingMovedItems() {
            const lines: string[] = [];

            this.bookingNotifications.notifications.forEach((notification: INotification) => {
                if (notification.type === 'booking_moved') {
                    lines.push(
                        `<span class="text-decoration-line-through">${this.formatShortDateTime(notification.origin.start)}</span>
                        &rarr; ${this.formatShortDateTime(notification.event.start)}`,
                    );
                }
            });

            return lines;
        }

        public datetimeFromTimestamp(timestamp: number): string {
            const formatOpts = {
                suppressMilliseconds: true,
            };

            const isoString = DateTime
                .fromSeconds(timestamp)
                .toISO(formatOpts)
            ;

            return this.formatShortDateTime(isoString as string);
        }

        public formatShortDateTime(date: string): string {
            const formatOpts: LocaleOptions & DateTimeFormatOptions = {
                day: 'numeric',
                month: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
            };

            return DateTime.fromISO(date).toLocaleString(formatOpts);
        }

        public formatDayTime(date: string): string {
            const formatOpts: LocaleOptions & DateTimeFormatOptions = {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                hour: 'numeric',
                minute: '2-digit',
            };

            return DateTime.fromISO(date).toLocaleString(formatOpts);
        }

        public notificationSeen() {
            const notificationModule = getModule(NotificationModule, this.$store);

            this.bookingNotifications.notifications.forEach((notification: INotification) => {
                notificationModule.notificationSeen(notification);
            });
        }

        public closeBookingCard() {
            this.bookingCardIsOpen = false;
        }

        public openModal() {
            this.bookingCardIsOpen = true;
        }
    }
