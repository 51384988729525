
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {LayoutModule} from '@/store/modules';

    const layoutNamespace = namespace('layout');

    @Component<TheDrawerClients>({})
    export default class TheDrawerClients extends Vue {
        @layoutNamespace.Getter('isDrawerLeftOpened')
        public isDrawerLeftOpened!: boolean;

        get opened() {
            return this.isDrawerLeftOpened;
        }

        set opened(value: boolean) {
            getModule(LayoutModule, this.$store).setDrawerLeftOpened(value);
        }
    }
