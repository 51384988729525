import {Channel} from 'phoenix';
import {getModule} from 'vuex-module-decorators';

import {store} from '@/store';

import UserSocket from '@/sockets/user.socket';

import {AccountModule, SnackbarModule} from '@/store/modules';

export default class ApplicationChannel {
    private channel!: Channel;

    get loggedAccount() {
        return store.getters['account/loggedAccount'];
    }

    constructor() {
        UserSocket
            .getInstance()
            .ensureConnected()
            .then((userSocket: UserSocket) => {
                userSocket
                    .channel(`application:pro`, {})
                    .then((channel: Channel) => {
                        this.channel = channel;
                        this.addEventHandlers();
                        this.channel.join();
                    })
                ;
            })
        ;
    }

    public leave() {
        if (this.channel) {
            this.channel.leave();
        }
    }

    private addEventHandlers() {
        this.channel.on('logout', () => {
            if (this.loggedAccount) {
                getModule(AccountModule, store).logout();
                getModule(SnackbarModule, store).displaySuccess('Veuillez vous reconnecter');
            }
        });

        this.channel.on('new_build', () => {
            getModule(SnackbarModule, store).displayUpdate();
        });
    }
}
