import { render, staticRenderFns } from "./BookingCommentsCard.vue?vue&type=template&id=3c0e4d16&"
import script from "./BookingCommentsCard.vue?vue&type=script&lang=ts&"
export * from "./BookingCommentsCard.vue?vue&type=script&lang=ts&"
import style0 from "./BookingCommentsCard.vue?vue&type=style&index=0&id=3c0e4d16&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports