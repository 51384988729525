
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {RouteRecord} from 'vue-router';

    import TheDrawerAgenda from '@/components/layout/TheDrawerAgenda.vue';
    import TheDrawerOrganization from '@/components/layout/TheDrawerOrganization.vue';
    import TheDrawerFaq from '@/components/layout/TheDrawerFaq.vue';
    import TheDrawerClients from '@/components/layout/TheDrawerClients.vue';

    import {IOrganization, IAccountRole} from '@/types';
    import {namespace} from 'vuex-class';

    const organizationNamespace = namespace('organization');
    const accountNamespace = namespace('account');

    @Component<TheDrawerLeft>({
        components: {
            TheDrawerAgenda,
            TheDrawerOrganization,
            TheDrawerFaq,
            TheDrawerClients,
        },
    })
    export default class TheDrawerLeft extends Vue {
        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @accountNamespace.Getter('loggedAccountRoles')
        public loggedAccountRoles!: IAccountRole[];

        get componentName() {
            return this.$route.matched.reduce((name: string|null, route: RouteRecord) => {
                if (this.loggedAccountRoles[0]?.role) {
                    switch (route.name) {
                        case 'account':
                            return 'TheDrawerOrganization';
    
                        case 'organization':
                            return 'TheDrawerOrganization';
    
                        case 'business-hours':
                            return 'TheDrawerAgenda';
    
                        case 'account-profile':
                            if (this.loggedAccountRoles[0]?.role.name !== 'organization_member') {
                                return 'TheDrawerOrganization';
                            }
    
                        case 'account-organizations':
                            if (this.loggedAccountRoles[0].role.name !== 'organization_member') {
                                return 'TheDrawerOrganization';
                            }
    
                        case 'faq':
                            if (this.loggedAccountRoles[0].role.name !== 'organization_member') {
                                return 'TheDrawerOrganization';
                            }
    
                        case 'terms':
                            if (this.loggedAccountRoles[0].role.name !== 'organization_member') {
                                return 'TheDrawerOrganization';
                            }
    
                        case 'clients':
                            if (this.loggedAccountRoles[0].role.name !== 'organization_member') {
                                return 'TheDrawerOrganization';
                            }
    
                        case 'home':
                            if (this.loggedOrganization && this.loggedOrganization.status === 'enabled') {
                                return 'TheDrawerAgenda';
                            }
    
                            return null;
                    }
                }

                return name;
            }, null);
        }
    }
