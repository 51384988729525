
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';

@Component<PhoneInput>({})
export default class PhoneInput extends Vue {
  @Prop({ type: String, default: '' }) label!: string;
  @Prop({ type: String, default: '' }) placeholder!: string;
  @Prop({ type: String, default: '' }) prefix!: string;
  @Prop({ type: String, default: 'both' }) typeOfPhone!: string;
  @Prop({ type: Boolean, default: false }) outlined!: boolean;
  @Prop({ type: Boolean, default: false }) dense!: boolean;
  @Prop({ type: Boolean, default: true }) required!: boolean;
  @Prop({ type: Boolean, default: false }) hideDetails!: boolean;

  public phone: any | null = null;
  public phoneRule: boolean | null = null;
  public regexBoth = /^([1-9])\d{8}$/;
  public regexCellphone = /^[67]\d{8}$/;
  public errorMsg: string = '';

  @Watch('phone')
  private handleChange() {
    let regex;
    let errorMsg;
    
    switch (this.typeOfPhone) {
      case 'both':
        regex = this.regexBoth;
        errorMsg = 'Le téléphone doit commencer par (1,2,3,4,5,6,7,8 ou 9) et doit être composé de 9 chiffres';
        break;
      case 'cellphone':
        regex = this.regexCellphone;
        errorMsg = 'Le téléphone doit commencer par 6 ou 7 et doit être composé de 9 chiffres';
        break;
      default:
        this.errorMsg = '';
        this.$emit('change', '');
        return;
    }
    
    if (!regex.test(this.phone)) {
      this.errorMsg = errorMsg;
      this.$emit('change', '');
    } else {
      this.$emit('change', '0' + this.phone);
      this.errorMsg = '';
    }
  }

  public normalizePhoneNumber(phoneNumber: string): string {
      if(!phoneNumber) return '';
      if (phoneNumber.startsWith("+33")) {
          return phoneNumber.replace("+33", "").replace(/\s+/g, "");
      }
      if (phoneNumber.startsWith("0")) {
        return phoneNumber.replace("0", "").replace(/\s+/g, "");
      }
      return phoneNumber.replace(/\s+/g, "");
  }
  public mounted() {
    if (this.placeholder) {
      this.phone=this.normalizePhoneNumber(this.placeholder);
    }

  }
}
