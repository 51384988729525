import {
    ISpecialty,
    ICreateOrganizationVeterinarianParams,
    IVeterinarian,
    IUpdateOrganizationVeterinarianParams,
    IPostVeterinarianPicturesParams,
    IPostVeterinarianPicturesResult,
    IDeleteVeterinarianPictureParams,
    IUpdateVeterinarianParams,
    IPostVeterinarianAvatarParams,
    IAvatar,
    IInviteOrganizationVeterinarianParams,
    IChangeOrganizationVeterinarianRoleParams,
} from '@/types';

import AbstractService from './abstract.service';

export class VeterinarianService extends AbstractService {
    public specialtiesList() {
        return new Promise<ISpecialty[]>((resolve, reject) => {
            this.api({
                url: `/veterinarians/specialties`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.specialties);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public createVeterinarian(params: ICreateOrganizationVeterinarianParams) {
        const organizationId = params.organization_id;

        delete (params as ICreateOrganizationVeterinarianParams).organization_id;

        return new Promise<IVeterinarian>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/veterinarians`,
                data: params,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public inviteVeterinarian(params: IInviteOrganizationVeterinarianParams) {
        const organizationId = params.organization_id;
        const veterinarianId = params.veterinarian_id;

        delete (params as IInviteOrganizationVeterinarianParams).organization_id;
        delete (params as IInviteOrganizationVeterinarianParams).veterinarian_id;

        return new Promise<IVeterinarian>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/veterinarians/${veterinarianId}/invite`,
                data: params,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public changeVeterinarianRole(params: IChangeOrganizationVeterinarianRoleParams) {
        const organizationId = params.organization_id;
        const veterinarianId = params.veterinarian_id;

        delete (params as IChangeOrganizationVeterinarianRoleParams).organization_id;
        delete (params as IChangeOrganizationVeterinarianRoleParams).veterinarian_id;

        return new Promise<IVeterinarian>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/veterinarians/${veterinarianId}/role`,
                data: params,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public updateVeterinarian(params: IUpdateOrganizationVeterinarianParams) {
        const organizationId = params.organization_id;
        const veterinarianId = params.veterinarian_id;

        delete (params as IUpdateOrganizationVeterinarianParams).organization_id;
        delete (params as IUpdateOrganizationVeterinarianParams).veterinarian_id;

        return new Promise<IVeterinarian>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/veterinarians/${veterinarianId}`,
                data: params,
                method: 'PUT',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public getVeterinarian(veterinarianId: string) {
        return new Promise<IVeterinarian>((resolve, reject) => {
            this.api({
                url: `/veterinarians/${veterinarianId}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public postPictures(params: IPostVeterinarianPicturesParams) {
        return new Promise<IPostVeterinarianPicturesResult>((resolve, reject) => {
            this.api({
                url: `/veterinarians/${params.veterinarian_id}/pictures`,
                method: 'POST',
                data: params.pictures,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public deletePicture(params: IDeleteVeterinarianPictureParams) {
        return new Promise<boolean>((resolve, reject) => {
            this.api({
                url: `/veterinarians/${params.veterinarian_id}/pictures/${params.picture_id}`,
                method: 'DELETE',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public updateLoggedVeterinarian(params: IUpdateVeterinarianParams) {
        return new Promise<IVeterinarian>((resolve, reject) => {
            this.api({
                url: `/veterinarians/${params.veterinarian_id}`,
                method: 'PUT',
                data: params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public postAvatar(params: IPostVeterinarianAvatarParams) {
        return new Promise<IAvatar>((resolve, reject) => {
            this.api({
                url: `/veterinarians/${params.veterinarian_id}/avatars`,
                data: params.avatar,
                method: 'POST',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
                ;
        });
    }
}
