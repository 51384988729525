
    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component<ViewMaintenance>({})
    export default class ViewMaintenance extends Vue {
      private image = {
        src: require('@/assets/home/s2.jpg'),
      };

    }
