import Vue from 'vue';
import {Duration} from 'luxon';

Vue.filter('duration', (value: number) => {
    if (!value) {
        return 'Non renseigné';
    }

    const duration = Duration.fromMillis(value);
    const minutes = duration.as('minutes');
    const hours = duration.as('hours');
    const days = duration.as('days');

    if (days % 1 === 0) {
        if (days > 1) {
            return `${days} jours`;
        } else {
            return `${days} jour`;
        }
    } else if (hours % 1 === 0) {
        if (days > 1) {
            return `${hours} heures`;
        } else {
            return `${hours} heure`;
        }
    } else if (minutes % 1 === 0) {
        if (days > 1) {
            return `${minutes} minutes`;
        } else {
            return `${minutes} minute`;
        }
    }
});
