import {Channel} from 'phoenix';
import {getModule} from 'vuex-module-decorators';

import {store} from '@/store';

import {
    IBusinessHour,
    IComment,
    IEvent,
} from '@/types';

import AccountSocket from '@/sockets/account.socket';

import {
    AccountModule,
    BusinessHourModule,
    EventModule,
} from '@/store/modules';

export default class AgendaUpdateChannel {
    private channel!: Channel;

    constructor(agendaId: string) {
        AccountSocket
            .getInstance()
            .ensureConnected()
            .then((accountSocket: AccountSocket) => {
                accountSocket
                    .channel(`agenda_update:${agendaId}`, {})
                    .then((channel) => {
                        this.channel = channel;
                        this.addEventHandlers();
                        this.channel.join();
                    })
                ;
            })
        ;
    }

    public leave() {
        if (this.channel) {
            this.channel.leave();
        }
    }

    private addEventHandlers() {
        this.channel.on('event_created', (message: IEvent) => {
            getModule(EventModule, store).addEventFromChannel(message);
        });

        this.channel.on('event_updated', (message: IEvent) => {
            getModule(EventModule, store).updateEventFromChannel(message);
        });

        this.channel.on('event_deleted', (message: IEvent) => {
            getModule(EventModule, store).deleteEventFromChannel(message.id);
        });

        this.channel.on('business_hour_created', (message: IBusinessHour) => {
            getModule(BusinessHourModule, store).addBusinessHourFromChannel(message);
        });

        this.channel.on('business_hour_updated', (message: IBusinessHour) => {
            getModule(BusinessHourModule, store).updateBusinessHourFromChannel(message);
        });

        this.channel.on('business_hour_deleted', (message: IBusinessHour) => {
            getModule(BusinessHourModule, store).deleteBusinessHourFromChannel(message.id);
        });

        this.channel.on('booking_note_created', (message: IComment) => {
            getModule(EventModule, store).addBookingNoteFromChannel(message);
        });
    }
}
