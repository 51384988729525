
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop, PropSync} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import ClientCard from '@/components/ClientCard.vue';

    import {SnackbarModule, EventModule} from '@/store/modules';

    import {
        IBooking,
        IOrganization,
        IBookingFile,
        IClient,
        ICreateBookingFilesParams,
        ICreateBookingFilesResult,
        ICreateBookingFilesError,
    } from '@/types';

    const organizationNamespace = namespace('organization');

    @Component<AddBookingComment>({
        components: {
            ClientCard,
        }
    })
    export default class AddBookingComment extends Vue {
        public confirmDeleteFileDialog: boolean = false;
        public isLoadingFiles: boolean = false;
        public isUploadingFiles: boolean = false;
        public isDeletingFile: boolean = false;
        public filesToUpload: File[] = [];
        public fileToDelete: IBookingFile|null = null;
        public notifyClient: boolean = false;
        public clientDialogIsOpen: boolean = false;

        get client(): IClient {
            return this.booking.booking.client;
        }

        private commentValid: boolean = false;
        private submittingComment: boolean = false;
        private comment: string|null = null;
        private commentRules = [
            (v: string) => !!v || 'Votre message ne doit pas être vide.',
        ];

        @Prop({
            type: Object,
            required: true,
        })
        private booking!: IBooking;

        @organizationNamespace.Getter('loggedOrganization')
        private loggedOrganization!: IOrganization;

        private get isLoading(): boolean {
            return this.isLoadingFiles || this.submittingComment;
        }

        private get isDisabled(): boolean {
            return !this.comment && this.filesToUpload.length === 0;
        }

        private submit() {
            if (this.comment) {
                this.commentBooking();
            }

            this.comment = '';

            if (this.filesToUpload.length > 0) {
                this.uploadFiles();
            }

            this.filesToUpload = [];
        }

        private commentBooking() {
            this.submittingComment = true;

            const eventModule = getModule(EventModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            eventModule
                .addBookingComment({
                    organization_id: this.loggedOrganization.id,
                    booking_id: this.booking.booking.id,
                    comment: this.comment as string,
                    notify_client: this.notifyClient && this.filesToUpload.length === 0,
                })
                .catch((error) => snackbarModule.displayError())
                .finally(() => this.submittingComment = false)
            ;
        }

        private uploadFiles() {
            this.isUploadingFiles = true;

            const formData = new FormData();

            this.filesToUpload.forEach((file: File, index: number) => {
                formData.append(`files[${index}]`, file);
            });

            if (this.notifyClient) {
                formData.append('notify_client', 'true');
            }

            const params: ICreateBookingFilesParams = {
                organization_id: this.loggedOrganization.id,
                booking_id: this.booking.booking.id,
                files: formData,
            };

            const eventModule = getModule(EventModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            eventModule
                .addBookingFiles(params)
                .then((response: ICreateBookingFilesResult) => {
                    if (response.errors.length > 0) {
                        const failedFiles =
                            response.errors.map((value: ICreateBookingFilesError) => {
                                return value.file;
                            })
                                .join(', ')
                        ;

                        snackbarModule.displayError(`Une erreur est survenue pendant l'envoi des fichiers suivant : ${failedFiles}`);
                    }

                    this.filesToUpload = [];
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isUploadingFiles = false)
            ;
        }
    }
