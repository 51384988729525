import Vue from 'vue';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';

import {
    IOrganizationRule,
    IUpdateOrganizationRuleParams,
    ISearchRulesParams,
    ISearchRulesResult,
    ISearchPublicRule,
    IDeleteOrganizationRuleParams,
} from '@/types';


import {
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';

import {
    SET_PUBLIC_RULES,
    SET_ENABLED_RULES,
    SET_DISABLED_RULES,
    SET_ENABLED_RULES_TOTAL,
    SET_DISABLED_RULES_TOTAL,
} from '@/types/store/mutations/rule.mutations';

@Module({
    namespaced: true,
    name: 'rule',
})
export class RuleModule extends VuexModule {
    public publicRules: IOrganizationRule[] = [];
    public enabledRules: IOrganizationRule[] = [];
    public disabledRules: IOrganizationRule[] = [];
    public enabledRulesTotal: number = 0;
    public disabledRulesTotal: number = 0;
    public status: string|null = null;

    get publicRulesList(): IOrganizationRule[] {
        return this.publicRules;
    }

    get enabledRulesList(): IOrganizationRule[] {
        return this.enabledRules;
    }

    get getEnabledRulesTotal(): number {
        return this.enabledRulesTotal;
    }

    get disabledRulesList(): IOrganizationRule[] {
        return this.disabledRules;
    }

    get getDisabledRulesTotal(): number {
        return this.disabledRulesTotal;
    }

    @Action({rawError: true})
    public async fetchRulesPublic(params: ISearchPublicRule): Promise<IOrganizationRule[]> {
        return new Promise<IOrganizationRule[]>((resolve, reject) => {
            (Vue.prototype as Vue).$api.rule
                .listPublicRules()
                .then((response: IOrganizationRule[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_PUBLIC_RULES, response);
                    resolve(response);
                })
                .catch((error: Error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async fetchRulesEnabled(params: ISearchRulesParams): Promise<IOrganizationRule[]> {
        return new Promise<IOrganizationRule[]>((resolve, reject) => {
            (Vue.prototype as Vue).$api.rule
                .listRules(params)
                .then((response: IOrganizationRule[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ENABLED_RULES_TOTAL, response);
                    this.context.commit(SET_ENABLED_RULES, response);
                    resolve(response);
                })
                .catch((error: Error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async fetchRulesDisabled(params: ISearchRulesParams): Promise<IOrganizationRule[]> {
        return new Promise<IOrganizationRule[]>((resolve, reject) => {
            (Vue.prototype as Vue).$api.rule
                .listRules(params)
                .then((response: IOrganizationRule[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_DISABLED_RULES, response);
                    this.context.commit(SET_DISABLED_RULES_TOTAL, response);
                    resolve(response);
                })
                .catch((error: Error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async createRule(params: IOrganizationRule): Promise<IOrganizationRule> {
        return new Promise<IOrganizationRule>((resolve, reject) => {
            (Vue.prototype as Vue).$api.rule
                .create(params)
                .then((response: IOrganizationRule) => {
                    this.context.commit(REQUEST_SUCCESS);
                    resolve(response);
                })
                .catch((error: Error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async deleteRule(params: IDeleteOrganizationRuleParams): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            (Vue.prototype as Vue).$api.rule
                .delete(params)
                .then(() => {
                    this.context.commit(REQUEST_SUCCESS);
                    resolve();
                })
                .catch((error: Error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async updateRule(params: IUpdateOrganizationRuleParams): Promise<IOrganizationRule> {
        return new Promise<IOrganizationRule>((resolve, reject) => {
            (Vue.prototype as Vue).$api.rule
                .update(params)
                .then((response: IOrganizationRule) => {
                    this.context.commit(REQUEST_SUCCESS);
                    resolve(response);
                })
                .catch((error: Error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Mutation
    private [SET_PUBLIC_RULES](data: IOrganizationRule[]) {
        this.publicRules = data;
    }

    @Mutation
    private [SET_ENABLED_RULES](data: IOrganizationRule[]) {
        data.forEach((element: IOrganizationRule) => {
            if (typeof element.used_variables === 'object') {
                for (const variable in element.used_variables) {
                    if (element.used_variables.hasOwnProperty(variable)) {
                        // tslint:disable-next-line:max-line-length
                        element.rule.issue_comment_title = element.rule.issue_comment?.replace(':?' + variable + ':?', element.used_variables[variable]);
                    }
                }
            }
        });

        this.enabledRules = data;
    }

    @Mutation
    private [SET_DISABLED_RULES](data: IOrganizationRule[]) {
        this.disabledRules = data;
    }

    @Mutation
    private [SET_ENABLED_RULES_TOTAL](data: ISearchRulesResult) {
        this.enabledRulesTotal = data.total;
    }

    @Mutation
    private [SET_DISABLED_RULES_TOTAL](data: ISearchRulesResult) {
        this.disabledRulesTotal = data.total;
    }

    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
        this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }
}
