import Vue from 'vue';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';

import {
    IAccount,
    IAccountResponse,
    IAccountRole,
    ICancelAccountJoinRequestParams,
    ICreateParaveterinarianData,
    ICreateVeterinarianData,
    IDisplaySettings,
    IOrganization,
    IOrganizationJoinRequest,
    IParaveterinarian,
    IRegisterData,
    IUpdateAccountData,
    IUpdateAccountJoinRequestParams,
    IUser,
    IVeterinarian,
} from '@/types';

import {localStorageService} from '@/storage/localstorage.service';

import {
    CLEAR_STATE,
    CLEAR_STATUS,
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';

import {
    ACCOUNT_UPDATED,
    ADD_ACCOUNT_ROLE,
    ADD_JOIN_REQUEST,
    DELETE_ACCOUNT_ROLE,
    JOIN_REQUEST_CANCELED,
    JOIN_REQUEST_UPDATED,
    PARAVETERINARIAN_CREATED,
    REFRESH_TOKEN_REQUEST,
    SET_DISPLAY_SETTINGS,
    SET_JOIN_REQUESTS,
    UPDATE_ACCOUNT_ROLE,
    UPDATE_ACCOUNT_STATUS,
    UPDATE_ORGANIZATION_STATUS,
    VETERINARIAN_CREATED,
    IMPERSONATE,
} from '@/types/store/mutations/account.mutations';

import {
    SET_ORGANIZATION,
} from '@/types/store/mutations/organization.mutations';

@Module({
    namespaced: true,
    name: 'account',
})
export class AccountModule extends VuexModule {
    private status: string|null = null;
    private refreshTokenRequest: Promise<IAccountResponse>|null = null;

    private veterinarian: IVeterinarian|null =
        localStorageService.loadObject('veterinarian');

    private paraveterinarian: IParaveterinarian|null =
        localStorageService.loadObject('paraveterinarian');

    private accountRoles: IAccountRole[] =
        localStorageService.loadObject('account_roles', []);

    private accountDisplaySettings: IDisplaySettings|null =
        localStorageService.loadObject('account_display_settings');

    private accountInvitations: IOrganizationJoinRequest[] =
        localStorageService.loadObject('account_invitations', []);

    private accountJoinRequests: IOrganizationJoinRequest[] =
        localStorageService.loadObject('account_join_requests', []);

    private account: IAccount|null =
        localStorageService.loadObject('account');

    private accessToken: string|null =
        localStorageService.load('access_token');

    private refreshToken: string|null =
        localStorageService.load('refresh_token');

    private impersonatorToken: string|null =
        localStorageService.load('impersonator_token');


    get isLoggedIn(): boolean {
        return !!this.refreshToken || !!this.impersonatorToken;
    }

    get isImpersonated(): boolean {
        return !!this.impersonatorToken;
    }

    get isValidated(): boolean {
        return !!this.account && this.account.status === 'validated';
    }

    get isPendingDetails(): boolean {
        return !!this.account && this.account.status === 'pending_details';
    }

    get isPendingValidation(): boolean {
        return !!this.account && this.account.status === 'pending_validation';
    }

    get loginStatus(): string|null {
        return this.status;
    }

    get loggedUser(): IUser|null {
        return this.isLoggedIn ?
             {
                account: this.account as IAccount,
                veterinarian: this.veterinarian,
                paraveterinarian: this.paraveterinarian,
                accountRoles: this.accountRoles,
            } :
            null
        ;
    }

    get loggedVeterinarian(): IVeterinarian|null {
        return this.veterinarian;
    }

    get isVeterinarian(): boolean {
        return !!this.veterinarian;
    }

    get loggedParaveterinarian(): IParaveterinarian|null {
        return this.paraveterinarian;
    }

    get loggedAccountRoles(): IAccountRole[] {
        return this.accountRoles;
    }

    get loggedAccountInvitations(): IOrganizationJoinRequest[] {
        return this.accountInvitations;
    }

    get loggedAccountJoinRequests(): IOrganizationJoinRequest[] {
        return this.accountJoinRequests;
    }

    get loggedAccount(): IAccount|null {
        return this.account;
    }

    get authToken(): string | null{
        return this.accessToken;
    }

    get impersonateToken() {
        return this.impersonatorToken;
    }

    get displaySettings(): IDisplaySettings {
        return this.accountDisplaySettings ?  this.accountDisplaySettings : {
            from: '08:00',
            to: '19:00',
            zoom: 2,
            grid: '00:30:00',
            days: [0],
            hide_cancelled: true,
        };
    }

    @Action({rawError: true})
    public async login({username, password}: {username: string, password: string}): Promise<IAccountResponse> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .login(username, password)
                .then((response: IAccountResponse) => {
                    this.context.commit(REQUEST_SUCCESS, response);

                    if (response.account_roles?.length === 1) {
                        const accountRole = response.account_roles[0];

                        if (accountRole.organization) {
                            this.context.commit(
                                `organization/${SET_ORGANIZATION}`,
                                accountRole.organization,
                                {root: true},
                            );
                        }
                    }

                    this.fetchDisplaySettings();

                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async impersonate({impersonator_token, impersonated_token}:
                             {impersonator_token: string; impersonated_token: string}): Promise<IAccountResponse> {
        return new Promise((resolve, reject) => {
            this.context.commit(IMPERSONATE, { impersonator_token, impersonated_token });
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .impersonate()
                .then((response: IAccountResponse) => {
                    this.context.commit(REQUEST_SUCCESS, response);

                    if (response.account_roles?.length === 1) {
                        const accountRole = response.account_roles[0];

                        if (accountRole.organization) {
                            this.context.commit(
                                `organization/${SET_ORGANIZATION}`,
                                accountRole.organization,
                                {root: true},
                            );
                        }
                    }

                    this.fetchDisplaySettings();

                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                });
        });
    }

    @Action({rawError: true})
    public async refreshAccessToken(): Promise<IAccountResponse> {
        if (!this.refreshTokenRequest) {
            this.context.commit(REFRESH_TOKEN_REQUEST, new Promise((resolve, reject) => {
                if (!this.refreshToken) {
                    return reject('Missing refresh token');
                }

                if (!this.loggedAccount) {
                    return reject('No logged account');
                }

                this.context.commit(REQUEST);

                (Vue.prototype as Vue).$api.account
                    .refreshToken({refresh_token: this.refreshToken, account_id: this.loggedAccount.id})
                    .then((response: IAccountResponse) => {
                        this.context.commit(REQUEST_SUCCESS, response);
                        resolve(response);
                    })
                    .catch((error) => {
                        const status = error.response?.status;
                        const errorValue = error.response?.data.error;

                        if (status === 401 && errorValue === 'refresh_token_expired') {
                            this.context.dispatch('account/logout', null, {root: true}).then(() => {
                                Vue.prototype.$router.push({name: 'home'});
                            });
                        }

                        this.context.commit(REQUEST_ERROR);
                        reject(error);
                    })
                    .finally(() => this.context.commit(REFRESH_TOKEN_REQUEST, null))
                ;
            }));
        }

        return this.refreshTokenRequest as Promise<IAccountResponse>;
    }

    @Action({rawError: true})
    public async register(registerData: IRegisterData): Promise<IAccountResponse> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .register(registerData)
                .then((response: IAccountResponse) => {
                    this.context.commit(REQUEST_SUCCESS, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async createVeterinarian(createVeterinarianData: ICreateVeterinarianData): Promise<IUser> {
        return new Promise((resolve, reject) => {
            if (!this.account) {
                return reject('You must be logged in');
            }

            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .createVeterinarian(createVeterinarianData)
                .then((response: IUser) => {
                    this.context.commit(VETERINARIAN_CREATED, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async createParaveterinarian(createParaveterinarianData: ICreateParaveterinarianData): Promise<IUser> {
        return new Promise((resolve, reject) => {
            if (!this.account) {
                return reject('You must be logged in');
            }

            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .createParaveterinarian(createParaveterinarianData)
                .then((response: IUser) => {
                    this.context.commit(PARAVETERINARIAN_CREATED, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async verifyEmail(token: string): Promise<IAccountResponse> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .verifyEmail(token)
                .then((response: IAccountResponse) => {
                    this.context.commit(REQUEST_SUCCESS, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async updateAccount(accountData: IUpdateAccountData): Promise<IUser> {
        return new Promise((resolve, reject) => {
            if (!this.account) {
                return reject('You must be logged in');
            }

            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .updateAccount(accountData, this.account.id)
                .then((response: IUser) => {
                    this.context.commit(ACCOUNT_UPDATED, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async deleteAccount(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (!this.account) {
                return reject('You must be logged in');
            }

            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .deleteAccount(this.account.id)
                .then((response: boolean) => {
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async fetchDisplaySettings(): Promise<IDisplaySettings> {
        return new Promise<IDisplaySettings>((resolve, reject) => {
            if (!this.loggedAccount) {
                return reject('no logged account');
            }

            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .getDisplaySettings(this.loggedAccount.id)
                .then((response: IDisplaySettings) => {
                    this.context.commit(SET_DISPLAY_SETTINGS, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async fetchAccountRoles(): Promise<IAccountRole[]> {
        return new Promise<IAccountRole[]>((resolve, reject) => {
            if (!this.loggedAccount) {
                return reject('no logged account');
            }

            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .listAccountRoles(this.loggedAccount.id)
                .then((response: IAccountRole[]) => {
                    this.context.commit(REQUEST_SUCCESS, {account_roles: response});
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async deleteAccountRole(params: IAccountRole): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!this.loggedAccount) {
                return reject('no logged account');
            }

            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .deleteAccountRole(this.loggedAccount.id, params)
                .then(() => {
                    this.context.commit(DELETE_ACCOUNT_ROLE, params.id);
                    resolve();
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async fetchAccountJoinRequests(): Promise<IOrganizationJoinRequest[]> {
        return new Promise<IOrganizationJoinRequest[]>((resolve, reject) => {
            if (!this.loggedAccount) {
                return reject('no logged account');
            }

            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .listAccountJoinRequests(this.loggedAccount.id)
                .then((response: IOrganizationJoinRequest[]) => {
                    this.context.commit(SET_JOIN_REQUESTS, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async saveDisplaySettings(
        {accountId, settings}: {
            accountId: string,
            settings: IDisplaySettings,
        },
    ): Promise<IDisplaySettings> {
        return new Promise<IDisplaySettings>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .saveDisplaySettings(settings, accountId)
                .then((response: IDisplaySettings) => {
                    this.context.commit(SET_DISPLAY_SETTINGS, response);

                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async updateJoinRequest(params: IUpdateAccountJoinRequestParams): Promise<IOrganizationJoinRequest> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .updateJoinRequest(params)
                .then((response: IOrganizationJoinRequest) => {
                    this.context.commit(JOIN_REQUEST_UPDATED, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async cancelJoinRequest(params: ICancelAccountJoinRequestParams): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .cancelJoinRequest(params)
                .then(() => {
                    this.context.commit(JOIN_REQUEST_CANCELED, params.join_request_id);
                    resolve(true);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public logout() {
        return new Promise<void>((resolve) => {
            this.context.commit(CLEAR_STATE);
            this.context.commit(`agenda/${CLEAR_STATE}`, null, {root: true});
            this.context.commit(`animal/${CLEAR_STATE}`, null, {root: true});
            this.context.commit(`businessHour/${CLEAR_STATE}`, null, {root: true});
            this.context.commit(`client/${CLEAR_STATE}`, null, {root: true});
            this.context.commit(`event/${CLEAR_STATE}`, null, {root: true});
            this.context.commit(`layout/${CLEAR_STATE}`, null, {root: true});
            this.context.commit(`notification/${CLEAR_STATE}`, null, {root: true});
            this.context.commit(`organization/${CLEAR_STATE}`, null, {root: true});
            this.context.commit(`paraveterinarian/${CLEAR_STATE}`, null, {root: true});
            this.context.commit(`reason/${CLEAR_STATE}`, null, {root: true});
            this.context.commit(`veterinarian/${CLEAR_STATE}`, null, {root: true});

            resolve();
        });
    }

    @Action
    public clearStatus() {
        this.context.commit(CLEAR_STATUS);
    }

    // Direct actions used by websockets

    @Action({rawError: true})
    public addAccountRoleFromChannel(params: IAccountRole) {
        this.context.commit(ADD_ACCOUNT_ROLE, params);
    }

    @Action({rawError: true})
    public updateAccountRoleFromChannel(params: IAccountRole) {
        this.context.commit(UPDATE_ACCOUNT_ROLE, params);
    }

    @Action({rawError: true})
    public deleteAccountRoleFromChannel(params: string) {
        this.context.commit(DELETE_ACCOUNT_ROLE, params);

        // TODO if loggedOrganization.id === accountRole.organization_id, log out
    }

    @Action({rawError: true})
    public updateAccountStatusFromChannel(params: {id: string, status: string}) {
        this.context.commit(UPDATE_ACCOUNT_STATUS, params);
    }

    @Action({rawError: true})
    public addOrganizationJoinRequestFromChannel(params: IOrganizationJoinRequest) {
        this.context.commit(ADD_JOIN_REQUEST, params);
    }

    @Action({rawError: true})
    public updateOrganizationJoinRequestFromChannel(params: {id: string, status: string}) {
        this.context.commit(JOIN_REQUEST_UPDATED, params);
    }


    @Action({rawError: true})
    public updateOrganizationStatusFromChannel(params: {id: string, status: string}) {
        this.context.commit(UPDATE_ORGANIZATION_STATUS, params);
    }

    // Mutations

    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS](data: IAccountResponse) {
        this.status = 'success';

        if (data.access_token) {
            this.accessToken = data.access_token;
            localStorageService.store('access_token', this.accessToken);
        }

        if (data.refresh_token) {
            this.refreshToken = data.refresh_token;
            localStorageService.store('refresh_token', this.refreshToken);
        }

        if (data.account) {
            this.account = data.account;
            localStorageService.storeObject('account', this.account);
        }

        if (data.veterinarian) {
            this.veterinarian = data.veterinarian;
            localStorageService.storeObject('veterinarian', this.veterinarian);
        }

        if (data.paraveterinarian) {
            this.paraveterinarian = data.paraveterinarian;
            localStorageService.storeObject('paraveterinarian', this.paraveterinarian);
        }

        if (data.account_roles) {
            this.accountRoles = data.account_roles;
            localStorageService.storeObject('account_roles', this.accountRoles);
        }

        if (data.join_requests) {
            this.accountInvitations = data.join_requests;
            localStorageService.storeObject('account_invitations', this.accountInvitations);
        }
    }

    @Mutation
    private [IMPERSONATE]({ impersonator_token, impersonated_token }:
                          { impersonator_token: string, impersonated_token: string }) {
        this.impersonatorToken = impersonator_token;
        localStorageService.store('impersonator_token', this.impersonatorToken);

        this.accessToken = impersonated_token;
        localStorageService.store('access_token', this.accessToken);
    }

    @Mutation
    private [UPDATE_ACCOUNT_STATUS]({id, status}: {id: string, status: string}) {
        if (this.account && this.account.id === id) {
            this.account.status = status;
            localStorageService.storeObject('account', this.account);
        }
    }

    @Mutation
    private [SET_JOIN_REQUESTS](data: IOrganizationJoinRequest[]) {
        this.accountJoinRequests = data;
        localStorageService.storeObject('account_join_requests', this.accountJoinRequests);
    }

    @Mutation
    private [ADD_ACCOUNT_ROLE](data: IAccountRole) {
        this.accountRoles.push(data);
        localStorageService.storeObject('account_roles', this.accountRoles);
    }

    @Mutation
    private [DELETE_ACCOUNT_ROLE](data: string) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.accountRoles.length; ++i) {
            if (this.accountRoles[i].id === data) {
                this.accountRoles.splice(i, 1);
                break;
            }
        }

        localStorageService.storeObject('account_roles', this.accountRoles);
    }

    @Mutation
    private [UPDATE_ACCOUNT_ROLE](data: IAccountRole) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.accountRoles.length; ++i) {
            if (this.accountRoles[i].id === data.id) {
                this.accountRoles[i] = data;
                break;
            }
        }

        localStorageService.storeObject('account_roles', this.accountRoles);
    }

    @Mutation
    private [UPDATE_ORGANIZATION_STATUS]({id, status}: {id: string, status: string}) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.accountRoles.length; ++i) {
            if (
                this.accountRoles[i].organization &&
                (this.accountRoles[i].organization as IOrganization).id === id
            ) {
                (this.accountRoles[i].organization as IOrganization).status = status;
            }
        }
    }

    @Mutation
    private [ADD_JOIN_REQUEST](data: IOrganizationJoinRequest) {
        if (data.source === 'organization') {
            this.accountInvitations.push(data);
            localStorageService.storeObject('account_invitations', this.accountInvitations);
        } else {
            this.accountJoinRequests.push(data);
            localStorageService.storeObject('account_join_requests', this.accountJoinRequests);
        }
    }

    @Mutation
    private [JOIN_REQUEST_UPDATED](data: IOrganizationJoinRequest) {
        if (data.source === 'organization') {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.accountInvitations.length; ++i) {
                if (this.accountInvitations[i].id === data.id) {
                    this.accountInvitations.splice(i, 1);
                    break;
                }
            }

            localStorageService.storeObject('account_invitations', this.accountInvitations);
        } else {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.accountJoinRequests.length; ++i) {
                if (this.accountJoinRequests[i].id === data.id) {
                    this.accountJoinRequests.splice(i, 1);
                    break;
                }
            }

            localStorageService.storeObject('account_join_requests', this.accountJoinRequests);
        }
    }

    @Mutation
    private [JOIN_REQUEST_CANCELED](data: string) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.accountJoinRequests.length; ++i) {
            if (this.accountJoinRequests[i].id === data) {
                this.accountJoinRequests.splice(i, 1);
                break;
            }
        }

        localStorageService.storeObject('account_join_requests', this.accountJoinRequests);
    }

    @Mutation
    private [VETERINARIAN_CREATED](data: IUser) {
        this.status = 'success';

        this.veterinarian = data.veterinarian;
        this.account = data.account;

        localStorageService.storeObject('veterinarian', this.veterinarian);
        localStorageService.storeObject('account',this.account);
    }

    @Mutation
    private [PARAVETERINARIAN_CREATED](data: IUser) {
        this.status = 'success';

        this.paraveterinarian = data.paraveterinarian;
        this.account = data.account;

        localStorageService.storeObject('paraveterinarian', this.paraveterinarian);
        localStorageService.storeObject('account', this.account);
    }

    @Mutation
    private [ACCOUNT_UPDATED](data: IAccount) {
        this.status = 'success';

        this.account = data;

        localStorageService.storeObject('account', this.account);
    }

    @Mutation
    private [SET_DISPLAY_SETTINGS](data: IDisplaySettings) {
        this.status = 'success';

        this.accountDisplaySettings = data;
        localStorageService.storeObject('account_display_settings', this.accountDisplaySettings);
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [REFRESH_TOKEN_REQUEST](promise: Promise<IAccountResponse> | null) {
        if (promise) {
            this.status = 'loading';
        }

        this.refreshTokenRequest = promise;
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.accessToken = null;
        this.account = null;
        this.accountDisplaySettings = null;
        this.accountInvitations = [];
        this.accountJoinRequests = [];
        this.accountRoles = [];
        this.impersonatorToken = null;
        this.paraveterinarian = null;
        this.refreshToken = null;
        this.status = null;
        this.veterinarian = null;

        localStorageService.remove('access_token');
        localStorageService.remove('account');
        localStorageService.remove('account_display_settings');
        localStorageService.remove('account_invitations');
        localStorageService.remove('account_join_requests');
        localStorageService.remove('account_roles');
        localStorageService.remove('impersonator_token');
        localStorageService.remove('paraveterinarian');
        localStorageService.remove('refresh_token');
        localStorageService.remove('veterinarian');
    }

    @Mutation
    private [CLEAR_STATUS]() {
        this.status = null;
    }
}
