
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Emit, Model, Prop, Watch} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {
        IAnimal,
        IFetchAnimalsParams,
        IOrganization,
        IClient,
        ISpecies,
    } from '@/types';

    import AnimalFormCard from '@/components/AnimalFormCard.vue';

    import {AnimalModule} from '@/store/modules';

    const animalNamespace = namespace('animal');
    const organizationNamespace = namespace('organization');

    @Component<AnimalSelect>({
        components: {
            AnimalFormCard,
        },
    })
    export default class AnimalSelect extends Vue {
        public isLoadingAnimals: boolean = false;

        public createAnimalDialog: boolean = false;
        public animalToCreate: IAnimal|null = null;

        public searchText: string|null = null;
        public innerModel: IAnimal|null = null;

        @animalNamespace.Getter('animalsList')
        public animalsList!: IAnimal[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @Prop({
            type: Boolean,
            required: false,
            default: false,
        })
        public allowNew!: boolean;

        @Prop({
            type: Array,
            required: false,
        })
        public species!: ISpecies[] | null;

        @Prop({
            type: Object,
            required: false,
        })
        public client!: IClient;

        @Model('input', {type: Object}) public readonly value!: IAnimal|null;

        @Watch('client', {immediate: true})
        public onClientChanged() {
            this.loadAnimals();
        }

        @Emit('input')
        public onAnimalChange() {
            return this.innerModel;
        }

        public loadAnimals() {
            const animalModule = getModule(AnimalModule, this.$store);

            if (this.client && this.client.id) {
                this.isLoadingAnimals = true;

                const params: IFetchAnimalsParams = {
                    organization_id: this.loggedOrganization.id,
                    client_id: this.client.id,
                    status: 'enabled',
                };

                if (this.species) {
                    params.species = this.species.map((species: ISpecies) => species.id);
                }

                animalModule
                    .fetchAnimals(params)
                    .then(() => this.isLoadingAnimals = false)
                ;
            } else {
                this.$nextTick(() => {
                    this.innerModel = null;
                });
            }
        }

        public openCreateAnimalForm() {
            (this.animalToCreate as IAnimal) = {
                client_id: this.client.id as string,
                is_sterilized: false as boolean,
                is_crossbreed: false as boolean,
            } as IAnimal;
            this.createAnimalDialog = true;
        }

        public setAnimal(value: IAnimal|null) {
            this.createAnimalDialog = false;
            this.innerModel = value;
            this.$emit('input', this.innerModel);
        }

        private data() {
            return {
                innerModel: this.value ? JSON.parse(JSON.stringify(this.value)) : null,
            };
        }
    }
