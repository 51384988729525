import {
    IOrganizationRule,
    IDeleteOrganizationInstructionParams,
    ISearchRulesParams,
    IUpdateOrganizationRuleParams,
} from '@/types';

import AbstractService from './abstract.service';

export class RuleService extends AbstractService {

    public listRules(params: ISearchRulesParams) {
        return new Promise<IOrganizationRule[]>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/rules`,
                method: 'GET',
                params,
            })
            .then((response) => {
                response.data.organization_rules.total = parseInt(response.headers.total, 10);
                resolve(response.data.organization_rules);
            })
            .catch((error) => {
                reject(error);
            });

        });
    }

    public listPublicRules() {
        return new Promise<IOrganizationRule[]>((resolve, reject) => {
            this.api({
                url: `/organizations/rules`,
                method: 'GET',
            })
            .then((response) => {
                resolve(response.data.rules);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public create(params: IOrganizationRule) {
        return new Promise<IOrganizationRule>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/rules`,
                method: 'POST',
                data: params,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public delete(params: IDeleteOrganizationInstructionParams) {
        return new Promise<IDeleteOrganizationInstructionParams>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/rules/${params.id}`,
                method: 'DELETE',
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public update(params: IUpdateOrganizationRuleParams) {
        return new Promise<IOrganizationRule>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/rules/${params.id}`,
                method: 'PUT',
                data: params,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }
}
