export const ACCOUNT_UPDATED = '_accountUpdated';
export const ADD_ACCOUNT_ROLE = '_addAccountRole';
export const ADD_JOIN_REQUEST = '_addOrganizationJoinRequest';
export const DELETE_ACCOUNT_ROLE = '_deleteAccountRole';
export const JOIN_REQUEST_CANCELED = '_joinRequestCanceled';
export const JOIN_REQUEST_UPDATED = '_joinRequestUpdated';
export const PARAVETERINARIAN_CREATED = '_paraveterinarianCreated';
export const REFRESH_TOKEN_REQUEST = '_refreshTokenRequest';
export const SET_ACCOUNT = '_setAccount';
export const SET_DISPLAY_SETTINGS = '_setDisplaySettings';
export const SET_JOIN_REQUESTS = '_setJoinRequests';
export const UPDATE_ACCOUNT_ROLE = '_updateAccountRole';
export const UPDATE_ACCOUNT_STATUS = '_updateAccountStatus';
export const UPDATE_ORGANIZATION_STATUS = '_updateOrganizationStatus';
export const VETERINARIAN_CREATED = '_veterinarianCreated';
export const IMPERSONATE = '_impersonate';
