
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {DateTime} from 'luxon';

    import {
        IAccount,
        IBooking,
        IComment,
        IOrganization,
    } from '@/types';

    import {EventModule} from '@/store/modules';

    const accountNamespace = namespace('account');
    const eventNamespace = namespace('event');
    const organizationNamespace = namespace('organization');

    import AddBookingNote from '@/components/forms/AddBookingNote.vue';

    @Component<BookingNotesCard>({
        components: {
            AddBookingNote,
        },
    })
    export default class BookingNotesCard extends Vue {
        public noteDialog: boolean = true;

        public isLoadingNotes: boolean = false;

        public confirmDeleteMsgDialog: boolean = false;
        public noteToDelete: IComment|null = null;
        public isDeletingMsg: boolean = false;

        public DATETIME_SHORT = DateTime.DATETIME_SHORT;

        @Prop({
            type: Object,
            required: true,
        })
        public booking!: IBooking;

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount;

        @eventNamespace.Getter('notesList')
        public notes!: IComment[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        private scrollToBottom() {
            this.scrollToBottomOf(this.$refs.notesContainer as HTMLElement);
        }

        private deleteMsg(comment: IComment) {
            this.noteToDelete = comment;
            this.confirmDeleteMsgDialog = true;
        }

        private sendDeleteRequest(comment: IComment) {
            const eventModule = getModule(EventModule, this.$store);
            eventModule
                .deleteNote({
                    organization_id: this.loggedOrganization.id,
                    booking_id: this.booking.booking.id,
                    comment_id: comment.id,
                })
                .then(() => {
                    this.isLoadingNotes = false;
                    this.confirmDeleteMsgDialog = false;
                })
                .catch(() => this.isLoadingNotes = false)
                .finally(() => this.isLoadingNotes = false)
            ;
        }

        private scrollToBottomOf(element: HTMLElement) {
            const cardText = this.$refs.cardText as HTMLElement;

            this.$nextTick(() => {
                cardText.scrollTo({
                    top: element.scrollHeight,
                    left: 0,
                    behavior: 'smooth',
                });
            });
        }

        private mounted() {
            this.isLoadingNotes = true;

            const eventModule = getModule(EventModule, this.$store);

            eventModule
                .listBookingNotes({
                    organization_id: this.loggedOrganization.id,
                    booking_id: this.booking.booking.id,
                })
                .finally(() => this.isLoadingNotes = false)
            ;
        }
    }
