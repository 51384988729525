import {
    ICreateClientParams,
    IDeleteClientParams,
    IClient,
    ISearchClientsParams,
    IUpdateClientParams,
    IFetchClientParams,
    ISearchClientsResult,
} from '@/types';

import AbstractService from './abstract.service';

export class ClientService extends AbstractService {
    public search(params: ISearchClientsParams) {
        const organizationId = params.organization_id;

        delete (params as ISearchClientsParams).organization_id;

        return new Promise<ISearchClientsResult>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/clients`,
                method: 'GET',
                params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public create(params: ICreateClientParams) {
        const organizationId = params.organization_id;

        delete (params as ICreateClientParams).organization_id;

        return new Promise<IClient>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/clients`,
                method: 'POST',
                data: params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public update(params: IUpdateClientParams) {
        const organizationId = params.organization_id;
        const clientId = params.client_id;

        delete (params as IUpdateClientParams).organization_id;
        delete (params as IUpdateClientParams).client_id;

        return new Promise<IClient>((resolve, reject) => {
            this.api({
                url: `/organizations/${organizationId}/clients/${clientId}`,
                method: 'PUT',
                data: params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public getClient(params: IFetchClientParams) {
        return new Promise<IClient>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/clients/${params.client_id}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public remove(params: IDeleteClientParams) {
        return new Promise<boolean>((resolve, reject) => {
            const organizationId = params.organization_id;
            const clientId = params.client_id;

            this.api({
                url: `/organizations/${organizationId}/clients/${clientId}`,
                method: 'DELETE',
            })
                .then((response) => {
                    resolve(true);
                })
                .catch((error) => {
                    if (error.response.data.error === 'client_already_deleted') {
                        resolve(true);
                    }

                    reject(error);
                })
            ;
        });
    }
}
