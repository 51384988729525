export const ADD_ORGANIZATION_JOIN_REQUEST = '_addOrganizationJoinRequest';
export const ADD_PARAVETERINARIAN = '_addParaveterinarian';
export const ADD_VETERINARIAN = '_addVeterinarian';
export const DELETE_ORGANIZATION_PARAVETERINARIAN = '_deleteOrganizationParaveterinarian';
export const DELETE_ORGANIZATION_PICTURE = '_deleteOrganizationPicture';
export const DELETE_ORGANIZATION_VETERINARIAN = '_deleteOrganizationVeterinarian';
export const DELETE_ORGANIZATION_VETERINARIAN_PICTURE = '_deleteOrganizationVeterinarianPictures';
export const SET_ORGANIZATION = '_setOrganization';
export const SET_ORGANIZATION_AVATAR = '_setOrganizationAvatar';
export const SET_ORGANIZATION_INVOICES = '_setOrganizationInvoices';
export const SET_ORGANIZATION_JOIN_REQUESTS_LIST = '_setOrganizationJoinRequestsList';
export const SET_ORGANIZATION_PARAVETERINARIANS_LIST = '_setOrganizationParaveterinarianList';
export const SET_ORGANIZATION_PICTURES = '_setOrganizationPictures';
export const SET_ORGANIZATION_SOCIALS = '_setOrganizationSocials';
export const SET_ORGANIZATION_SUBSCRIPTION = '_setOrganizationSubscription';
export const SET_ORGANIZATION_VETERINARIAN = '_setOrganizationVeterinarian';
export const SET_ORGANIZATION_VETERINARIANS_LIST = '_setOrganizationVeterinarianList';
export const SET_ORGANIZATION_VETERINARIAN_AVATAR = '_setOrganizationVeterinarianAvatar';
export const SET_ORGANIZATION_VETERINARIAN_PICTURES = '_setOrganizationVeterinarianPictures';
export const SET_TYPES = '_setTypes';
export const TYPES_REQUEST = '_typesRequest';
export const UPDATE_ORGANIZATION = '_updateOrganization';
export const UPDATE_ORGANIZATION_INVOICE = '_updateOrganizationInvoice';
export const UPDATE_ORGANIZATION_JOIN_REQUEST = '_updateOrganizationJoinRequest';
export const UPDATE_ORGANIZATION_PARAVETERINARIAN_ROLE = '_updateOrganizationParaveterinarianRole';
export const UPDATE_ORGANIZATION_VETERINARIAN = '_updateOrganizationVeterinarian';
export const UPDATE_ORGANIZATION_VETERINARIAN_ROLE = '_updateOrganizationVeterinarianRole';
export const UPDATE_SYNCHRONIZATION_ALLOWED = '_updateSynchronizationAllowed';
