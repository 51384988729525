import {Channel, Socket} from 'phoenix';
import Vue from 'vue';

import {IAccount} from '@/types';

import {store} from '@/store';

export default class AccountSocket {
    public static getInstance(): AccountSocket {
        if (!AccountSocket.instance) {
            AccountSocket.instance = new AccountSocket();
        }

        return AccountSocket.instance;
    }

    private static instance: AccountSocket;

    private account: IAccount|null = null;
    private socket: Socket|null = null;
    private socketPromise: Promise<AccountSocket>|null = null;

    private constructor() {}

    get loggedAccount() {
        return store.getters['account/loggedAccount'];
    }

    public async ensureConnected(onError?: (error: any) => void): Promise<AccountSocket> {
        // If we are connected with another account, disconnect and reconnect
        if (this.socket && this.account && this.account.id !== this.loggedAccount?.id) {
            return this.disconnect().then(() => this.ensureConnected(onError));
        }

        // If a Promise exists, use it
        if (this.socketPromise) {
            return this.socketPromise;
        }

        // If a socket exists, return
        if (this.socket) {
            return new Promise<AccountSocket>((resolve) => resolve(this));
        }

        this.socketPromise = new Promise<AccountSocket>((resolve, reject) => {
            (Vue.prototype as Vue).$api.account
                .authenticateWebsocket()
                .then((token: string) => {
                    this.socket = new Socket(
                        `${process.env.VUE_APP_WEBSOCKET_URL}/account-socket`,
                        {
                            params: {token},
                            logger: (kind: string, msg: string, data: any) =>
                                // tslint:disable-next-line:no-console
                                console.log(`Socket logger : ${kind}: ${msg}`, data),
                        },
                    );

                    this.socket.onError((error) => {
                        if (onError) {
                            onError(error);
                        }
                    });

                    this.socket.onOpen(() => {
                        this.socketPromise = null;
                        resolve(this);
                    });

                    this.socket.onClose((data: any) => {
                        if (data.code === 1000) {
                            return;
                        }

                        this.disconnect().then(() => this.ensureConnected(onError));
                    });

                    this.socket.connect();
                })
                .catch((error) => {
                    window.localStorage.clear();
                    this.socketPromise = null;
                    reject(error);
                })
            ;
        });

        return this.socketPromise;
    }

    public channel(topic: string, chanParams?: object): Promise<Channel> {
        return new Promise<Channel>((resolve, reject) => {
            if (this.socket) {
                resolve(this.socket.channel(topic, chanParams));
            } else {
                reject('Socket not connected');
            }
        });
    }

    public disconnect() {
        return new Promise<void>((resolve) => {
            if (this.socket) {
                this.socket.disconnect(
                    () => {
                        this.socket = null;
                        this.account = null;
                        resolve();
                    },
                    1000,
                    'User disconnected',
                );
            } else {
                resolve();
            }
        });
    }
}
