
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop, PropSync} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {SnackbarModule, EventModule} from '@/store/modules';

    import {
        IBooking,
        IOrganization,
        IUpdateBookingParams,
    } from '@/types';

    const organizationNamespace = namespace('organization');

    @Component<CancelBookingDialog>({})
    export default class CancelBookingDialog extends Vue {
        private isSubmittingClient: boolean = false;
        private isSubmittingPro: boolean = false;
        private isSubmittingProSms: boolean = false;

        @Prop({
            type: Object,
            required: true,
        })
        private booking!: IBooking;

        @PropSync('opened', {
            type: Boolean,
            default: () => false,
        })
        private openedSync!: boolean;

        @organizationNamespace.Getter('loggedOrganization')
        private loggedOrganization!: IOrganization;

        get isSubmitting() {
            return this.isSubmittingClient || this.isSubmittingPro;
        }

        private sendCancelRequest(status: string) {
            let notifyClient = false;

            switch (status) {
                case 'client':
                    this.isSubmittingClient = true;
                    break;

                case 'pro':
                    this.isSubmittingPro = true;
                    break;

                case 'proSms':
                    this.isSubmittingProSms = true;
                    notifyClient = true;
                    break;
            }

            const snackbarModule = getModule(SnackbarModule, this.$store);
            const params: IUpdateBookingParams = {
                booking_id: this.booking.booking.id,
                organization_id: this.loggedOrganization.id,
            };

            if (status === 'client') {
                params.attendance = 'absent_excused';
            } else {
                params.status = 'cancelled_by_pro';
                params.notify_client = notifyClient;
            }

            getModule(EventModule, this.$store)
                .updateBooking(params)
                .then((booking: IBooking) => {
                    snackbarModule.displaySuccess('Votre rendez-vous a bien été annulé !');
                    this.openedSync = false;
                    this.$emit('cancelled');
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => {
                    this.isSubmittingClient = false;
                    this.isSubmittingPro = false;
                    this.isSubmittingProSms = false;
                })
            ;
        }

    }
