
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';
    import {DateTime} from 'luxon';
    import {EventApi} from '@fullcalendar/common';

    import {
        IBooking,
    } from '@/types';

    import BookingCommentsCard from '@/components/booking/BookingCommentsCard.vue';
    import BookingNotesCard from '@/components/booking/BookingNotesCard.vue';
    import BookingFilesCard from '@/components/booking/BookingFilesCard.vue';
    import BookingSummaryCard from '@/components/booking/BookingSummaryCard.vue';

    @Component<BookingCard>({
        components: {
            BookingCommentsCard,
            BookingFilesCard,
            BookingNotesCard,
            BookingSummaryCard,
        },
    })
    export default class BookingCard extends Vue {
        public tab: number = 0;

        @Prop({
            type: Object,
            required: false,
        })
        public value!: EventApi;

        @Prop({
            type: Boolean,
            required: false,
        })
        public commentTabIsOpen!: boolean;

        // Utilisé depuis ClientCardBookings et Notification
        @Prop({
            type: Object,
            required: false,
        })
        public selectedBooking!: IBooking;

        get booking(): IBooking {
            if (this.value) {
                return this.value.extendedProps.event;
            } else {
                // Utilisé depuis ClientCardBookings et Notification
                return this.selectedBooking;
            }
        }

        get bookingStartDate(): Date {
            return DateTime.fromISO(this.booking.start).toJSDate();
        }

        get bookingEndDate(): Date {
            return DateTime.fromISO(this.booking.end).toJSDate();
        }

        get activeTabName(): string {
            switch (this.tab) {
                case 0:
                    return 'Résumé';
                case 1:
                    return 'Messages';
                case 2:
                    return 'Notes internes';
                default:
                    return '';
            }
        }

        public mounted() {
            if (this.commentTabIsOpen === true) {
                this.tab = 1;
            }
        }
    }
