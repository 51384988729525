import {store} from '@/store';
import _Vue, { VNode } from 'vue';

import {
    IAccountRole, IOrganization,
} from '@/types';

export default function RolePlugin(Vue: typeof _Vue, options?: any): void {
    const hasRole = (role: string): boolean => {
        const organization: IOrganization|null = store.getters['organization/loggedOrganization'];
        const accountRoles: IAccountRole[] = store.getters['account/loggedAccountRoles'];

        if (!organization) {
            return false;
        }

        return !!accountRoles.find((accountRole: IAccountRole) => {
            if (accountRole.organization?.id !== organization.id) {
                return false;
            }

            switch (role) {
                case 'owner':
                    return ['organization_owner'].indexOf(accountRole.role.name) > -1;

                case 'admin':
                    return [
                        'organization_owner',
                        'organization_admin',
                    ].indexOf(accountRole.role.name) > -1;

                case 'member':
                    return [
                        'organization_owner',
                        'organization_admin',
                        'organization_member',
                    ].indexOf(accountRole.role.name) > -1;

                default:
                    return false;
            }
        });
    };

    Vue.prototype.$hasRole = hasRole;

    Vue.directive('if-role', (el, binding, vnode) => {
        const behaviour = binding.modifiers.disable ? 'disable' : 'hide';
        const not = !!binding.modifiers.not;
        const role = binding.arg;

        if (!role) {
            throw new Error('missing role in if-role directive');
        }

        const ok = hasRole(role);

        // XOR operand
        if (not !== ok) {
            return;
        }

        if (behaviour === 'hide') {
            commentNode(el, vnode);
        } else if (behaviour === 'disable') {
            (el as any).disabled = true;
        }
    });

    /**
     * Create comment node
     *
     * @private
     * @author https://stackoverflow.com/questions/43003976/a-custom-directive-similar-to-v-if-in-vuejs#43543814
     */
    function commentNode(el: HTMLElement, vnode: VNode) {
        const comment = document.createComment(' ');

        Object.defineProperty(comment, 'setAttribute', {
            value: () => undefined,
        });

        vnode.text = ' ';
        vnode.elm = comment;
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.data) {
            vnode.data.directives = undefined;
        }

        if (vnode.componentInstance) {
            (vnode.componentInstance.$el as any) = comment;
        }

        if (el.parentNode) {
            el.parentNode.replaceChild(comment, el);
        }
    }
}
