
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Emit, Model, Prop} from 'vue-property-decorator';

    import {
        IAttendance,
    } from '@/types';

    @Component<AttendanceSelect>({})
    export default class AttendanceSelect extends Vue {
        public searchText: string|null = null;
        public innerModel: IAttendance|null = null;

        @Model('input', {type: String})
        public readonly value!: IAttendance|null;

        @Prop({
            type: Boolean,
            required: true,
        })
        public showAbsent!: boolean;

        @Emit('input')
        public onValueChange() {
            return this.innerModel;
        }

        public get items() {
            const items = [
                {
                    id: 'seen',
                    icon: 'mdi-account-check',
                    name: 'Vu',
                },
                {
                    id: 'waiting_room',
                    icon: 'mdi-account-clock',
                    name: `En salle d'attente`,
                },
                {
                    id: 'consultation',
                    icon: 'mdi-account-convert',
                    name: 'En consultation',
                },
                {
                    id: 'pending',
                    icon: 'mdi-account-question',
                    name: 'A renseigner',
                },
            ];

            if (this.showAbsent) {
                items.push({
                    id: 'absent',
                    icon: 'mdi-account-remove',
                    name: 'Absent',
                });
            }

            return items;
        }

        private data() {
            return {
                innerModel: this.value,
            };
        }
    }
