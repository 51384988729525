import {Channel} from 'phoenix';
import {getModule} from 'vuex-module-decorators';

import {store} from '@/store';

import {
    IOrganizationJoinRequest,
    IParaveterinarian,
    IVeterinarian,
} from '@/types';

import AccountSocket from '@/sockets/account.socket';

import {OrganizationModule} from '@/store/modules';

export default class OrganizationChannel {
    private channel!: Channel;

    constructor(organizationId: string) {
        AccountSocket
            .getInstance()
            .ensureConnected()
            .then((accountSocket: AccountSocket) => {
                accountSocket
                    .channel(`organization:${organizationId}`, {})
                    .then((channel: Channel) => {
                        this.channel = channel;
                        this.addEventHandlers();
                        this.channel.join();
                    })
                ;
            })
        ;
    }

    public leave() {
        if (this.channel) {
            this.channel.leave();
        }
    }

    private addEventHandlers() {
        this.channel.on('organization_join_request_created', (message: IOrganizationJoinRequest) => {
            getModule(OrganizationModule, store).addOrganizationJoinRequestFromChannel(message);
        });

        this.channel.on('organization_join_request_status_changed', (message: {id: string, status: string}) => {
            getModule(OrganizationModule, store).updateOrganizationJoinRequestFromChannel(message);
        });

        this.channel.on('paraveterinarian_created', (message: IParaveterinarian) => {
            getModule(OrganizationModule, store).addParaveterinarianFromChannel(message);
        });

        this.channel.on('paraveterinarian_deleted', ({id}: {id: string}) => {
            getModule(OrganizationModule, store).deleteParaveterinarianFromChannel(id);
        });

        this.channel.on('paraveterinarian_role_updated', (message: {id: string, role: string}) => {
            getModule(OrganizationModule, store).updateParaveterinarianRoleFromChannel(message);
        });

        this.channel.on(
            'synchronization_allowed_changed',
            ({synchronization_allowed}: {synchronization_allowed: boolean}) => {
            getModule(OrganizationModule, store).updateSynchronizationAllowed(synchronization_allowed);
        });

        this.channel.on('veterinarian_created', (message: IVeterinarian) => {
            getModule(OrganizationModule, store).addVeterinarianFromChannel(message);
        });

        this.channel.on('veterinarian_deleted', ({id}: {id: string}) => {
            getModule(OrganizationModule, store).deleteVeterinarianFromChannel(id);
        });

        this.channel.on('veterinarian_role_updated', (message: {id: string, role: string}) => {
            getModule(OrganizationModule, store).updateVeterinarianRoleFromChannel(message);
        });
    }
}
