import {Channel} from 'phoenix';
import {getModule} from 'vuex-module-decorators';

import {store} from '@/store';

import {
    IAccountRole,
    IOrganizationJoinRequest,
} from '@/types';

import AccountSocket from '@/sockets/account.socket';

import {
    AccountModule,
    NotificationModule,
    OrganizationModule,
} from '@/store/modules';

export default class AccountChannel {
    private channel!: Channel;

    constructor(accountId: string) {
        AccountSocket
            .getInstance()
            .ensureConnected()
            .then((accountSocket: AccountSocket) => {
                accountSocket
                    .channel(`account:${accountId}`, {})
                    .then((channel) => {
                        this.channel = channel;
                        this.addEventHandlers();
                        this.channel.join();
                    })
                ;
            })
        ;
    }

    public leave() {
        if (this.channel) {
            this.channel.leave();
        }
    }

    private addEventHandlers() {
        this.channel.on('account_role_created', (message: IAccountRole) => {
            getModule(AccountModule, store).addAccountRoleFromChannel(message);
        });

        this.channel.on('account_role_deleted', ({id}: {id: string}) => {
            const accountModule = getModule(AccountModule, store);
            const organizationModule = getModule(OrganizationModule, store);
            const accountRole = accountModule
                .loggedAccountRoles
                .find((value: IAccountRole) => value.id === id)
            ;

            accountModule.deleteAccountRoleFromChannel(id);

            if (accountRole?.organization?.id === organizationModule.loggedOrganization?.id) {
                organizationModule.selectOrganization(null);
            }
        });

        this.channel.on('account_status_changed', (message: {id: string, status: string}) => {
            getModule(AccountModule, store).updateAccountStatusFromChannel(message);
        });

        this.channel.on('account_role_updated', (message: IAccountRole) => {
            getModule(AccountModule, store).updateAccountRoleFromChannel(message);
        });

        this.channel.on('organization_join_request_created', (message: IOrganizationJoinRequest) => {
            getModule(AccountModule, store).addOrganizationJoinRequestFromChannel(message);
        });

        this.channel.on('organization_join_request_status_changed', (message: {id: string, status: string}) => {
            getModule(AccountModule, store).updateOrganizationJoinRequestFromChannel(message);
        });

        this.channel.on('organization_status_changed', (message: {id: string, status: string}) => {
            const organizationModule = getModule(OrganizationModule, store);

            getModule(AccountModule, store).updateOrganizationStatusFromChannel(message);

            if (message.id === organizationModule.loggedOrganization?.id) {
                organizationModule.updateOrganizationStatusFromChannel(message);
            }
        });

        this.channel.on('notification_seen', (message: {id: string}) => {
            getModule(NotificationModule, store).deleteNotification(message.id);
        });
    }
}
