
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop, PropSync} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {SnackbarModule, EventModule} from '@/store/modules';

    import {
        IBooking,
        IOrganization,
    } from '@/types';

    const organizationNamespace = namespace('organization');

    @Component<AddBookingNote>({})
    export default class AddBookingNote extends Vue {
        private commentValid: boolean = false;
        private submittingComment: boolean = false;
        private comment: string|null = null;
        private commentRules = [
            (v: string) => !!v || 'Votre note ne doit pas être vide.',
        ];

        @Prop({
            type: Object,
            required: true,
        })
        private booking!: IBooking;

        @organizationNamespace.Getter('loggedOrganization')
        private loggedOrganization!: IOrganization;

        private commentBooking() {
            this.submittingComment = true;

            const eventModule = getModule(EventModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            eventModule
                .addBookingNote({
                    organization_id: this.loggedOrganization.id,
                    booking_id: this.booking.booking.id,
                    comment: this.comment as string,
                })
                .then(() => {
                    snackbarModule.displaySuccess('Votre note a bien été ajoutée !');
                    this.comment = null;
                })
                .catch((error) => snackbarModule.displayError())
                .finally(() => this.submittingComment = false)
            ;
        }
    }
