
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {DateTime} from 'luxon';

    import {
        IBooking,
        IOrganization,
        IAccount,
        IBookingFile,
        IDeleteBookingFilesParams,
    } from '@/types';

    import {EventModule, SnackbarModule} from '@/store/modules';

    const accountNamespace = namespace('account');
    const organizationNamespace = namespace('organization');

    @Component<BookingFile>({})
    export default class BookingFile extends Vue {
        public imageUrl: string = '';

        public confirmDeleteFileDialog: boolean = false;
        public fileToDelete: IBookingFile|null = null;
        public isDeletingFile: boolean = false;

        public DATE_SHORT = DateTime.DATE_SHORT;
        public TIME_24_SIMPLE = DateTime.TIME_24_SIMPLE;

        @Prop({
            type: Object,
            required: true,
        })
        public file!: IBooking;

        @Prop({
            type: Object,
            required: true,
        })
        public booking!: IBooking;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount;

        public created() {
            this.$api.event
                .getBookingFile({
                    organization_id: this.loggedOrganization.id,
                    booking_id: this.booking.booking.id,
                    file_id: this.file.id,
                })
                .then((url: string) => {
                    this.imageUrl = url;
                });
        }

        public isRefent(commentAuthorId: string) {
            if (this.loggedAccount.id !== commentAuthorId) {
                return;
            }

            return 'is-referent';
        }

        private getFileLink(file: IBookingFile) {
            // TODO(#1): handle expiration date, fetch file link only if it has expired
            this.$api.event
                .getBookingFile({
                    organization_id: this.loggedOrganization.id,
                    booking_id: this.booking.booking.id,
                    file_id: file.id,
                })
                .then((url: string) => window.open(url, '_blank'))
            ;
        }

        private deleteFile(file: IBookingFile) {
            this.fileToDelete = file;
            this.confirmDeleteFileDialog = true;
        }

        private sendDeleteFileRequest() {
            if (!this.fileToDelete) {
                return;
            }

            this.isDeletingFile = true;

            const eventModule = getModule(EventModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            const params: IDeleteBookingFilesParams = {
                organization_id: this.loggedOrganization.id,
                booking_id: this.booking.booking.id,
                file_id: this.fileToDelete.id,
            };

            eventModule
                .deleteBookingFile(params)
                .then(() => snackbarModule.displaySuccess('Votre fichier a bien été supprimé !'))
                .catch(() => snackbarModule.displayError())
                .finally(() => {
                    this.fileToDelete = null;
                    this.isDeletingFile = false;
                })
            ;
        }
    }
