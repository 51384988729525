import Ajv, {ErrorObject} from "ajv";
import { 
    IClient, 
    IOrganization,
    IUndefinedParamsReq,
    IErrorParams
} from '@/types';
import {localStorageService} from '../storage/localstorage.service';

export const reqHelper = {
    handlerJson,
};

function handlerJson(schema: object, datas: IUndefinedParamsReq) {
    //to tcheck function
    datas.organization_id = undefined;

    const ajv = new Ajv();
    const validate = ajv.compile(schema);

    if (!validate(datas)) {
        const validParams: any = validate.errors;
        const callBackDatasStorage: IErrorParams[]|boolean[] = validParams.map((error: any) => {
            return getLocalStorageParams(datas, error?.params?.missingProperty);
        });

        if (callBackDatasStorage[0] === false) {
            return {errors: validate.errors};
        } else {
            return callBackDatasStorage;
        }
    } else {
        return datas;
    }
}

function getLocalStorageParams(datas: IUndefinedParamsReq, target: string) {
    const result: IUndefinedParamsReq = datas;

    switch(target) {
        case 'client_id':
            const localClient: IClient = localStorageService.loadObject('new_client');
            if (localClient) {
                result.client_id = localClient.id;
            } else {
                return false;
            }
            break;
        case 'organization_id':
            const localOrganization: IOrganization = localStorageService.loadObject('organization');
            if (localOrganization) {
                result.organization_id = localOrganization.id;
            } else {
                return false;
            }
            break;
    }

    return result;

}