
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {Model, Watch} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {DateTime, ToISOTimeOptions} from 'luxon';

    import {
        IAgenda,
        IAnimal,
        IBookingForm,
        IOrganization,
        IReason,
        IOrganizationInstruction,
        ISearchInstructionsParams,
    } from '@/types';

    import AnimalSelect from '@/components/forms/AnimalSelect.vue';
    import ClientAutocomplete from '@/components/forms/ClientAutocomplete.vue';
    import ClientCard from '@/components/ClientCard.vue';

    import {formHelper} from '@/helpers';

    import {
        InstructionModule,
    } from '@/store/modules';

    const agendaNamespace = namespace('agenda');
    const animalNamespace = namespace('animal');
    const organizationNamespace = namespace('organization');
    const reasonNamespace = namespace('reason');
    const instructionNamespace = namespace('instruction');

    @Component<BookingForm>({
        components: {
            AnimalSelect,
            ClientAutocomplete,
            ClientCard,
        },
    })
    export default class BookingForm extends Vue {
        public bookingFormValid: boolean = false;
        public startDateMenu: boolean = false;
        public clientDialogIsOpen: boolean = false;
        public instructionsIsFetched: boolean = false;

        public startDate: string|null = null;
        public startTime: string|null = null;
        public duration: number|null = null;
        public nbInstruction: number = 0;

        public innerModel!: IBookingForm;

        public agendaRules = formHelper.getEventAgendaRules();
        public startDateRules = formHelper.getEventStartDateRules();
        public startTimeRules = formHelper.getEventStartTimeRules();
        public durationRules = formHelper.getEventDurationRules();
        public clientRules = formHelper.getBookingClientRules();
        public reasonRules = formHelper.getBookingReasonRules();

        @agendaNamespace.Getter('agendasList')
        public agendasList!: IAgenda[];

        @animalNamespace.Getter('animalsList')
        public animalsList!: IAnimal[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @reasonNamespace.Getter('organizationReasonsEnabledList')
        public organizationReasonsEnabledList!: IReason[];

        @instructionNamespace.Getter('enabledInstructionsList')
        public instructionsEnabled!: IOrganizationInstruction[];

        @Model('input', {type: Object}) public readonly value!: IBookingForm;

        @Watch('innerModel', {deep: true})
        public onInnerModelChanged(val: IBookingForm) {
            this.$emit('input', val);
        }

        get startDateFormatted() {
            if (!this.startDate) {
                return null;
            }

            return DateTime
                .fromISO(this.startDate)
                .toLocaleString(DateTime.DATE_SHORT)
            ;
        }

        set startDateFormatted(value: string | null) {
            if (!value) {
                this.startDate = null;
            }
        }

        get findPostBookingInstructions() {
            return this.instructionsEnabled.filter((item: IOrganizationInstruction) => {
                    if (item.instruction.post_booking_instruction) {
                        return item;
                    }
                },
            );
        }

        get showCommentsFields() {
            return !this.innerModel.event_id && !this.innerModel.booking_id;
        }

        public onStartDateChange() {
            const dateTime = `${this.startDate}T${this.startTime}:00`;
            const formatOpts = {
                suppressMilliseconds: true,
            };

            this.innerModel.start = DateTime.fromISO(dateTime).toUTC().toISO(formatOpts) as string;
            this.innerModel.end = this.computeEndFromDuration() as string;

            this.startDateMenu = false;
        }

        public onStartTimeChange() {
            const dateTime = `${this.startDate}T${this.startTime}:00`;
            const formatOpts = {
                suppressMilliseconds: true,
            };

            this.innerModel.start = DateTime.fromISO(dateTime).toUTC().toISO(formatOpts) as string;
            this.innerModel.end = this.computeEndFromDuration() as string;
        }

        public onDurationChange() {
            if (this.duration && this.innerModel.start) {
                this.innerModel.end = this.computeEndFromDuration() as string;
            }
        }

        public onReasonChanged() {
            if (this.innerModel.reason) {
                this.duration = this.innerModel.reason.duration;
                this.innerModel.end = this.computeEndFromDuration() as string;
                this.fetchInstructions();
            }
        }

        public fetchInstructions() {
            this.instructionsIsFetched = false;

            const paramsEnabled: ISearchInstructionsParams = {
                organization_id: this.loggedOrganization.id,
                status: 'enabled',
                agenda_id: this.innerModel.agenda?.id,
                reason_id: this.innerModel.reason?.id,
            };

            const instructionsEnabled = getModule(InstructionModule, this.$store)
            .fetchInstructionsEnabled(paramsEnabled);

            return Promise.all([instructionsEnabled])
                .then(() => {
                    this.instructionsIsFetched = true;
                },
            );
        }

        private data() {
            return {
                innerModel: Object.assign({}, this.value),
            };
        }

        private created() {
            if (this.innerModel.start) {
                this.startDate = this.extractDateFromStart();
                this.startTime = this.extractTimeFromStart();

                if (this.innerModel.end) {
                    this.duration = this.computeDuration();
                }
            }

            if (!this.innerModel.reason) {
                const defaultReason =
                    this.organizationReasonsEnabledList
                        .find((reason: IReason) => reason.name.toLowerCase() === 'consultation')
                ;

                if (defaultReason) {
                    this.innerModel.reason = defaultReason;
                }
            }

            if (this.innerModel.client) {
                if (!this.innerModel.client.full_name) {
                    this.innerModel.client.full_name =
                        `${this.innerModel.client.first_name ?? ''} ${this.innerModel.client.last_name}`.trim();
                }
            }
        }

        private computeDuration() {
            const start = DateTime.fromISO(this.innerModel.start);
            const end = DateTime.fromISO(this.innerModel.end);

            return end.diff(start, 'minutes').minutes;
        }

        private computeEndFromDuration() {
            const start = DateTime.fromISO(this.innerModel.start);
            const formatOpts: ToISOTimeOptions = {
                suppressMilliseconds: true,
            };

            return start.plus({minutes: this.duration as number}).toUTC().toISO(formatOpts);
        }

        private extractDateFromStart() {
            return DateTime
                .fromISO(this.innerModel.start)
                .toISODate()
            ;
        }

        private extractTimeFromStart() {
            const formatOpts: ToISOTimeOptions = {
                suppressMilliseconds: true,
                suppressSeconds: true,
                includeOffset: false,
            };

            return DateTime
                .fromISO(this.innerModel.start)
                .set({second: 0, millisecond: 0})
                .toISOTime(formatOpts)
            ;
        }
    }
