import _Vue from 'vue';

import {store} from '@/store';

import AccountChannel from './account.channel';
import AccountSocket from './account.socket';
import AgendaNotificationChannel from './agenda-notification.channel';
import AgendaUpdateChannel from './agenda-update.channel';
import ApplicationChannel from './application.channel';
import OrganizationChannel from './organization.channel';
import UserSocket from './account.socket';

import {
    IAgenda,
} from '@/types';

export default class SocketHandler {
    public static getInstance(): SocketHandler {
        if (!SocketHandler.instance) {
            SocketHandler.instance = new SocketHandler();
        }

        return SocketHandler.instance;
    }

    private static instance: SocketHandler;

    private accountChannel: AccountChannel|null = null;
    private accountSocket: AccountSocket|null = null;
    private agendasNotificationsChannels: Map<string, AgendaNotificationChannel> = new Map();
    private agendasUpdatesChannels: Map<string, AgendaUpdateChannel> = new Map();
    private applicationChannel: ApplicationChannel|null = null;
    private errors: number = 0;
    private organizationChannel: OrganizationChannel|null = null;
    private userSocket: UserSocket|null = null;

    private get loggedAccount() {
        return store.getters['account/loggedAccount'];
    }

    private get loggedOrganization() {
        return store.getters['organization/loggedOrganization'];
    }

    private get agendasList() {
        return store.getters['agenda/agendasList'];
    }

    private get selectedAgendasList() {
        return store.getters['agenda/selectedAgendasList'];
    }

    private constructor() {
        this.initWatchers();
    }

    public getAgendaNotificationChannel(agendaId: string): AgendaNotificationChannel|null {
        return this.agendasNotificationsChannels.get(agendaId) ?? null;
    }

    private initSocketAndChannels() {
        this.errors = 0;

        UserSocket
            .getInstance()
            .ensureConnected()
            .then((userSocket: UserSocket) => {
                this.userSocket = userSocket;
                this.applicationChannel = new ApplicationChannel();
            })
        ;

        this.disconnectSocket()
            .then(() => {
                if (!this.loggedAccount) {
                    return;
                }

                AccountSocket
                    .getInstance()
                    .ensureConnected(() => {
                        // tslint:disable-next-line:no-console
                        console.log('Errors count: ' + (this.errors + 1));
                        if (++this.errors > 5) {
                            // tslint:disable-next-line:no-console
                            console.log('More than 5 errors, recreating socket');
                            this.initSocketAndChannels();
                        }
                    })
                    .then((accountSocket: AccountSocket) => {
                        this.accountSocket = accountSocket;
                        this.accountChannel = new AccountChannel(this.loggedAccount.id);

                        if (this.loggedOrganization) {
                            this.organizationChannel = new OrganizationChannel(this.loggedOrganization.id);
                        }

                        if (this.agendasList) {
                            this.agendasList.forEach((el: IAgenda) => {
                                if (!el.id) return;
                                this.agendasNotificationsChannels.set(
                                    el.id,
                                    new AgendaNotificationChannel(el.id),
                                );
                            });
                        }

                        if (this.selectedAgendasList) {
                            this.selectedAgendasList.forEach((el: IAgenda) => {
                                if (!el.id) return;
                                this.agendasUpdatesChannels.set(
                                    el.id,
                                    new AgendaUpdateChannel(el.id),
                                );
                            });
                        }
                    })
                    .catch((error) => {
                        if (error.response.status !== 498) {
                            setTimeout(() => this.initSocketAndChannels(), 1000);
                        }
                    });
            });
    }

    private leaveChannels() {
        this.destroyOrganizationChannel();
        this.destroyAccountChannel();

        this.agendasNotificationsChannels.forEach((channel: AgendaNotificationChannel) => {
            channel.leave();
        });

        this.agendasUpdatesChannels.forEach((channel: AgendaUpdateChannel) => {
            channel.leave();
        });

        this.agendasUpdatesChannels.clear();
    }

    private destroyOrganizationChannel() {
        if (this.organizationChannel) {
            this.organizationChannel.leave();
            this.organizationChannel = null;
        }
    }

    private destroyAccountChannel() {
        if (this.accountChannel) {
            this.accountChannel.leave();
            this.accountChannel = null;
        }
    }

    private disconnectSocket() {
        return new Promise<void>((resolve, reject) => {
            if (this.accountSocket) {
                this.leaveChannels();
                this.accountSocket
                    .disconnect()
                    .then(() => resolve())
                    .catch(() => reject())
                ;
            } else {
                resolve();
            }
        });
    }

    private initWatchers() {
        // Set up watchers
        store.watch(
            (state, getters) => getters['account/loggedAccount'],
            (newValue, oldValue) => {
                if (oldValue && !newValue) {
                    // Disconnection
                    this.disconnectSocket();
                } else if (oldValue && newValue) {
                    // Disconnect and reconnect only if account id changed
                    if (oldValue.id !== newValue.id) {
                        this.initSocketAndChannels();
                    }
                } else if (!oldValue && newValue) {
                    // New connection
                    this.initSocketAndChannels();
                }
            },
            {immediate: true},
        );

        store.watch(
            (state, getters) => getters['organization/loggedOrganization'],
            (newValue, oldValue) => {
                if (!this.accountSocket) {
                    return;
                }

                if (oldValue && !newValue) {
                    // Disconnection
                    this.destroyOrganizationChannel();
                } else if (oldValue && newValue) {
                    // Disconnect and reconnect only if account id changed
                    if (oldValue.id !== newValue.id) {
                        this.destroyOrganizationChannel();
                        this.organizationChannel = new OrganizationChannel(newValue.id);
                    }
                } else if (!oldValue && newValue) {
                    // New connection
                    this.organizationChannel = new OrganizationChannel(newValue.id);
                }
            },
        );

        store.watch(
            (state, getters) => getters['agenda/agendasList'],
            (newValue, oldValue) => {
                if (oldValue) {
                    oldValue
                        .filter((agenda: IAgenda) => {
                            return !(newValue && newValue.find((val: IAgenda) => val.id === agenda.id));
                        })
                        .forEach((agenda: IAgenda) => {
                            if(!agenda.id) return;
                            if (this.agendasNotificationsChannels.has(agenda.id)) {
                                this.agendasNotificationsChannels.get(agenda.id)?.leave();
                                this.agendasNotificationsChannels.delete(agenda.id);
                            }
                        })
                    ;
                }

                if (newValue) {
                    newValue.forEach((agenda: IAgenda) => {
                        if(!agenda.id) return;
                        if (!this.agendasNotificationsChannels.has(agenda.id)) {
                            this.agendasNotificationsChannels.set(
                                agenda.id,
                                new AgendaNotificationChannel(agenda.id),
                            );
                        }
                    });
                }
            },
        );

        store.watch(
            (state, getters) => getters['agenda/selectedAgendasList'],
            (newValue, oldValue) => {
                if (oldValue) {
                    oldValue
                        .filter((agenda: IAgenda) => {
                            return !(newValue && newValue.find((val: IAgenda) => val.id === agenda.id));
                        })
                        .forEach((agenda: IAgenda) => {
                            if(!agenda.id) return;
                            if (this.agendasUpdatesChannels.has(agenda.id)) {
                                this.agendasUpdatesChannels.get(agenda.id)?.leave();
                                this.agendasUpdatesChannels.delete(agenda.id);
                            }
                        })
                    ;
                }

                if (newValue) {
                    newValue.forEach((agenda: IAgenda) => {
                        if(!agenda.id) return;
                        if (!this.agendasUpdatesChannels.has(agenda.id)) {
                            this.agendasUpdatesChannels.set(
                                agenda.id,
                                new AgendaUpdateChannel(agenda.id),
                            );
                        }
                    });
                }
            },
        );
    }
}
