import {
    IOrganizationInstruction,
    IUpdateOrganizationInstructionParams,
    ISearchParams,
    ISearchInstructionsParams,
    IPostInstructionPicturesParams,
    IPostInstructionPicturesResult,
    IDeleteInstructionPictureParams,
    IDeleteOrganizationInstructionParams,
} from '@/types';

import AbstractService from './abstract.service';

export class InstructionService extends AbstractService {

    public create(params: IOrganizationInstruction) {
        return new Promise<IOrganizationInstruction>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/instructions`,
                method: 'POST',
                data: params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public listPublicInstructions(params: ISearchParams) {
        return new Promise<IOrganizationInstruction[]>((resolve, reject) => {
            this.api({
                url: `/organizations/instructions`,
                method: 'GET',
                data: params,
            })
                .then((response) => {
                    resolve(response.data.instructions);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public listInstructions(params: ISearchInstructionsParams) {
        return new Promise<IOrganizationInstruction[]>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/instructions`,
                method: 'GET',
                params,
            })
            .then((response) => {
                response.data.organization_instructions.total = parseInt(response.headers.total, 10);
                resolve(response.data.organization_instructions);
            })
            .catch((error) => {
                reject(error);
            });

        });
    }

    public update(params: IUpdateOrganizationInstructionParams) {
        return new Promise<IOrganizationInstruction>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/instructions/${params.id}`,
                method: 'PUT',
                data: params,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
            ;
        });
    }

    public delete(params: IDeleteOrganizationInstructionParams) {
        return new Promise<IDeleteOrganizationInstructionParams>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/instructions/${params.id}`,
                method: 'DELETE',
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
            ;
        });
    }

    public postPictures(params: IPostInstructionPicturesParams) {
        return new Promise<IPostInstructionPicturesResult>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/instructions-files`,
                method: 'POST',
                data: params.files,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public deletePicture(params: IDeleteInstructionPictureParams) {
        return new Promise<boolean>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/instructions-files/${params.picture_id}`,
                method: 'DELETE',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

}
