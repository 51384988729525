export const ADD_BOOKING_COMMENT = '_addBookingComment';
export const ADD_BOOKING_FILES = '_addBookingFiles';
export const ADD_BOOKING_NOTE = '_addBookingNote';
export const ADD_BOOKING_NOTE_STRING = '_addBookingNoteString';
export const ADD_EVENT = '_addEvent';
export const DELETE_BOOKING_FILE = '_deleteBookingFile';
export const DELETE_EVENT = '_deleteEvent';
export const DELETE_COMMENT = '_deleteComment';
export const DELETE_NOTE = '_deleteNote';
export const SET_BOOKING_COMMENTS = '_setBookingComments';
export const SET_BOOKING_FILES = '_setBookingFiles';
export const SET_BOOKING_NOTES = '_setBookingNotes';
export const SET_BOOKING_SMS = '_setBookingSms';
export const SET_EVENTS = '_setEvents';
export const SET_CLIENT_BOOKINGS = '_setClientBookings';
export const UPDATE_EVENT = '_updateEvent';
