
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {
        LayoutModule,
        NotificationModule,
    } from '@/store/modules';

    const layoutNamespace = namespace('layout');
    const accountNamespace = namespace('account');
    const organizationNamespace = namespace('organization');
    const notificationNamespace = namespace('notification');

     import {
        IOrganization,
        INotification,
    } from '@/types';

    import BookingNotifications from '@/components/BookingNotifications.vue';

    @Component<TheDrawerNotification>({
        components: {
            BookingNotifications,
        },
    })
    export default class TheDrawerNotification extends Vue {
        @layoutNamespace.Getter('isDrawerNotificationOpened')
        public isDrawerNotificationOpened!: boolean;

        @accountNamespace.Getter('isLoggedIn')
        public isLoggedIn!: boolean;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @notificationNamespace.Getter('notificationsList')
        public notificationsList!: INotification[];

        @notificationNamespace.Getter('notificationsFilteredByAgenda')
        public notificationsByAgenda!: object;

        get opened() {
            return this.isDrawerNotificationOpened;
        }

        set opened(value: boolean) {
            getModule(LayoutModule, this.$store).setDrawerNotificationOpened(value);
        }

        get currentRoute() {
            return this.$route.name;
        }

        public toggleNotificationDrawer() {
            getModule(LayoutModule, this.$store).toggleNotificationDrawer();
        }

        public allNotificationsSeen() {
            const notificationModule = getModule(NotificationModule, this.$store);

            notificationModule.allNotificationsSeen();
        }

        public agendaNotificationsSeen(agendaId: string) {
            const notificationModule = getModule(NotificationModule, this.$store);

            notificationModule.agendaNotificationsSeen(agendaId);
        }
    }
