
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {LayoutModule} from '@/store/modules';

    import {
        OrganizationModule,
    } from '@/store/modules';

    import {
        IOrganization,
        IAccountRole,
    } from '@/types';

    const layoutNamespace = namespace('layout');
    const organizationNamespace = namespace('organization');
    const accountNamespace = namespace('account');

    @Component<TheDrawerOrganization>({})
    export default class TheDrawerOrganization extends Vue {
        @layoutNamespace.Getter('isDrawerLeftOpened')
        public isDrawerLeftOpened!: boolean;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @accountNamespace.Getter('loggedAccountRoles')
        public loggedAccountRoles!: IAccountRole[];

        public hover: boolean = false;

        get opened() {
            return this.isDrawerLeftOpened;
        }
        set opened(value: boolean) {
            getModule(LayoutModule, this.$store).setDrawerLeftOpened(value);
        }

        get organizations() {
            return this.loggedAccountRoles
                .filter((accountRole: IAccountRole) => {
                    return !!accountRole.organization &&
                        (!this.loggedOrganization || accountRole.organization.id !== this.loggedOrganization.id)
                    ;
                })
                .map((accountRole: IAccountRole) => {
                    return accountRole.organization;
                })
            ;
        }

        get shortOrganizationName() {
            const lengthName = this.loggedOrganization.name.split('');

            if (lengthName.length < 23) {
                return this.loggedOrganization.name;
            } else {
                let newName: string = '';
                lengthName.map((e, i) => {
                        if (i < 24) {
                            newName = newName+e;
                        }
                    },
                );

                return newName + '...';
            }
        }

        public shortcutsName(name: string) {
            const lengthName = name.split('');
            
            if (lengthName.length < 23) {
                return name;
            } else {
                let newName: string = '';
                lengthName.map((e, i) => {
                        if (i < 24) {
                            newName = newName+e;
                        }
                    },
                );

                return newName + '...';
            }
        }

        public selectOrganization(organization: IOrganization) {
            const organizationModule = getModule(OrganizationModule, this.$store);

            organizationModule.selectOrganization(organization)

            // TODO: don't reload, clean and refetch main data instead
            // window.location.reload();
        }
    }
