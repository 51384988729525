import {IPlaceSuggestion, IParsedPlaceSuggestion, IAddressComponent} from '@/types';

export const addressHelper = {
    formatForStripe,
    parseRawData,
};

function parseRawData(place: IPlaceSuggestion): IParsedPlaceSuggestion {
    const placeSuggestion: IParsedPlaceSuggestion = {
        house_number: null,
        postal_code: null,
        street: null,
        city: null,
        country: null,
    };
    
    if (place.raw_data.address_components) {
        place.raw_data.address_components.forEach((addressComponent: IAddressComponent) => {
            addressComponent.types.forEach((type: string) => {
                switch (type) {
                    case 'street_number':
                        placeSuggestion.house_number = addressComponent.long_name;
                        break;
    
                    case 'postal_code':
                        placeSuggestion.postal_code = addressComponent.long_name;
                        break;
    
                    case 'route':
                        placeSuggestion.street = addressComponent.long_name;
                        break;
    
                    case 'locality':
                        placeSuggestion.city = addressComponent.long_name;
                        break;
    
                    case 'country':
                        placeSuggestion.country = addressComponent.long_name;
                        break;
                }
            });
        });
    }
    
    return placeSuggestion;
}

function formatForStripe(place: IParsedPlaceSuggestion): stripe.OwnerAddress {
    let line1 = '';

    if (place.house_number) {
        line1 = place.house_number;
    }

    if (place.street) {
        line1 = `${line1} ${place.street}`;
    }

    const stripeAddress: stripe.OwnerAddress = {
        line1: line1.trim(),
    };

    if (place.postal_code) {
        stripeAddress.postal_code = place.postal_code;
    }

    if (place.city) {
        stripeAddress.city = place.city;
    }

    if (place.country) {
        stripeAddress.country = place.country;
    }

    return stripeAddress;
}
