
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {Prop} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';

    import {
        DateTime,
        DateTimeFormatOptions,
        LocaleOptions,
    } from 'luxon';

    import {EventModule} from '@/store/modules';

    import {
        IOrganization,
        IClient,
        IAnimal,
        IBooking,
        IEvent,
    } from '@/types';

    const clientNamespace = namespace('client');
    const organizationNamespace = namespace('organization');
    const eventNamespace = namespace('event');


    @Component<ClientCardBookings>({
        components: {
            BookingCard: () => import('@/components/BookingCard.vue'),
        },
    })
    export default class ClientCardBookings extends Vue {
        public selectedBooking!: IBooking;
        public bookingCardIsOpen: boolean = false;
        public animalToCreate: IAnimal|null = null;

        @Prop({
            type: String,
            required: false,
            default: '',
        })
        public clientToEdit!: string;

        public dataTableIsLoading: boolean = true;

        public headers: object[] = [
          { text: 'Praticien', value: 'agenda.name' },
          { text: 'Animal', value: 'booking.animal' },
          { text: 'Date et Heure', value: 'start' },
          { text: 'Motif', value: 'booking.reason.name' },
          { text: 'Référent', value: 'booking.referent'},
          { text: 'Statut', value: 'booking.attendance', align: 'end' },
        ];

        @clientNamespace.Getter('currentClient')
        public currentClient!: IClient;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @eventNamespace.Getter('clientBookingsList')
        public clientBookingsList!: IEvent[];

        public dateFormat(date: string) {
            const formatOpts: LocaleOptions & DateTimeFormatOptions = {
                weekday: 'short',
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            };

            return DateTime
                .fromISO(date)
                .toLocaleString(formatOpts)
            ;
        }

        public hourMinuteFormat(date: string) {
            return DateTime
                .fromISO(date)
                .toLocaleString(DateTime.TIME_SIMPLE)
            ;
        }

        public openBookingCard(booking: IBooking) {
            this.selectedBooking = booking;
            this.bookingCardIsOpen = true;
        }

        public created() {
            if (this.clientToEdit) {
                const eventModule = getModule(EventModule, this.$store);
                const params = {
                    organization_id: this.loggedOrganization.id,
                    clients: [this.clientToEdit],
                    type: 'booking',
                };

                eventModule
                    .fetchClientBookings(params)
                    .finally(() => {
                        this.dataTableIsLoading = false;
                    })
                ;
            }
        }
    }
