import {
    IBusinessHour,
    ICreateBusinessHourParams,
    IDeleteBusinessHourParams,
    IListBusinessHoursParams,
    IUpdateBusinessHourParams,
} from '@/types';

import AbstractService from './abstract.service';

export class BusinessHourService extends AbstractService {
    public list(params: IListBusinessHoursParams) {
        return new Promise<IBusinessHour[]>((resolve, reject) => {
            const organizationId = params.organization_id;

            delete (params as IListBusinessHoursParams).organization_id;

            this.api({
                url: `/organizations/${organizationId}/business-hours`,
                method: 'GET',
                params,
            })
                .then((response) => {
                    resolve(response.data.business_hours);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public create(params: ICreateBusinessHourParams) {
        return new Promise<IBusinessHour>((resolve, reject) => {
            const organizationId = params.organization_id;
            delete (params as ICreateBusinessHourParams).organization_id;

            this.api({
                url: `/organizations/${organizationId}/business-hours`,
                method: 'POST',
                data: params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public  update(params: IUpdateBusinessHourParams) {
        return new Promise<IBusinessHour>((resolve, reject) => {
            const organizationId = params.organization_id;
            const businessHourId = params.business_hour_id;

            delete (params as IUpdateBusinessHourParams).organization_id;
            delete (params as IUpdateBusinessHourParams).business_hour_id;

            this.api({
                url: `/organizations/${organizationId}/business-hours/${businessHourId}`,
                method: 'PUT',
                data: params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public deleteBusinessHour(params: IDeleteBusinessHourParams) {
        return new Promise<boolean>((resolve, reject) => {
            this.api({
                url: `/organizations/${params.organization_id}/business-hours/${params.business_hour_id}`,
                method: 'DELETE',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }
}
