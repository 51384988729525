import {Channel, Socket} from 'phoenix';

export default class UserSocket {
    public static getInstance(): UserSocket {
        if (!UserSocket.instance) {
            UserSocket.instance = new UserSocket();
        }

        return UserSocket.instance;
    }

    private static instance: UserSocket;

    private socket: Socket|null = null;
    private socketPromise: Promise<UserSocket>|null = null;

    private constructor() {}

    public async ensureConnected(onError?: (error: any) => void): Promise<UserSocket> {
        // If a Promise exists, use it
        if (this.socketPromise) {
            return this.socketPromise;
        }

        // If a socket exists, return
        if (this.socket) {
            return new Promise<UserSocket>((resolve) => resolve(this));
        }

        this.socketPromise = new Promise<UserSocket>((resolve, reject) => {
            this.socket = new Socket(
                `${process.env.VUE_APP_WEBSOCKET_URL}/user-socket`,
                {
                    logger: (kind: string, msg: string, data: any) =>
                        // tslint:disable-next-line:no-console
                        console.log(`Socket logger : ${kind}: ${msg}`, data),
                },
            );

            this.socket.onError((error) => {
                if (onError) {
                    onError(error);
                }
            });

            this.socket.onOpen(() => {
                this.socketPromise = null;
                resolve(this);
            });

            this.socket.onClose((data: any) => {
                if (data.code === 1000) { // Normal Closure
                    return;
                }

                this.disconnect().then(() => this.ensureConnected(onError));
            });

            this.socket.connect();
        });

        return this.socketPromise;
    }

    public channel(topic: string, chanParams?: object): Promise<Channel> {
        return new Promise<Channel>((resolve, reject) => {
            if (this.socket) {
                resolve(this.socket.channel(topic, chanParams));
            } else {
                reject('Socket not connected');
            }
        });
    }

    public disconnect() {
        return new Promise<void>((resolve) => {
            if (this.socket) {
                this.socket.disconnect(
                    () => {
                        this.socket = null;
                        resolve();
                    },
                    1000,
                    'User disconnected',
                );
            } else {
                resolve();
            }
        });
    }
}
