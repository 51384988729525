import {ISpecialty, IPlaceSuggestion, IOrganizationType, IAgenda, IClient, IAnimal, IReason} from '@/types';

export const formHelper = {
    getAccountEducationEndYearRules,
    getAccountEducationLocationRules,
    getAccountEducationNameRules,
    getAccountEducationStartYearRules,
    getAgendaSpeciesRules,
    getAgendaColorRules,
    getAgendaNameRules,
    getBookingAnimalRules,
    getBookingClientRules,
    getBookingDurationRules,
    getBookingReasonRules,
    getCellPhoneRules,
    getClientEmailRules,
    getClientLastNameRules,
    getContactMessageRules,
    getContactSubjectRules,
    getEmailsRules,
    getEventAgendaRules,
    getEventDurationRules,
    getEventEndDateRules,
    getEventEndTimeRules,
    getEventStartDateRules,
    getEventStartTimeRules,
    getNoteRules,
    getFirstNameRules,
    getLastNameRules,
    getMaxIntervalRules,
    getMinIntervalRules,
    getNameRules,
    getOrganizationAddressRules,
    getOrganizationFacebookUrlRules,
    getOrganizationFeeNameRules,
    getOrganizationFeePriceRules,
    getOrganizationInstagramUrlRules,
    getOrganizationNameRules,
    getOrganizationPartnerRules,
    getOrganizationTwitterUrlRules,
    getOrganizationTypeRules,
    getOrganizationVideoUrlRules,
    getOrganizationWebUrlRules,
    getOwnerAddressRules,
    getOwnerEmailRules,
    getOwnerNameRules,
    getParaveterinarianFirstNameRules,
    getParaveterinarianLastNameRules,
    getPasswordRules,
    getReasonColorRules,
    getReasonDurationRules,
    getReasonNameRules,
    getRecurrenceByDayRules,
    getRecurrenceCountRules,
    getRecurrenceFrequencyRules,
    getRecurrenceIntervalRules,
    getRecurrenceStopConditionRules,
    getRecurrenceUntilRules,
    getSpecialtyRules,
    getTermsRules,
    getUnavailabilityNoteRules,
    getUnavailabilityObjectRules,
    getValidationCodeRules,
    getVeterinarianEmailRules,
    getVeterinarianFirstNameRules,
    getVeterinarianLastNameRules,
    getVeterinarianSpecialtyRules,
};

const regex = /^[67]\d{8}$/;

function testEmail(email: string) {
    return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
}

function testCellphone(cellphone: string) {
    return /^[67]\d{8}$/.test(cellphone);
}

function getEmailsRules() {
    return [
        (v: string) => !!v || 'L\'adresse e-mail est requise',
        (v: string) => testEmail(v) || 'Le format de l\'adresse e-mail est incorrect',
    ];
}

function getValidationCodeRules() {
    return [
        (v: string) => !!v || 'Veuillez entrer le code reçu par SMS',
        (v: string) => /\d{6}/.test(v) || 'Le code doit etre composé de 6 chiffres',
    ];
}

function getPasswordRules(isMandatory: boolean) {
    return isMandatory ?
        [
            (v: string) => !!v || 'Le mot de passe est requis',
            (v: string) => (!v || (v.length >= 12 && /\d/.test(v) && /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(v))) || 'le mot de passe doit contenir au moins 12 caractères avec, un chiffre et un caratère spécial minimum',
        ] :
        [
            (v: string) => (!v || (v.length >= 12 && /\d/.test(v) && /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(v))) || 'le mot de passe doit contenir au moins 12 caractères avec, un chiffre et un caratère spécial minimum',
        ]
    ;
}

function getFirstNameRules() {
    return [
        (v: string) => !!v || 'Votre prénom est requis',
        (v: string) => (v && v.length >= 2) || 'Votre prénom est requis',
    ];
}

function getLastNameRules() {
    return [
        (v: string) => !!v || 'Nom de famille est requis',
        (v: string) => (v && v.length >= 2) || 'Nom de famille est requis',
    ];
}

function getOrganizationNameRules() {
    return [
        (v: string) => !!v || 'le nom de l\'établissement est requis',
        (v: string) => (v && v.length >= 3) || 'le nom de l\'établissement est requis',
        (v: string) => !/\d{10}/.test(v) || 'le nom de l\'établissement ne peut pas contenir de telephone',
    ];
}

function getOrganizationPartnerRules(isMandatory: boolean) {
    return isMandatory ?
        [
            (v: string) => !!v || 'Votre identifiant chronovet est requis',
            (v: number) =>!v || v < 100000000000 || 'Votre identifiant chronovet doit est inferieur ou égal à 11 chiffres',
        ] :
        [
            (v: string) => !!v || 'Votre identifiant chronovet est requis',
            (v: number) =>!v || v < 100000000000 || 'Votre identifiant chronovet doit est inferieur ou égal à 11 chiffres',
        ]
    ;
}

function getOrganizationAddressRules() {
    return [
        (v: IPlaceSuggestion|null) => !!v || 'Veuillez indiquer l\'adresse de votre établissement',
    ];
}

function getOrganizationTypeRules() {
    return [
        (v: IOrganizationType|null) => !!v || 'Veuillez indiquer le type de votre établissement',
    ];
}

function getSpecialtyRules() {
    return [
        (v: ISpecialty|null) => !!v || 'Veuillez indiquer votre spécialité',
    ];
}

function getNameRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer votre nom',
        (v: string) => (v && v.length >= 2) || 'Veuillez indiquer votre nom',
    ];
}

function getTermsRules() {
    return [
        (v: boolean) => v || 'Vous devez accepter les CGV',
    ];
}

function getContactSubjectRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer l\'objet de votre demande',
    ];
}

function getContactMessageRules() {
    return [
        (v: string) => !!v || 'Veuillez nous donner plus de détails quant à la raison de votre contact',
        (v: string) => (v && v.length >= 50) || 'Votre message est trop court (50 caractères minimum)',
    ];
}

function getOwnerNameRules() {
    return [
        (v: string) => (v && v.length >= 2) || 'Veuillez indiquer le nom du titulaire du compte',
    ];
}

function getCellPhoneRules() {
        return [
            (v: string) => !!v || 'Le téléphone est obligatoire',
            (v: string) => testCellphone(v) || 'Le téléphone doit commencer par 6 ou 7 et doit etre composé de 9 chiffres',
        ];
}

function getOwnerEmailRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer l\'email du titulaire du compte',
        (v: string) => testEmail(v) || 'Le format de l\'adresse e-mail est incorrect',
    ];
}

function getOwnerAddressRules() {
    return [
        (v: IPlaceSuggestion|null) => !!v || 'Veuillez indiquer l\'adresse du titulaire du compte',
    ];
}

function getAgendaNameRules() {
    return [
        (v: string) => (v && v.length >= 2) || 'Veuillez indiquer le titre de l\'agenda',
    ];
}

function getAgendaColorRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer la couleur de l\'agenda',
    ];
}

function getAgendaSpeciesRules() {
    return [
        (v: string) => (v && v.length >= 1) || 'Veuillez sélectionner au moins une espèce prise en charge',
    ];
}

function getVeterinarianFirstNameRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer le prénom du vétérinaire',
        (v: string) => (v && v.length >= 2) || 'Veuillez indiquer le prénom du vétérinaire',
    ];
}

function getVeterinarianLastNameRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer le nom de famille du vétérinaire',
        (v: string) => (v && v.length >= 2) || 'Veuillez indiquer le nom de famille du vétérinaire',
    ];
}

function getVeterinarianEmailRules() {
    return [
        (v: string) => !v || testEmail(v) || 'Le format de l\'adresse e-mail est incorrect',
    ];

}

function getVeterinarianSpecialtyRules() {
    return [
        (v: ISpecialty|null) => !!v || 'Veuillez indiquer la spécialité du vétérinaire',
    ];
}

function getParaveterinarianFirstNameRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer le prénom de l\'utilisateur',
        (v: string) => (v && v.length >= 2) || 'Veuillez indiquer le prénom de l\'utilisateur',
    ];
}

function getParaveterinarianLastNameRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer le nom de famille de l\'utilisateur',
        (v: string) => (v && v.length >= 2) || 'Veuillez indiquer le nom de famille de l\'utilisateur',
    ];
}

function getReasonColorRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer la couleur du motif de consultation',
    ];
}

function getNoteRules() {
    return [
        (v: string) => !!v || 'Un note est nécésaire pour valider l\'hospitalisation',
    ];
}

function getReasonDurationRules() {
    return [
        (v: number) => !!v || 'Veuillez indiquer la durée relative à ce motif',
        (v: number) => (v >= 5 && v <= 180)  || 'La durée doit être comprise entre 5 et 180 minutes',
    ];
}

function getReasonNameRules() {
    return [
        (v: string) => (v && v.length >= 2) || 'Veuillez indiquer le nom du motif',
    ];
}

function getMinIntervalRules() {
    return [
        (v: number) => !!v || 'Veuillez indiquer le délais minimum',
        (v: number) => (v && v > 0) || 'Veuillez indiquer un délais valide',
    ];
}

function getMaxIntervalRules() {
    return [
        (v: number) => !!v || 'Veuillez indiquer le délais maximum',
        (v: number) => (v && v > 0) || 'Veuillez indiquer un délais valide',
    ];
}

function getClientLastNameRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer le nom de famille du client',
        (v: string) => (v && v.length >= 2) || 'Veuillez indiquer le nom de famille du client',
    ];
}

function getClientEmailRules() {
    return [
        (v: string) => !v || testEmail(v) || 'Le format de l\'adresse e-mail est incorrect',
    ];

}

function getEventAgendaRules() {
    return [
        (v: IAgenda) => (!!v && !!v.id) || 'Veuillez sélectionner un agenda',
    ];
}

function getEventStartDateRules() {
    return [
        (v: string) => !v || /\d{2}\/\d{2}\/\d{4}/.test(v) || 'Veuillez choisir un jour',
    ];
}

function getEventEndDateRules() {
    return [
        (v: string) => !v || /\d{2}\/\d{2}\/\d{4}/.test(v) || 'Veuillez choisir un jour',
    ];
}

function getEventStartTimeRules() {
    return [
        (v: string) => !v || /\d{2}:\d{2}/.test(v) || 'Veuillez choisir une heure de début',
        (v: string) => /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(v) || 'Veuillez indiquer une heure valide',
    ];
}

function getEventDurationRules() {
    return [
        (v: number) => !!v || 'Veuillez indiquer la durée',
        (v: number) => v >= 5 || 'La durée minimale est de 5min',
    ];
}

function getEventEndTimeRules() {
    return [
        (v: string) => !v || /\d{2}:\d{2}/.test(v) || 'Veuillez choisir une heure de fin',
        (v: string) => /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(v) || 'Veuillez indiquer une heure valide',
    ];
}

function getBookingClientRules() {
    return [
        (v: IClient) => (!!v && !!v.id) || 'Veuillez ajouter un client',
    ];
}

function getBookingAnimalRules() {
    return [
        (v: IAnimal) => (!!v && !!v.id) || 'Veuillez ajouter un animal',
    ];
}

function getBookingReasonRules() {
    return [
        (v: IReason) => (!!v && !!v.id) || 'Veuillez sélectionner un motif',
    ];
}

function getBookingDurationRules() {
    return [
        (v: number) => !!v || 'Veuillez indiquer la durée de ce RDV',
        (v: number) => v >= 5  || 'La durée doit être comprise supérieure à 5 minutes',
    ];
}

function getUnavailabilityObjectRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer un objet',
    ];
}

function getUnavailabilityNoteRules() {
    return [
        (v: string | null | undefined) => {
            if (v === null || v === undefined || v === '') {
                return true;
            }
            return v.length < 255 || 'Votre message est trop long (255 caractères maximum)';
        },
    ];
}

function getRecurrenceFrequencyRules() {
    return [
        (v: string) => !!v || 'Veuillez sélectionner une fréquence de répétition',
    ];
}

function getRecurrenceIntervalRules() {
    return [
        (v: number) => !!v || 'Veuillez indiquer l\'intervalle de répétition',
        (v: number) => v >= 1  || 'L\'Intervalle doit être au minimum de 1',
    ];
}

function getRecurrenceByDayRules() {
    return [
        (v: string) => (!!v && v.length > 0) || 'Veuillez sélectionner au moins un jour',
    ];
}

function getRecurrenceStopConditionRules() {
    return [
        (v: string) => !!v || 'Veuillez sélectionner une condition d\'arrêt',
    ];
}

function getRecurrenceUntilRules() {
    return [
        (v: string) =>  !v || /\d{2}\/\d{2}\/\d{4}/.test(v) || 'Veuillez indiquer la date de fin',
    ];
}

function getRecurrenceCountRules() {
    return [
        (v: number) => !!v || 'Veuillez indiquer le nombre de répétitions',
        (v: number) => v >= 1  || 'Le nombre de répétition doit être au minimum de 1',
    ];
}

function getOrganizationVideoUrlRules() {
    return [
        (v: string) =>!v
        || /^(http:\/\/|https:\/\/)(youtu\.be|www\.youtube\.com)\/([\w\/]+)([\?].*)?$/.test(v)
        || 'Le format de l\'URL est incorrect',
    ];
}

function getOrganizationWebUrlRules() {
    return [
        (v: string) =>!v
        || /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(v)
        || 'Le format de l\'URL ne correspond pas à une addresse de site web',
    ];
}

function getOrganizationFacebookUrlRules() {
    return [
        (v: string) =>!v
        || /(?:https?:\/\/)?(?:www\.)?facebook\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]*)/.test(v)
        || 'Le format de l\'URL ne correspond pas à une addresse de page Facebook',
    ];
}

function getOrganizationTwitterUrlRules() {
    return [
        (v: string) =>!v
        || /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/.test(v)
        || 'Le format de l\'URL ne correspond pas à une addresse de profil Twitter',
    ];
}

function getOrganizationInstagramUrlRules() {
    return [
        (v: string) =>!v
        || /http(?:s)?:\/\/(?:www\.)?instagram\.com\/([a-zA-Z0-9_]+)/.test(v)
        || 'Le format de l\'URL ne correspond pas à une addresse de page Instagram',
    ];
}

function getOrganizationFeePriceRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer le prix de l\'honoraire',
        (v: number) =>!v
        || v >= 0
        || 'Le prix doit être un nombre positif',
    ];
}

function getOrganizationFeeNameRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer la désignation de l\'honoraire',
    ];
}

function getAccountEducationNameRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer le nom du diplôme',
    ];
}

function getAccountEducationLocationRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer le lieu d\'obtention du diplôme',
    ];
}

function getAccountEducationStartYearRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer l\'année du début de la formation',
        (v: number) => !v
        || v >= 0
        || 'L\'année doit être un nombre positif',
    ];
}

function getAccountEducationEndYearRules() {
    return [
        (v: number) => !v
        || v >= 0
        || 'L\'année doit être un nombre positif',
    ];
}
