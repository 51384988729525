
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Model, Prop, Watch} from 'vue-property-decorator';
    import {DateTime} from 'luxon';
    import {AnimalModule} from '@/store/modules';

    import {
        IAnimal,
        IBreed,
        IOrganization,
        ISpecies,
    } from '@/types';

    const animalNamespace = namespace('animal');
    const organizationNamespace = namespace('organization');

    @Component<AnimalForm>({})
    export default class AnimalForm extends Vue {
        public animalFormValid = false;
        public birthDateMenu = false;

        public birthDateFormatted: string|null = null;
        public filteredBreedsList: IBreed[] = [];

        public innerModel!: IAnimal;
        public species: ISpecies|null = null;
        public speciesByType: ISpecies[] = [];

        @Model('input', {type: Object}) public readonly value!: ISpecies|null;

        public nameRules = [
            (v: string) => !!v || 'Le nom est requis',
        ];

        public birthDateRules = [
            (v: string) => !!v || 'La date de naissance est requise',
        ];

        public speciesRules = [
            (v: string) => !!v || 'L\'espèce est requise',
        ];

        public sexRules = [
            (v: string) => !!v || 'Le sexe est requis',
        ];

        @animalNamespace.Getter('speciesList')
        public speciesList!: ISpecies[];

        @animalNamespace.Getter('breedsList')
        public breedsList!: IBreed[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        // @Model('input', {type: Object}) public readonly value!: IAnimal;

        @Prop({
            type: Boolean,
            required: false,
            default: true,
        })
        public isEditable!: boolean;

        @Watch('innerModel', {deep: true})
        public onInnerModelChanged(val: IAnimal) {
            this.$emit('input', val);
        }

        @Watch('value')
        private valueChanged(val: ISpecies|null) {
            if (val !== this.species) {
                this.species = val;
            }
        }

        public birthDateChanged() {
            this.birthDateMenu = false;

            if (this.innerModel.birth_date === '') {
                this.birthDateFormatted = null;
            } else {
                this.birthDateFormatted =
                    DateTime
                    .fromISO(this.innerModel.birth_date)
                    .toLocaleString(DateTime.DATE_SHORT)
                ;
            }
        }

        public computeBreedsList() {
            if (!this.innerModel.species) {
                this.filteredBreedsList = [];
            } else {
                
                this.filteredBreedsList = this
                    .breedsList
                    .filter((breed: IBreed) => breed.species_id === this.innerModel.species.id)
                ;

                if (this.innerModel.species.name === 'Chat' && !this.innerModel.breed) {
                    const result = this.filteredBreedsList.find((elm: IBreed) => 
                            elm.name==='Européen \"Gouttière\"'
                    )
                    this.innerModel.breed = result as IBreed;
                }
            }
        }

        private data() {
            return {
                innerModel: Object.assign({}, this.value),
            };
        }

        private created() {
            this.computeBreedsList();
            if (this.innerModel.birth_date) {
                this.birthDateFormatted =
                    DateTime
                    .fromISO(this.innerModel.birth_date)
                    .toLocaleString(DateTime.DATE_SHORT)
                ;
            }

            const animalModule = getModule(AnimalModule, this.$store);

            animalModule
                .computeSpeciesByTypeList()
                .then((speciesByType) => {
                    this.speciesByType = speciesByType;

                    if (this.value) {
                        this.species = this.value;
                    }
                })
            ;
        }
    }
